import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { IEventPreference } from 'src/app/api/model/events.model';
import { EventService } from 'src/app/api/services/event/event.service';
import { EVENT_ID_LS_KEY } from 'src/app/shared/constants/storage.constant';
import { IPageVisibilityChangeEvent } from 'src/app/shared/model/page-list-item.model';
import { LanguageService } from 'src/app/shared/services/language/language.service';
import { PreferencesService } from 'src/app/shared/services/preferences/preferences.service';
import { SnackbarService } from 'src/app/shared/services/snackbar/snackbar.service';
import { StorageService } from 'src/app/shared/services/storage/storage.service';
import { ORGANIZER_PAGES } from '../../constants/organizer-pages.constant';
import { OrganizerPage } from '../../model/organizer-page.model';

@Component({
  templateUrl: './organizers.page.html',
  styleUrls: ['./organizers.page.scss'],
})
export class OrganizersPage implements OnInit {
  organizerPages = ORGANIZER_PAGES;
  eventId: number;
  preferences: IEventPreference[] = [];
  searchValue = '';
  activeLanguageCode: string;
  private subs = new Subscription();

  constructor(
    private router: Router,
    private eventService: EventService,
    private storageService: StorageService,
    private preferencesService: PreferencesService,
    public translate: TranslateService,
    private snackbarService: SnackbarService,
    private languageService: LanguageService
  ) {
    this.eventId = this.storageService.getData(EVENT_ID_LS_KEY) as number;
  }

  ngOnInit(): void {
    this.activeLanguageCode = this.languageService.getActiveLanguage();

    this.subs.add(
      this.eventService
        .getEventPreference(this.eventId)
        .subscribe((preferences) => {
          this.preferences = preferences;
        })
    );
  }

  onSearch(searchTerm: string): void {
    this.searchValue = searchTerm;
  }

  onNavigateToPage(pageId: OrganizerPage): void {
    this.router.navigate(['organizers', pageId]);
  }

  onChangeVisibilityState(
    event: IPageVisibilityChangeEvent<OrganizerPage>
  ): void {
    const preferenceKey = `${this.activeLanguageCode};${event.page.id}`;

    if (event.shouldBeVisible) {
      this.subs.add(
        this.preferencesService
          .createOrUpdatePreference(
            this.eventId,
            preferenceKey,
            event.shouldBeVisible
          )
          .subscribe({
            next: (preferences: IEventPreference[]) => {
              this.preferences = preferences;

              this.snackbarService.openSnackbar(
                this.snackbarService.getSuccessSnackbarData()
              );
            },
            error: (error: unknown) => {
              this.snackbarService.openSnackbar(
                this.snackbarService.getErrorSnackbarData()
              );

              console.error(error);
            },
          })
      );
    } else {
      this.subs.add(
        this.preferencesService
          .deletePreference(this.eventId, preferenceKey)
          .subscribe({
            next: (preferences: IEventPreference[]) => {
              this.preferences = preferences;

              this.snackbarService.openSnackbar(
                this.snackbarService.getSuccessSnackbarData()
              );
            },
            error: (error: unknown) => {
              this.snackbarService.openSnackbar(
                this.snackbarService.getErrorSnackbarData()
              );

              console.error(error);
            },
          })
      );
    }
  }
}
