import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { IOrganizerPage, OrganizerPage } from '../model/organizer-page.model';

export const ORGANIZER_PAGES: IOrganizerPage[] = [
  {
    id: OrganizerPage.BaseMap,
    titleI18n: marker('ORGANIZERS.BASEMAP'),
    type: OrganizerPage.BaseMap.toUpperCase(),
    canCheck: true,
    translation: '',
    isBasicPage: false,
    containList: false,
  },
  {
    id: OrganizerPage.ImportantContacts,
    titleI18n: marker('ORGANIZERS.IMPORTANT_CONTACTS'),
    type: OrganizerPage.ImportantContacts.toUpperCase(),
    canCheck: true,
    translation: '',
    isBasicPage: false,
    containList: false,
  },
  {
    id: OrganizerPage.ImportantInfo,
    titleI18n: marker('ORGANIZERS.OTHER_INFORMATION'),
    type: OrganizerPage.ImportantInfo.toUpperCase(),
    canCheck: true,
    translation: '',
    isBasicPage: false,
    containList: false,
  },
  {
    id: OrganizerPage.Transport,
    titleI18n: marker('ORGANIZERS.TRANSPORT'),
    type: OrganizerPage.Transport.toUpperCase(),
    canCheck: true,
    translation: '',
    isBasicPage: false,
    containList: false,
  },
  {
    id: OrganizerPage.Accommodation,
    titleI18n: marker('ORGANIZERS.ACCOMMODATION'),
    type: OrganizerPage.Accommodation.toUpperCase(),
    canCheck: true,
    translation: '',
    isBasicPage: false,
    containList: true,
  },
  {
    id: OrganizerPage.Catering,
    titleI18n: marker('ORGANIZERS.CATERING'),
    type: OrganizerPage.Catering.toUpperCase(),
    canCheck: true,
    translation: '',
    isBasicPage: false,
    containList: true,
  },
  {
    id: OrganizerPage.Position,
    titleI18n: marker('ORGANIZERS.POSITION'),
    type: OrganizerPage.Position.toUpperCase(),
    canCheck: true,
    translation: '',
    isBasicPage: false,
    containList: false,
  },
  {
    id: OrganizerPage.HygieneMeasures,
    titleI18n: marker('ORGANIZERS.COVID'),
    type: OrganizerPage.HygieneMeasures.toUpperCase(),
    canCheck: true,
    translation: '',
    isBasicPage: false,
    containList: false,
  },
  {
    id: OrganizerPage.ExtraordinaryEvents,
    titleI18n: marker('ORGANIZERS.EXTRAORDINARY_EVENTS'),
    type: OrganizerPage.ExtraordinaryEvents.toUpperCase(),
    canCheck: true,
    translation: '',
    isBasicPage: false,
    containList: false,
  },
];
