export function isLanguageSupported(
  countryCode: string,
  supportedLanguages: string[]
): boolean {
  return supportedLanguages.includes(countryCode);
}

export function getCountryCodePrefix(countryCode: string): string {
  if (countryCode.length >= 2) {
    return countryCode.slice(0, 1);
  }
  throw Error('Country code is too short');
}
