import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
  Output,
  EventEmitter,
  ComponentRef,
  Inject,
  ChangeDetectorRef,
  ComponentFactoryResolver,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDialogData } from '../../../model/dialog-data.model';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent<T = unknown>
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('contentContainer', { read: ViewContainerRef, static: false })
  vcRef: ViewContainerRef;
  @Output() contentRendered = new EventEmitter<ComponentRef<T>>();
  contentRef: ComponentRef<T>;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent<T>>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData<T>,
    private changeDetection: ChangeDetectorRef,
    private resolver: ComponentFactoryResolver
  ) {}

  ngOnInit(): void {
    // empty
  }

  ngAfterViewInit(): void {
    this.renderComponent();
    this.changeDetection.detectChanges();
    this.contentRendered.emit(this.contentRef);
  }

  ngOnDestroy(): void {
    if (this.contentRef) {
      this.contentRef.destroy();
    }
  }

  renderComponent(): void {
    const factory = this.resolver.resolveComponentFactory<T>(
      this.data.component
    );
    this.contentRef = this.vcRef.createComponent<T>(factory);

    if (this.data.componentData) {
      for (const inputKey of Object.keys(this.data.componentData)) {
        (this.contentRef.instance as Record<string, unknown>)[inputKey] =
          this.data.componentData[inputKey];
      }
    }
  }
}
