import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrganizersPage } from './pages/organizers/organizers.page';
import { BaseMapPage } from './pages/base-map/base-map.page';
import { ImportantContactsPage } from './pages/important-contacts/important-contacts.page';
import { BasicFormContentPage } from 'src/app/shared/pages/basic-form-content/basic-form-content.page';
import { CanDeactiveGuard } from 'src/app/core/guard/can-deactive.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'list',
  },
  {
    path: 'list',
    pathMatch: 'full',
    component: OrganizersPage,
  },
  {
    path: 'org_baseMap',
    pathMatch: 'full',
    component: BaseMapPage,
    canDeactivate: [CanDeactiveGuard],
  },
  {
    path: 'org_important_contacts',
    pathMatch: 'full',
    component: ImportantContactsPage,
    canDeactivate: [CanDeactiveGuard],
  },
  {
    path: ':pageId',
    pathMatch: 'full',
    component: BasicFormContentPage,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OrganizersRoutingModule {}
