<div *ngIf="!getValue()">
  <div>
    <span>{{ title }}</span>
    <button mat-icon-button (click)="openAddFilesDialog()">
      <mat-icon>{{ getIcon() }}</mat-icon>
      <input
        class="app-file-input"
        #FileSelectInputDialog
        type="file"
        multiple
        (change)="onChange($event)"
      />
    </button>
  </div>
</div>

<div *ngIf="getValue()">
  <div>
    <span>{{ title }}</span>
    <button mat-icon-button (click)="delete.emit()" (click)="deleteItem()">
      <mat-icon>{{ getIcon() }}</mat-icon>
      <input
        class="app-file-input"
        #FileSelectInputDialog
        type="file"
        multiple
        (change)="onChange($event)"
      />
    </button>
  </div>
  <img [style.width.px]="width" [src]="image" />
</div>
