import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { defaultIfEmpty, map, mergeMap } from 'rxjs/operators';
import { EventsService } from 'src/app/api/service/events/events.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import {
  IEnervitProduct,
  IEnervitInfo,
  IEnervitInfoImages,
  IEnervitInfos,
} from '../../model/other-pages.model';

@Injectable({
  providedIn: 'root',
})
export class EnervitService {
  private readonly basePath = environment.apiUrl;

  constructor(
    private httpClient: HttpClient,
    private eventsService: EventsService,
    private translate: TranslateService
  ) {}

  getEnervit(): Observable<IEnervitProduct[]> {
    return this.httpClient.get<IEnervitProduct[]>(
      `${this.basePath}/enervit/products`
    );
  }

  getEnervitInfo(activeLanguageCode: string): Observable<IEnervitInfos> {
    return this.httpClient
      .get<IEnervitInfos>(`${this.basePath}/enervit/info/${activeLanguageCode}`)
      .pipe(defaultIfEmpty());
  }

  createOrUpdateEnervitProduct(
    product: IEnervitProduct
  ): Observable<IEnervitProduct> {
    return this.httpClient.post<IEnervitProduct>(
      `${this.basePath}/enervit`,
      product
    );
  }

  createOrUpdateEnervitInfos(infos: IEnervitInfo[]): Observable<any> {
    return forkJoin(
      infos.map((info) => {
        return this.createOrUpdateEnervitInfo(info);
      })
    ).pipe(defaultIfEmpty([]));
  }

  saveEnervit(
    products: IEnervitProduct[],
    infos: IEnervitInfo[],
    images: any[],
    activeLanguageCode: string
  ): Observable<any> {
    return forkJoin([
      this.createOrUpdateEnervitProducts(products, activeLanguageCode),
      this.createOrUpdateEnervitInfos(infos),
      this.uploadInfoImages(images, activeLanguageCode),
    ]).pipe(defaultIfEmpty([]));
  }

  uploadInfoImages(
    images: any[],
    activeLanguageCode: string
  ): Observable<void[]> {
    return forkJoin(
      images.map((image) => {
        if (image?.file === undefined) {
          return this.deleteEvenrvitImage(
            image.type.toUpperCase(),
            activeLanguageCode
          );
        } else if (image.file && typeof image?.file !== 'string') {
          return this.uploadImageInfo(
            image.file,
            image.type.toUpperCase(),
            activeLanguageCode
          );
        } else {
          return of(0 as unknown as void);
        }
      })
    ).pipe(defaultIfEmpty([]));
  }

  deleteEvenrvitImage(
    type: string,
    activeLanguageCode: string
  ): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.basePath}/enervit/infoImage/${activeLanguageCode}`,
      {
        params: {
          imgType: type,
        },
      }
    );
  }

  deleteProductImage(
    productId: number,
    activeLanguageCode: string
  ): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.basePath}/enervit/${productId}/${activeLanguageCode}/image`
    );
  }

  uploadImageInfo(
    image: File,
    type: string,
    activeLanguageCode: string
  ): Observable<void> {
    const formData = new FormData();
    formData.append('file', image, image?.name.toLowerCase());

    return this.httpClient.post<void>(
      `${this.basePath}/enervit/uploadInfoImage/${activeLanguageCode}`,
      formData,
      {
        params: {
          imgType: type,
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  }

  createOrUpdateEnervitInfo(info: IEnervitInfo): Observable<IEnervitProduct> {
    return this.httpClient.post<IEnervitProduct>(
      `${this.basePath}/enervit/info`,
      info
    );
  }

  createOrUpdateEnervitProducts(
    products: IEnervitProduct[],
    activeLanguageCode: string
  ): Observable<any> {
    return forkJoin(
      products.map((product) => {
        let file = null;
        if (typeof product.image[activeLanguageCode] !== 'string') {
          file = product.image[activeLanguageCode];
          product.image.cs = '';
          product.image.en = '';
        }

        const isImageStringEmpty = product.image[activeLanguageCode] === '';

        return this.createOrUpdateEnervitProduct(product).pipe(
          mergeMap((response) => {
            if (!file && isImageStringEmpty && product.id) {
              return this.deleteProductImage(response.id, activeLanguageCode);
            } else if (file) {
              return this.uploadImage(response.id, file, activeLanguageCode);
            }
            return of(0 as unknown as void);
          })
        );
      })
    ).pipe(defaultIfEmpty([]));
  }

  deleteEvenrvitInfo(ids: number[]): Observable<void> {
    return this.httpClient.delete<void>(`${this.basePath}/enervit/info`, {
      params: {
        ids: ids.toString(),
      },
    });
  }

  deleteProductImages(
    productIds: number[],
    activeLanguageCode: string,
    products: IEnervitProduct[]
  ): Observable<any> {
    return forkJoin(
      productIds.map((productId) => {
        if (
          this.doesProductContainImage(productIds, activeLanguageCode, products)
        ) {
          return this.deleteProductImage(productId, activeLanguageCode);
        }
        return of(0 as unknown as void);
      })
    ).pipe(defaultIfEmpty([]));
  }

  private doesProductContainImage(
    productIds: number[],
    activeLanguageCode: string,
    products: IEnervitProduct[]
  ): boolean {
    const foundProduct = products.find((product) =>
      productIds.includes(product.id)
    );

    if (foundProduct) {
      return (
        typeof foundProduct.image[activeLanguageCode] === 'string' &&
        foundProduct.image[activeLanguageCode] !== ''
      );
    }

    return false;
  }

  deleteProductsWithImages(
    productIds: number[],
    activeLanguageCode: string,
    products: IEnervitProduct[]
  ): Observable<void> {
    return this.deleteProductImages(
      productIds,
      activeLanguageCode,
      products
    ).pipe(
      mergeMap(() => {
        return this.deleteProducts(productIds);
      })
    );
  }

  deleteProducts(productIds: number[]): Observable<void> {
    return this.httpClient.delete<void>(`${this.basePath}/enervit/product`, {
      params: {
        ids: productIds.toString(),
      },
    });
  }

  uploadImage(
    productId: number,
    image: File,
    activeLanguageCode: string
  ): Observable<void> {
    const formData = new FormData();
    formData.append('file', image, image?.name.toLowerCase());

    return this.httpClient.post<void>(
      `${this.basePath}/enervit/${productId}/${activeLanguageCode}/uploadImage`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  }

  deleteRaceImage(
    productId: number,
    activeLanguageCode: string
  ): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.basePath}/enervit/${productId}/${activeLanguageCode}/image`
    );
  }
}
