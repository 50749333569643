import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IEventResult } from 'src/app/api/model/events.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SortingListService } from '../../services/sorting-list.service';

@Component({
  selector: 'app-sorting-event-results',
  templateUrl: './sorting-event-results.component.html',
  styleUrls: ['./sorting-event-results.component.scss'],
})
export class SortingEventResultsComponent implements OnInit {
  @Input() results: IEventResult[];
  @Output() resultChange = new EventEmitter<IEventResult[]>();

  sortedResults: IEventResult[];

  constructor(private sortingListService: SortingListService) {
    this.sortingListService.changeEventResult.subscribe({
      next: (results) => {
        this.sortedResults = [...results];
        this.resultChange.emit(this.sortedResults);
      },
    });
  }

  ngOnInit(): void {
    this.sortedResults = [...this.results];
  }

  drop(dragDropEvent: CdkDragDrop<string[]>): void {
    this.reorderResults(dragDropEvent);
  }

  reorderResults(dragDropEvent: CdkDragDrop<string[]>): void {
    moveItemInArray(
      this.sortedResults,
      dragDropEvent.previousIndex,
      dragDropEvent.currentIndex
    );

    this.resultChange.emit(this.sortedResults);
  }

  trackResults(index: number, result: IEventResult): unknown {
    return result.race.id;
  }
}
