import { Injectable } from '@angular/core';
import { IPermisson, IPermissonRole } from '../../model/permisson.model';

@Injectable({
  providedIn: 'root',
})
export class PermissonService {
  private readonly ROOT = 'ROOT';
  private readonly ADMIN = 'ADMIN';
  private readonly DEVICE = 'DEVICE';

  private readonly permissions: IPermissonRole[] = [
    {
      part: 'program',
      action: 'delete',
      type: 1,
      roles: [this.ROOT, this.ADMIN, this.DEVICE],
    },

    {
      part: 'program',
      action: 'create',
      type: 2,
      roles: [this.ROOT, this.ADMIN, this.DEVICE],
    },
    {
      part: 'program',
      action: 'delete',
      type: 2,
      roles: [this.ROOT, this.ADMIN],
    },
    {
      part: 'notifications',
      action: 'delete',
      type: 1,
      roles: [this.ROOT, this.ADMIN],
    },
  ];
  constructor() {}

  hasPermission(type: IPermisson): boolean {
    const LOGGED_USER_ROLE = localStorage.getItem('role');
    const permissionFound = this.permissions.find((permission) => {
      return (
        permission.action === type.action &&
        permission.part === type.part &&
        permission.type === type.type
      );
    });

    if (permissionFound) {
      return permissionFound.roles.includes(LOGGED_USER_ROLE);
    }
    return false;
  }
}
