import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function FileExtension(extensions: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const files: File[] = control.value;

    if (files) {
      const normExtensions = extensions.map((extension) =>
        extension.toLowerCase()
      );

      for (const file of files) {
        if (!file) {
          return { extensions: true };
        }

        const extension = file.name.split('.').pop().toLowerCase();
        if (normExtensions.indexOf(extension) === -1) {
          return { extensions: true };
        }
      }
    }
    return null;
  };
}
