<div *ngIf="!loading">
  <app-back-panel
    [title]="'ORGANIZERS.PAGES.BACK' | translate"
  ></app-back-panel>

  <app-list-top-card [title]="titleI18n | translate"> </app-list-top-card>

  <div class="mb4">
    <app-basic-form-content
      [formGroup]="formGroup"
      [extended]="extended"
      (addGalleryImage)="addGalleryFormGroup()"
      (deleteGalleryImage)="onDeleteImage($event)"
    ></app-basic-form-content>
  </div>

  <app-footer
    class="mt4"
    (save)="onSaveClick()"
    (cancel)="onCancelClick()"
  ></app-footer>
</div>

<app-spinner *ngIf="loading"></app-spinner>
