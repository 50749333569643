<app-custom-button
  class="mr2"
  weight="secondary"
  [disabled]="cancelDisabled"
  (click)="cancel.emit()"
>
  {{ cancelText || ("CORE.BUTTON.CANCEL" | translate) }}
</app-custom-button>

<app-custom-button
  weight="primary"
  [disabled]="confirmDisabled"
  [danger]="danger"
  (click)="confirm.emit()"
>
  {{ confirmText || ("CORE.BUTTON.CANCEL" | translate) }}
</app-custom-button>
