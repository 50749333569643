<div *ngIf="loading" class="mb4">
  <app-back-panel
    [title]="'OTHER-PAGES.PAGES.BACK' | translate"
  ></app-back-panel>
  <app-list-top-card [title]="'OTHER-PAGES.PAGES.ENERVIT' | translate">
  </app-list-top-card>
  <app-detail-card class="mb3">
    <mat-tab-group class="app-tab-group">
      <div class="mb4">
        <mat-tab
          class="app-tab-group"
          [label]="'OTHER-PAGES.ENERVIT.TABS.PRODUCTS' | translate | uppercase"
        >
          <div class="mt4 mb4">
            <app-enervit-products
              *ngFor="let product of getProducts().controls; let i = index"
              [formGroup]="product"
              [index]="i"
              (getIndex)="deleteIndex($event)"
            ></app-enervit-products>
          </div>
          <div (click)="addProductFormGroup()">
            <app-icon-title
              [title]="'OTHER-PAGES.ENERVIT.ADD' | translate"
              icon="add"
            >
            </app-icon-title>
          </div>
        </mat-tab>

        <mat-tab
          class="app-tab-group"
          [label]="
            'OTHER-PAGES.ENERVIT.TABS.WEEK-BEFORE' | translate | uppercase
          "
        >
          <div class="mt4 mb4">
            <div class="mb4" [formGroup]="formGroup">
              <app-image-input
                [control]="formGroup.get('week_before_image')"
                [width]="110"
                [height]="180"
                [title]="'OTHER-PAGES.ENERVIT.IMAGES.BEFORE' | translate"
              ></app-image-input>
            </div>

            <app-enervit-info
              *ngFor="
                let info of getInfo('WEEK_BEFORE').controls;
                let i = index
              "
              [formGroup]="info"
              type="'WEEK_BEFORE'"
              [index]="i"
              (getIndex)="deleteInfoIndex($event, 'WEEK_BEFORE')"
            ></app-enervit-info>
          </div>
          <div (click)="addInfoFormGroup('WEEK_BEFORE')">
            <app-icon-title
              [title]="'OTHER-PAGES.ENERVIT.ADD' | translate"
              icon="add"
            >
            </app-icon-title>
          </div>
        </mat-tab>

        <mat-tab
          class="app-tab-group"
          [label]="
            'OTHER-PAGES.ENERVIT.TABS.JUST_BEFORE' | translate | uppercase
          "
        >
          <div class="mt4 mb4">
            <div class="mb4" [formGroup]="formGroup">
              <app-image-input
                [control]="formGroup.get('just_before_image')"
                [width]="110"
                [height]="180"
                [title]="'OTHER-PAGES.ENERVIT.IMAGES.JUST' | translate"
              ></app-image-input>
            </div>

            <app-enervit-info
              *ngFor="
                let info of getInfo('JUST_BEFORE').controls;
                let i = index
              "
              [formGroup]="info"
              type="'JUST_BEFORE'"
              [index]="i"
              (getIndex)="deleteInfoIndex($event, 'JUST_BEFORE')"
            ></app-enervit-info>
          </div>
          <div (click)="addInfoFormGroup('JUST_BEFORE')">
            <app-icon-title
              [title]="'OTHER-PAGES.ENERVIT.ADD' | translate"
              icon="add"
            >
            </app-icon-title>
          </div>
        </mat-tab>

        <mat-tab
          class="app-tab-group"
          [label]="
            'OTHER-PAGES.ENERVIT.TABS.DURING-AFTER' | translate | uppercase
          "
        >
          <div class="mt4 mb4">
            <div class="mb4" [formGroup]="formGroup">
              <app-image-input
                [control]="formGroup.get('during_and_after')"
                [width]="110"
                [height]="180"
                [title]="'OTHER-PAGES.ENERVIT.IMAGES.DURING' | translate"
              ></app-image-input>
            </div>

            <app-enervit-info
              *ngFor="
                let info of getInfo('DURING_AND_AFTER').controls;
                let i = index
              "
              [formGroup]="info"
              type="'DURING_AND_AFTER'"
              [index]="i"
              (getIndex)="deleteInfoIndex($event, 'DURING_AND_AFTER')"
            ></app-enervit-info>
          </div>
          <div (click)="addInfoFormGroup('DURING_AND_AFTER')">
            <app-icon-title
              [title]="'OTHER-PAGES.ENERVIT.ADD' | translate"
              icon="add"
            >
            </app-icon-title>
          </div>
        </mat-tab>
      </div>
    </mat-tab-group>
  </app-detail-card>

  <app-footer (save)="onSaveClick()" (cancel)="onCancelClick()"></app-footer>
</div>

<app-spinner *ngIf="!loading"></app-spinner>
