import { Pipe, PipeTransform } from '@angular/core';
import { OtherPage } from '../model/other-pages.model';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Pipe({
  name: 'getCustomOtherPagesListTitle',
})
export class GetCustomOtherPagesListTitlePipe implements PipeTransform {
  transform(pageType: OtherPage): string {
    const pages: Record<string, string> = {
      [OtherPage.Accommodation]: marker(
        'OTHER-PAGES.ACCOMMODATION_LIST.TITLE'
      ) as string,
      [OtherPage.Food]: marker('OTHER-PAGES.FOOD_LIST.TITLE') as string,
      [OtherPage.Music]: marker('OTHER-PAGES.MUSIC_LIST.TITLE') as string,
      [OtherPage.Extra]: marker('OTHER-PAGES.EXTRA_LIST.TITLE') as string,
    };

    if (pages[pageType]) {
      return pages[pageType];
    }

    throw new Error(`Unknown page type: ${pageType}`);
  }
}
