import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { IEvent } from 'src/app/api/model/events.model';
import { EventService } from 'src/app/api/services/event/event.service';
import { Observable, Subscription } from 'rxjs';
import { LanguageService } from 'src/app/shared/services/language/language.service';
import {
  setDateWithTime,
  timeFormat,
} from 'src/app/shared/utils/dateFormatter.util';
import { TIME_REGEX } from 'src/app/shared/constants/time.constants';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { IComponentCanDeactivate } from 'src/app/core/model/can-deactive-guard.model';
import { ApiCallExtensionService } from 'src/app/shared/services/api-call-extension/api-call-extension.service';
import { PROGRESS_DIALOG_ACTION_SAVING } from 'src/app/shared/constants/dialog.constants';
import { reduceI18nObject } from 'src/app/shared/utils/reduce-i18n-object.util';
import { updateI18nObject } from 'src/app/shared/utils/update-i18n-object.util';

@Component({
  selector: 'app-general',
  templateUrl: './general.page.html',
  styleUrls: ['./general.page.scss'],
})
export class GeneralPage implements OnInit, OnDestroy, IComponentCanDeactivate {
  formGroup: FormGroup;
  activeEvent: IEvent;
  loading = false;

  private eventId: number;
  private activeLanguageCode: string;
  private subs = new Subscription();

  constructor(
    private eventService: EventService,
    private formBuilder: FormBuilder,
    private languageService: LanguageService,
    private translate: TranslateService,
    private apiCallExtensionService: ApiCallExtensionService
  ) {
    this.formGroup = this.formBuilder.group({
      time: this.formBuilder.group({
        time: ['', [Validators.pattern(TIME_REGEX), Validators.required]],
      }),
      event: this.formBuilder.group({
        inserted: [''],
        updated: [''],
        id: [''],
        name: [''],
        extId: [''],
        dateFrom: [''],
        eventOrder: [''],
        eventOrderResult: [''],
        dateTo: [''],
        baseMap: [''],
        banner1: [''],
        banner1Link: [''],
        banner2: [''],
        banner2Link: [''],
        eventShortCode: [''],
        landingImage: [''],
        organizerMap: [''],
        startTimestamp: ['', [Validators.required]],
      }),
    });
  }

  ngOnInit(): void {
    this.init();
  }

  canDeactivate(): boolean {
    return this.formGroup.untouched;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  init(): void {
    this.eventId = this.eventService.getEventId();
    this.activeLanguageCode = this.languageService.getActiveLanguage();
    this.loading = true;

    this.subs.add(
      this.eventService.getEventList().subscribe({
        next: (eventList) => {
          this.activeEvent = eventList.find(
            (event) => event.id === this.eventId
          );
          this.initFormGroup();
          this.setStartTime();
          this.loading = false;
        },
        error: (error: unknown) => {
          console.error('Cannot load event list', error);
        },
      })
    );
  }

  initFormGroup(): void {
    if (this.activeEvent) {
      const transformedObject = reduceI18nObject(
        this.activeEvent,
        this.activeLanguageCode
      );
      this.formGroup.get('event').setValue(transformedObject);
    }
  }

  setStartTime(): void {
    const DATE =
      this.formGroup.get('event').get('startTimestamp').value || new Date();

    const eventTime = timeFormat(new Date(DATE));
    this.formGroup.get('event').get('startTimestamp').setValue(DATE);
    this.formGroup.get('time').get('time').setValue(eventTime);
  }

  formatDateTime(): void {
    const DATE = this.formGroup.get('event').get('startTimestamp').value;
    const TIME = this.formGroup.get('time').get('time').value;
    const formatedDate = setDateWithTime(DATE, TIME);

    this.formGroup.get('event').get('startTimestamp').setValue(formatedDate);
  }

  onSaveClick(): void {
    this.formatDateTime();
    this.formGroup.markAsUntouched();

    this.subs.add(
      this.apiCallExtensionService
        .extendApiCall(
          () => this.updateGeneral(),
          this.translate.instant(marker(PROGRESS_DIALOG_ACTION_SAVING))
        )
        .subscribe({
          next: () => {
            this.init();
          },
        })
    );
  }

  updateGeneral(): Observable<any> {
    const eventFormGroup = this.formGroup.get('event');
    const event = updateI18nObject(
      { ...this.activeEvent },
      eventFormGroup.value,
      this.activeLanguageCode
    );

    return this.eventService.updateGeneral(event, this.activeLanguageCode);
  }
}
