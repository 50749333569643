import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, mergeMap, tap } from 'rxjs/operators';
import { EventsService } from 'src/app/api/service/events/events.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { IOtherPage } from '../../model/other-pages.model';
import { IPreference } from 'src/app/api/model/preference.model';
import { OtherPages } from '../../enums/other-pages.enum';
import { EventCode } from 'src/app/core/model/event.model';
import { ILanguages } from 'src/app/api/model/languages.model';

@Injectable({
  providedIn: 'root',
})
export class OtherPagesService {
  private readonly basePath = environment.apiUrl;
  private readonly SHORT_CODE = localStorage.getItem('eventShortCode');

  private J50: IOtherPage = {
    id: OtherPages.VIRTUAL_RACE,
    title: marker('PAGES.VIRTUAL_RACE'),
    type: 'VIRTUAL_RACE',
    translation: '',
    key: OtherPages.VIRTUAL_RACE,
    canCheck: false,
  };

  private OTHER_PAGES: IOtherPage[] = [
    {
      id: OtherPages.BASE_MAP,
      title: marker('PAGES.BASEMAP'),
      type: 'BASEMAP',
      translation: '',
      key: OtherPages.BASE_MAP,
      canCheck: true,
    },
    {
      id: OtherPages.TRANSPORT,
      title: marker('PAGES.TRANSPORT'),
      type: 'TRANSPORT',
      translation: '',
      key: OtherPages.TRANSPORT,
      canCheck: true,
    },
    {
      id: OtherPages.ENERVIT,
      title: marker('PAGES.ENERVIT'),
      type: 'ENERVIT',
      translation: '',
      key: OtherPages.ENERVIT,
      canCheck: true,
    },
    {
      id: OtherPages.CHARITY,
      title: marker('PAGES.CHARITY'),
      type: 'CHARITY',
      translation: '',
      key: OtherPages.CHARITY,
      canCheck: true,
    },
    {
      id: OtherPages.EXHIBITION,
      title: marker('PAGES.EXHIBITION'),
      type: 'EXHIBITION',
      translation: '',
      key: OtherPages.EXHIBITION,
      canCheck: true,
    },
    {
      id: OtherPages.SPECTATORS_SERVICE,
      title: marker('PAGES.SERVICE'),
      type: 'SERVICE',
      translation: '',
      key: OtherPages.SPECTATORS_SERVICE,
      canCheck: true,
    },
    {
      id: OtherPages.SPONSOR,
      title: marker('PAGES.SPONSOR'),
      type: 'SPONSOR',
      translation: '',
      key: OtherPages.SPONSOR,
      canCheck: true,
    },
    {
      id: OtherPages.EXTRAORDINARY_EVENTS,
      title: marker('PAGES.EXTRAORDINARY_EVENTS'),
      type: 'EXTRAORDINARY_EVENTS',
      translation: '',
      key: OtherPages.EXTRAORDINARY_EVENTS,
      canCheck: true,
    },
    {
      id: OtherPages.HYGIENE_MEASURES,
      title: marker('PAGES.HYGIENE_MEASURES'),
      type: 'HYGIENE_MEASURES',
      translation: '',
      key: OtherPages.HYGIENE_MEASURES,
      canCheck: true,
    },
    {
      id: OtherPages.RACE_HISTORY,
      title: marker('PAGES.RACE_HISTORY'),
      type: 'RACE_HISTORY',
      translation: '',
      key: OtherPages.RACE_HISTORY,
      canCheck: true,
    },
    {
      id: OtherPages.SOUVENIRS,
      title: marker('PAGES.SOUVENIRS'),
      type: 'SOUVENIRS',
      translation: '',
      key: OtherPages.SOUVENIRS,
      canCheck: true,
    },
    {
      id: OtherPages.TEST_CENTER_KASTLE,
      title: marker('PAGES.TEST_CENTER_KASTLE'),
      type: 'TEST_CENTER_KASTLE',
      translation: '',
      key: OtherPages.TEST_CENTER_KASTLE,
      canCheck: true,
    },
    {
      id: OtherPages.J50_NEWS,
      title: marker('PAGES.J50_NEWS'),
      type: OtherPages.J50_NEWS,
      translation: '',
      key: OtherPages.J50_NEWS,
      canCheck: true,
    },
    {
      id: OtherPages.SKITOUR_NEWS,
      title: marker('PAGES.SKITOUR_NEWS'),
      type: OtherPages.SKITOUR_NEWS,
      translation: '',
      key: OtherPages.SKITOUR_NEWS,
      canCheck: true,
    },
    {
      id: OtherPages.CROSS_COUNTRY_SKI_TRAIL,
      title: marker('PAGES.CROSS_COUNTRY_SKI_TRAIL'),
      type: OtherPages.CROSS_COUNTRY_SKI_TRAIL,
      translation: '',
      key: OtherPages.CROSS_COUNTRY_SKI_TRAIL,
      canCheck: true,
    },
    {
      id: OtherPages.WAXES_CLASSIC,
      title: marker('PAGES.WAXES-CLASSIC'),
      type: OtherPages.WAXES_CLASSIC,
      translation: '',
      key: OtherPages.WAXES_CLASSIC,
      canCheck: false,
    },
    {
      id: OtherPages.WAXES_SKATE,
      title: marker('PAGES.WAXES-SKATE'),
      type: OtherPages.WAXES_SKATE,
      translation: '',
      key: OtherPages.WAXES_SKATE,
      canCheck: false,
    },
  ];

  constructor(
    private httpClient: HttpClient,
    private eventsService: EventsService
  ) {}

  getPagesTitles(pageType: string): string {
    return this.OTHER_PAGES.find((page) => page.id === pageType)?.title;
  }

  getPagesTranslation(): Observable<IOtherPage[]> {
    this.addVirtualRace();
    return this.getTranslationFile().pipe(
      map((translation) => {
        this.OTHER_PAGES.forEach((item) => {
          item.translation = translation.PAGES[item.type];
        });
        return this.OTHER_PAGES;
      })
    );
  }

  addVirtualRace(): void {
    const NOT_ALLOWED_EVENTS = [EventCode.Jizerska50];
    if (
      !NOT_ALLOWED_EVENTS.some((event) => event === this.SHORT_CODE) &&
      !this.OTHER_PAGES.some((event) => event.id === OtherPages.VIRTUAL_RACE)
    ) {
      this.OTHER_PAGES.unshift(this.J50);
    }
  }

  getTranslationFile(): Observable<any> {
    return this.httpClient.get('./assets/i18n/cs.json');
  }

  otherPagesList(): Observable<any> {
    return of(this.OTHER_PAGES);
  }

  otherPagesDetail(otherPagesId: string): Observable<any> {
    return of(this.OTHER_PAGES.find((data) => data.id === otherPagesId));
  }

  getPreference(eventId: number): Observable<IPreference[]> {
    return this.httpClient.get<IPreference[]>(
      `${this.basePath}/events/${eventId}/preferences`
    );
  }

  createOrUpdatePreference(
    eventId: number,
    preferenceKey: string,
    preferenceValue: boolean
  ): Observable<void> {
    return this.httpClient.post<void>(
      `${this.basePath}/events/${eventId}/preference`,
      {},
      {
        params: {
          key: preferenceKey,
          value: preferenceValue.toString(),
        },
      }
    );
  }

  deletePreference(eventId: number, preferenceKey: string): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.basePath}/events/${eventId}/preference`,
      {
        params: {
          key: preferenceKey,
        },
      }
    );
  }

  getBaseMapImage(eventId: number): Observable<string> {
    if (!eventId) {
      return null;
    }
    return this.getBaseMap(eventId).pipe(
      map((url) => {
        if (url) {
          return `${this.basePath}/storage${url}`;
        }
        return '';
      })
    );
  }

  getBaseMap(eventId: number): Observable<ILanguages> {
    return this.eventsService.eventList().pipe(
      map((events) => {
        return events.find((event) => event.id === eventId)?.baseMap;
      })
    );
  }

  deleteBaseMap(eventId: number, languageCode: string): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.basePath}/events/${eventId}/${languageCode}/baseMap`
    );
  }

  uploadWeather(eventId: number, body: any): Observable<any> {
    return of(body);
  }

  uploadBaseMap(
    eventId: number,
    baseMap: File,
    activeLanguageCode: string
  ): Observable<void> {
    const formData = new FormData();
    formData.append('file', baseMap, baseMap.name.toLowerCase());

    return this.httpClient.post<void>(
      `${this.basePath}/events/${eventId}/${activeLanguageCode}/uploadBaseMap`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  }
}
