import { ILanguages } from '../../api/model/languages.model';
import {
  isI18nObject,
  replaceNullStringWithEmptyValue,
} from './i18n-objects.util';

export function reduceI18nObject<T extends object>(
  i18nObject: object,
  languageCode: string
): T {
  const reducedI18nObject: object = {};

  Object.keys(i18nObject).forEach((i18nObjectKey) => {
    reducedI18nObject[i18nObjectKey] = reduceI18nObjectValue(
      i18nObject[i18nObjectKey],
      languageCode
    );
  });

  return reducedI18nObject as T;
}

function reduceI18nObjectValue(
  i18nObjectValue: unknown,
  languageCode: string
): unknown | object | object[] | string {
  if (
    typeof i18nObjectValue === 'object' &&
    i18nObjectValue !== null &&
    i18nObjectValue.constructor?.name === 'Object'
  ) {
    if (Array.isArray(i18nObjectValue)) {
      return reduceI18nArray(i18nObjectValue, languageCode);
    }

    const isValueI18nObject = isI18nObject(i18nObjectValue);

    if (isValueI18nObject) {
      return replaceNullStringWithEmptyValue(
        (i18nObjectValue as ILanguages)[languageCode]
      );
    }
    return reduceI18nObject(i18nObjectValue, languageCode);
  }

  return i18nObjectValue;
}

export function reduceI18nArray<T extends unknown[]>(
  i18nArray: unknown[],
  languageCode: string
): T {
  return i18nArray.map((i18nArrayValue) =>
    reduceI18nObjectValue(i18nArrayValue, languageCode)
  ) as T;
}
