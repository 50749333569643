import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ImagesService } from 'src/app/api/service/images/images.service';
import { LanguageService } from 'src/app/shared/services/language/language.service';
import {
  IBasicFormContentInfo,
  ITranslatedBasicFormContentInfo,
} from 'src/app/shared/model/basic-form-content-info.model';
import { StorageService } from '../../services/storage/storage.service';
import { EVENT_ID_LS_KEY } from '../../constants/storage.constant';
import { map, mergeMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IImage } from 'src/app/api/model/image.model';
import { BasicFormContentService } from '../../services/basic-form-content/basic-form-content.service';
import { IComponentCanDeactivate } from 'src/app/core/model/can-deactive-guard.model';
import { TranslateService } from '@ngx-translate/core';
import { PROGRESS_DIALOG_ACTION_SAVING } from '../../constants/dialog.constants';
import { ApiCallExtensionService } from '../../services/api-call-extension/api-call-extension.service';
import { Location } from '@angular/common';

@Component({
  templateUrl: './basic-form-content.page.html',
  styleUrls: ['./basic-form-content.page.scss'],
})
export class BasicFormContentPage implements OnInit, IComponentCanDeactivate {
  private pageId: string;
  private deletedImageIds: number[] = [];
  private basicFormContentInfo: IBasicFormContentInfo;
  private readonly eventId = this.storageService.getData(
    EVENT_ID_LS_KEY
  ) as number;
  formGroup: FormGroup;
  loading = false;
  extended = false;

  get galleryFormArray(): FormArray {
    return this.formGroup.get('basicContentGallery') as FormArray;
  }

  get titleI18n(): string {
    return this.basicFormContentService.getPageTitleI18n(this.pageId);
  }

  constructor(
    private formBuilder: FormBuilder,
    private basicFormContentService: BasicFormContentService,
    private activatedRoute: ActivatedRoute,
    private imagesService: ImagesService,
    private languageService: LanguageService,
    private storageService: StorageService,
    private translate: TranslateService,
    private location: Location,
    private apiCallExtensionService: ApiCallExtensionService
  ) {
    this.formGroup = this.createFormGroup();
  }

  ngOnInit(): void {
    this.pageId = this.activatedRoute.snapshot.params.pageId;

    this.activatedRoute.params.subscribe((params) => {
      this.pageId = params.pageId;
      this.loading = true;

      this.initPage().subscribe((images) => {
        this.onImageGalleryLoaded(images);
        this.loading = false;
      });
    });
  }

  canDeactivate(): boolean {
    return this.formGroup.untouched;
  }

  onCancelClick(): void {
    this.location.back();
  }

  initPage(): Observable<IImage[]> {
    return this.basicFormContentService
      .getInfoByType(this.eventId, this.removeOrganizersPrefix().toLowerCase())
      .pipe(
        tap((basicFormContentInfo) => {
          this.initBasicFormContentInfo(basicFormContentInfo);
        }),
        map(() => {
          const translatedBasicFormContentInfo =
            this.translateBasicFormContentInfo(this.basicFormContentInfo);
          return translatedBasicFormContentInfo;
        }),
        tap((translatedBasicFormContentInfo) => {
          this.formGroup
            .get('basicContent')
            .setValue(translatedBasicFormContentInfo);
        }),
        mergeMap((translatedBasicFormContentInfo) =>
          this.getImageGallery(translatedBasicFormContentInfo)
        )
      );
  }

  initBasicFormContentInfo(basicFormContentInfo: IBasicFormContentInfo): void {
    const defaultBasicFormContentInfo =
      this.createDefaultBasicFormContentInfo();

    this.basicFormContentInfo =
      basicFormContentInfo ?? defaultBasicFormContentInfo;
  }

  translateBasicFormContentInfo(
    basicFormContentInfo: IBasicFormContentInfo
  ): ITranslatedBasicFormContentInfo {
    const activeLanguageCode = this.languageService.getActiveLanguage();

    return {
      ...basicFormContentInfo,
      description: basicFormContentInfo.description[activeLanguageCode],
      link: basicFormContentInfo.link[activeLanguageCode],
      title: basicFormContentInfo.title[activeLanguageCode],
    };
  }

  createDefaultBasicFormContentInfo(): IBasicFormContentInfo {
    return {
      inserted: new Date(),
      updated: new Date(),
      id: 0,
      type: this.pageId,
      title: {
        cs: '',
        en: '',
      },
      description: {
        cs: '',
        en: '',
      },
      link: {
        cs: '',
        en: '',
      },
    };
  }

  createFormGroup(): FormGroup {
    return this.formBuilder.group({
      basicContentGallery: this.formBuilder.array([]),
      basicContent: this.formBuilder.group({
        inserted: [''],
        updated: [''],
        id: [''],
        type: [''],
        title: [''],
        description: [''],
        link: [''],
      }),
    });
  }

  onImageGalleryLoaded(images: IImage[]): void {
    images.forEach(() => {
      this.addGalleryFormGroup();
    });
    this.galleryFormArray.setValue(images);
  }

  navigateToParent(): void {
    this.basicFormContentService.navigateToParent();
  }

  updateBasicFormContentInfo(): void {
    const translatedBasicFormContentInfo = this.formGroup.get('basicContent')
      .value as ITranslatedBasicFormContentInfo;

    this.basicFormContentInfo = this.untranslateBasicFormContentInfo(
      translatedBasicFormContentInfo
    );
  }

  untranslateBasicFormContentInfo(
    translatedBasicFormContentInfo: ITranslatedBasicFormContentInfo
  ): IBasicFormContentInfo {
    const activeLanguageCode = this.languageService.getActiveLanguage();

    return {
      ...translatedBasicFormContentInfo,
      description: {
        ...this.basicFormContentInfo.description,
        [activeLanguageCode]: translatedBasicFormContentInfo.description,
      },
      link: {
        ...this.basicFormContentInfo.link,
        [activeLanguageCode]: translatedBasicFormContentInfo.link,
      },
      title: {
        ...this.basicFormContentInfo.title,
        [activeLanguageCode]: translatedBasicFormContentInfo.title,
      },
    };
  }

  removeOrganizersPrefix(): string {
    return this.pageId.startsWith('org_')
      ? this.pageId.replace('org_', '')
      : this.pageId;
  }

  onSaveClick(): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    this.updateBasicFormContentInfo();
    this.formGroup.markAsUntouched();

    this.apiCallExtensionService
      .extendApiCall(
        () =>
          this.basicFormContentService.createOrUpdateInfo(
            this.eventId,
            this.basicFormContentInfo,
            this.removeOrganizersPrefix(),
            this.getUploadedImages(),
            this.deletedImageIds,
            activeLanguageCode
          ),
        this.translate.instant(PROGRESS_DIALOG_ACTION_SAVING)
      )
      .subscribe({
        next: () => {
          this.navigateToParent();
        },
      });
  }

  getImageGallery(
    basicFormContentInfo: ITranslatedBasicFormContentInfo
  ): Observable<IImage[]> {
    const activeLanguageCode = this.languageService.getActiveLanguage();

    return this.imagesService.getImages(
      basicFormContentInfo.id,
      this.basicFormContentService.imageType,
      activeLanguageCode
    );
  }

  addGalleryFormGroup(): void {
    this.galleryFormArray.push(this.createGalleryImageFormGroup());
  }

  getUploadedImages(): File[] {
    const galleryImages = this.galleryFormArray.value as IImage[];

    return galleryImages.reduce<File[]>((imageFiles, image: IImage) => {
      if (image.image instanceof File) {
        return imageFiles.concat(image.image);
      }
      return imageFiles;
    }, []);
  }

  createGalleryImageFormGroup(): FormGroup {
    return this.formBuilder.group({
      inserted: [''],
      updated: [''],
      id: [''],
      lang: [''],
      orderBy: [''],
      type: [''],
      image: [''],
    });
  }

  onDeleteImage(index: number): void {
    const galleryImages = this.galleryFormArray.value as IImage[];

    this.deletedImageIds.push(galleryImages[index]?.id);
    this.galleryFormArray.removeAt(index);
  }
}
