import { Pipe, PipeTransform } from '@angular/core';
import { filterDataBySearchInput } from 'src/app/shared/utils/filter.util';
import { IPage } from '../../model/page-list-item.model';

@Pipe({
  name: 'filterPagesByName',
})
export class FilterPagesByNamePipe implements PipeTransform {
  transform<T extends IPage>(pages: T[], name: string): T[] {
    return filterDataBySearchInput(pages, 'translation', name);
  }
}
