import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-back-panel',
  templateUrl: './back-panel.component.html',
  styleUrls: ['./back-panel.component.scss'],
})
export class BackPanelComponent implements OnInit {
  @Input() title: string;

  constructor(private location: Location) {}

  onClick(): void {
    this.location.back();
  }

  ngOnInit(): void {}
}
