import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  INotification,
  INotificationScreenPaths,
} from '../../model/notification/notification.model';
import { NotificationsService } from '../../services/notifications/notifications.service';
import {
  setDateWithTime,
  timeFormat,
} from 'src/app/shared/utils/dateFormatter.util';
import { EventsService } from 'src/app/api/service/events/events.service';
import { IEvent, ITopic } from 'src/app/api/model/events.model';
import { IRaceCode } from 'src/app/program/model/program.model';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { LanguageService } from 'src/app/shared/services/language/language.service';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { StorageService } from 'src/app/shared/services/storage/storage.service';
import { EVENT_SHORT_CODE_LS_KEY } from 'src/app/shared/constants/storage.constant';
import { TIME_REGEX } from 'src/app/shared/constants/time.constants';
import { IComponentCanDeactivate } from 'src/app/core/model/can-deactive-guard.model';
import {
  PROGRESS_DIALOG_ACTION_LOADING,
  PROGRESS_DIALOG_ACTION_SAVING,
} from 'src/app/shared/constants/dialog.constants';
import { ApiCallExtensionService } from 'src/app/shared/services/api-call-extension/api-call-extension.service';
import { mergeMap, tap } from 'rxjs/operators';
import { error } from '@angular/compiler/src/util';

@Component({
  selector: 'app-notifications-detail',
  templateUrl: './notifications-detail.component.html',
  styleUrls: ['./notifications-detail.component.scss'],
})
export class NotificationsDetailComponent
  implements OnInit, OnDestroy, IComponentCanDeactivate
{
  private readonly EVENT_ID = parseInt(localStorage.getItem('eventId'), 10);
  private readonly NOFITICATION_ID = parseInt(
    this.activatedRoute.snapshot.paramMap.get('id'),
    10
  );
  private readonly DEFAULT_RACE_CODE = 'general';
  private event: IEvent;
  private subs = new Subscription();
  raceCodes: IRaceCode[];
  notificationRoutes: INotificationScreenPaths[];

  notificationScreenPaths: INotificationScreenPaths[] = [];
  organizersScreenPaths: INotificationScreenPaths[] = [];

  topics: ITopic[] = [];
  notificationEvents: ITopic[] = [];

  disabledRoutes = new FormControl(false);

  selectedRaceCode: string;
  selectedNotificationRoute: string;
  formGroup: FormGroup;
  loading = false;
  notification: INotification;
  time = new FormControl();
  notificationEventControl = new FormControl();

  constructor(
    private notificationsService: NotificationsService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private eventsService: EventsService,
    private translate: TranslateService,
    private languageService: LanguageService,
    private storageService: StorageService,
    private apiCallExtensionService: ApiCallExtensionService
  ) {
    this.formGroup = this.formBuilder.group({
      time: this.formBuilder.group({
        time: ['', [Validators.pattern(TIME_REGEX), Validators.required]],
      }),
      notification: this.formBuilder.group({
        dateTime: [new Date(), [Validators.required]],
        id: [0],
        inserted: [new Date()],
        message: [''],
        sent: [false],
        lang: [''],
        title: [''],
        topic: [this.DEFAULT_RACE_CODE],
        eventTitle: [''],
        updated: [new Date()],
        screenPath: [this.screenPathToString('program')],
      }),
    });
    this.formGroup
      .get('notification')
      .get('topic')
      .valueChanges.subscribe((topic) => {
        this.setScreenPaths(topic);
        this.onTopicChange(topic);
      });
  }

  ngOnInit(): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    this.loading = true;

    this.subs.add(
      forkJoin([
        this.eventsService.eventDetail(this.EVENT_ID),
        this.notificationsService.getNotificationsDetailWithRaceCodes(
          this.NOFITICATION_ID,
          activeLanguageCode
        ),
        this.initNotificationScreenPaths(),
        this.initOrganizersScreenPaths(),
      ])
        .pipe(
          tap(
            ([
              event,
              notifications,
              notificationScreenPaths,
              organizesScreenPaths,
            ]) => {
              this.event = event;
              const NOTIFICATION_VALUE = notifications[0];
              this.topics = notifications[1] || [];

              this.notificationScreenPaths = notificationScreenPaths;
              this.organizersScreenPaths = organizesScreenPaths;

              const notificationTopic = NOTIFICATION_VALUE?.topic ?? '';
              this.setScreenPaths(notificationTopic);

              this.addNotificationsType();
              this.addNotificationsScreenPaths();

              if (NOTIFICATION_VALUE) {
                this.formGroup.get('notification').setValue(NOTIFICATION_VALUE);
                this.notification = NOTIFICATION_VALUE;
                this.parseNotificationProgram(this.notification.screenPath);
                this.setProgramControlValue(this.notification.topic);

                if (NOTIFICATION_VALUE.topic !== 'general') {
                  const topic = this.parseNotificationProgram(
                    this.notification.screenPath
                  );

                  this.formGroup
                    .get('notification')
                    .get('topic')
                    .setValue(topic.topic);
                }
              }

              const eventTime = NOTIFICATION_VALUE
                ? timeFormat(new Date(NOTIFICATION_VALUE?.dateTime))
                : timeFormat(new Date());

              this.formGroup.get('time').get('time').setValue(eventTime);

              this.loading = false;
            }
          )
        )
        .subscribe({
          error: (error: unknown) => {
            this.loading = false;
            console.error(error);
          },
        })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  canDeactivate(): boolean {
    return this.formGroup.untouched;
  }

  private setProgramControlValue(topic: string): void {
    if (topic.startsWith('pr_')) {
      this.notificationEventControl.setValue(topic);
    }
  }

  private parseNotificationProgram(screenPath: string): ITopic | undefined {
    const eventId = screenPath.substring(
      screenPath.indexOf(':') + 1,
      screenPath.indexOf(';')
    );

    return this.topics.find((topic) => topic.eventId === eventId.toString());
  }

  onTopicChange(topic: string): void {
    const eventId = this.topics.find(
      (topicValue) => topicValue.topic === topic
    )?.eventId;

    if (eventId !== undefined) {
      this.apiCallExtensionService
        .extendApiCall(
          () => this.notificationsService.getNotificationEvent(eventId),
          this.translate.instant(marker(PROGRESS_DIALOG_ACTION_LOADING))
        )
        .subscribe({
          next: (notificationEvents) => {
            this.notificationEvents = notificationEvents as ITopic[];

            this.notificationEvents.unshift({
              title: this.translate.instant(
                marker('NOTIFICATION.ALL-PROGRAMS')
              ),
              type: 'general',
              topic: 'general',
            });
          },
        });
    } else {
      this.notificationEvents = [];
      this.notificationEventControl.reset('general');
    }
  }

  initNotificationScreenPaths(): Observable<INotificationScreenPaths[]> {
    return this.notificationsService.getNotificationscreenPaths(
      this.EVENT_ID,
      ''
    );
  }

  initOrganizersScreenPaths(): Observable<INotificationScreenPaths[]> {
    const CURRENT_RACE_SHORT_CODE = this.storageService.getData(
      EVENT_SHORT_CODE_LS_KEY
    );
    return this.notificationsService.getOrganizerscreenPaths(
      this.EVENT_ID,
      `org_${CURRENT_RACE_SHORT_CODE}`
    );
  }

  getCurrentScreenPaths(raceCode = ''): INotificationScreenPaths[] {
    return raceCode.startsWith('org_')
      ? this.organizersScreenPaths
      : this.notificationScreenPaths;
  }

  setScreenPaths(topic: string): void {
    const screenPaths = this.getCurrentScreenPaths(topic);
    const generalScreenPath = this.screenPathToString(
      'program',
      this.formGroup.get('notification').get('topic').value
    );

    const foundScreenPath = screenPaths.find(
      (screenPath) => screenPath.path === generalScreenPath.toString()
    );

    if (!foundScreenPath) {
      screenPaths.splice(0, 1);

      screenPaths.unshift({
        name: this.translate.instant(marker('NOTIFICATION-ROUTES.BASIC')),
        path: generalScreenPath,
      });
    }

    this.notificationRoutes = [...screenPaths];
  }

  screenPathToString(screen: string, raceCode?: string): string {
    let includes = false;
    if (raceCode) {
      const parsedTopic = this.parseTopic(raceCode);
      const notAllowedRoute = ['general', 'org'];
      includes = notAllowedRoute.includes(parsedTopic);
    }
    const pathString =
      raceCode && !includes
        ? `eventId:${this.EVENT_ID};raceCode:${raceCode};screen:${screen}`
        : `eventId:${this.EVENT_ID};screen:${screen}`;
    return pathString;
  }

  isNotAllowedTopicType(raceCode: string): boolean {
    const parsedTopic = this.parseTopic(raceCode);
    const notAllowedRoute = ['general', 'org'];
    return notAllowedRoute.includes(parsedTopic);
  }

  isDisabledNotificationRoute(topic: string): void {
    if (topic) {
      const parsedTopic = this.parseTopic(topic);
      const notAllowedRoute = ['general', 'org'];
      const inclues = notAllowedRoute.includes(parsedTopic);
      this.disabledRoutes.setValue(inclues);
    }
    this.setNotificationRoutesToDefaulValue(topic);
  }

  setNotificationRoutesToDefaulValue(topic: string): void {
    if (this.disabledRoutes.value) {
      this.formGroup
        .get('notification')
        .get('screenPath')
        .setValue(this.screenPathToString('program', topic));
    }
  }

  parseTopic(topic: string): string {
    if (topic.startsWith('org')) {
      const index = topic.indexOf('-');
      return topic.substring(0, index);
    }
    return topic;
  }

  changeLoadingState(): void {
    this.loading = !this.loading;
  }

  addNotificationsScreenPaths(): void {
    const topic = this.formGroup.get('notification').get('topic').value;
    const screenPaths = this.getCurrentScreenPaths(topic);
    const generalScreenPath = this.screenPathToString(
      'program',
      this.formGroup.get('notification').get('topic').value
    );

    const foundScreenPath = screenPaths.find(
      (screenPath) => screenPath.path === generalScreenPath.toString()
    );

    if (!foundScreenPath) {
      const paths: INotificationScreenPaths[] = [
        {
          name: this.translate.instant(marker('NOTIFICATION-ROUTES.BASIC')),
          path: this.screenPathToString('program'),
        },
      ];

      paths.forEach((path) => {
        this.notificationRoutes.unshift(path);
      });
    }
  }

  addNotificationsType(): void {
    this.topics.unshift({
      title: this.translate.instant(marker('NOTIFICATION.ALL-RACES')),
      type: 'general',
      topic: 'general',
    });
  }

  formatDateTime(): void {
    let date = this.formGroup.get('notification').get('dateTime').value;
    date = new Date(date);
    date = date.toISOString();
    const time = this.formGroup.get('time').get('time').value;
    const formatedDate = setDateWithTime(date, time);
    this.formGroup.get('notification').get('dateTime').setValue(formatedDate);
  }

  onSaveClick(): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    this.formatDateTime();
    this.formGroup.get('notification').get('lang').setValue(activeLanguageCode);
    let notification = this.formGroup.get('notification').value;
    this.formGroup.markAsUntouched();

    if (!notification.id) {
      notification.eventTitle = this.event.name;
    }

    if (
      this.notificationEventControl.value &&
      this.notificationEventControl.value !== 'general'
    ) {
      notification = {
        ...notification,
        topic: this.notificationEventControl.value,
      };
    }

    this.apiCallExtensionService
      .extendApiCall(
        () =>
          this.notificationsService.createOrUpdateNotification(notification),
        this.translate.instant(marker(PROGRESS_DIALOG_ACTION_SAVING))
      )
      .subscribe({
        next: () => {
          this.navigateToNotificationList();
        },
      });
  }

  onCancelClick(): void {
    this.navigateToNotificationList();
  }

  private navigateToNotificationList(): void {
    this.router.navigate(['notifications', 'list']);
  }
}
