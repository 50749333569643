<div *ngIf="!loading">
  <app-back-panel
    *ngIf="isExtraInfo"
    [title]="'OTHER-PAGES.PAGES.BACK' | translate"
  ></app-back-panel>

  <app-list-top-card
    [title]="pageType | getCustomOtherPagesListTitle | translate"
  >
    <app-search-box
      class="mr2"
      [label]="'CORE.SEARCH' | translate"
      [value]="searchValue"
      (search)="onSearch($event)"
    ></app-search-box>

    <app-button
      [title]="'CORE.ADD' | translate | uppercase"
      [icon]="'add'"
      (click)="createCustomOtherPage(0)"
    ></app-button>
  </app-list-top-card>

  <app-basic-table
    [tableDataList]="customOtherPages | extractBasicTableProps : activeLanguageCode | searchBasicTableElement : searchValue"
    (delete)="onDeleteCustomOtherPage($event)"
    (rowClick)="onEditCustomOtherPage($event)"
  ></app-basic-table>
</div>

<app-spinner *ngIf="loading"></app-spinner>
