<app-header [title]="'GENERAL.TITLE' | translate"></app-header>

<ng-container *ngIf="!loading">
  <app-content>
    <div class="mb4">
      <div class="mb1">{{ "GENERAL.DATE.TITLE" | translate }}</div>

      <div class="mb3 flex fit justify-between">
        <div [formGroup]="formGroup.get('event')">
          <mat-form-field>
            <mat-label>{{ "GENERAL.DATE.DATE" | translate }}</mat-label>

            <input
              matInput
              [matDatepicker]="picker"
              formControlName="startTimestamp"
            />

            <mat-datepicker-toggle matSuffix [for]="picker">
              <mat-icon matDatepickerToggleIcon>date_range</mat-icon>
            </mat-datepicker-toggle>

            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

        <div [formGroup]="formGroup.get('time')">
          <mat-form-field>
            <mat-label>{{ "GENERAL.DATE.TIME" | translate }}</mat-label>

            <input formControlName="time" matInput type="time" />
          </mat-form-field>

          <div class="pl1 mb1">
            <mat-error *ngIf="formGroup.get('time').hasError('time')">
              {{ "COMMON.ERROR.TIME-FORMAT" | translate }}
            </mat-error>
          </div>
        </div>
      </div>
    </div>

    <div class="mb4">
      <div class="mb1" [formGroup]="formGroup.get('event')">
        <app-image-input
          [control]="formGroup.get('event').get('banner1')"
          [type]="'icon'"
          [title]="'GENERAL.BANNER1.IMAGE' | translate"
          [width]="375"
          [height]="48"
        ></app-image-input>
      </div>

      <div class="mb3" [formGroup]="formGroup.get('event')">
        <mat-form-field class="app-detail-race-form-field">
          <mat-label>{{ "GENERAL.BANNER1.LINK" | translate }}</mat-label>

          <input formControlName="banner1Link" matInput />
        </mat-form-field>
      </div>
    </div>

    <div>
      <div class="mb1" [formGroup]="formGroup.get('event')">
        <app-image-input
          [control]="formGroup.get('event').get('banner2')"
          [type]="'icon'"
          [title]="'GENERAL.BANNER2.IMAGE' | translate"
          [width]="375"
          [height]="48"
        ></app-image-input>
      </div>

      <div class="mb3" [formGroup]="formGroup.get('event')">
        <mat-form-field class="app-detail-race-form-field">
          <mat-label>{{ "GENERAL.BANNER2.LINK" | translate }}</mat-label>

          <input formControlName="banner2Link" matInput />
        </mat-form-field>
      </div>
    </div>

    <div>
      <div class="mb1" [formGroup]="formGroup.get('event')">
        <app-image-input
          [control]="formGroup.get('event').get('landingImage')"
          [type]="'icon'"
          [title]="'GENERAL.MAIN_IMAGE' | translate"
          [width]="375"
          [height]="146"
        ></app-image-input>
      </div>
    </div>
  </app-content>

  <app-footer
    class="mt4"
    [detail]="false"
    [showCancelButton]="false"
    [disabled]="formGroup.invalid"
    (save)="onSaveClick()"
  ></app-footer>
</ng-container>

<app-spinner *ngIf="loading"></app-spinner>
