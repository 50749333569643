import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { IBusConnectionGroupIndex } from '../../model/bus.model';

@Component({
  selector: 'app-transport-bus',
  templateUrl: './transport-bus.component.html',
  styleUrls: ['./transport-bus.component.scss'],
})
export class TransportBusComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() index: number;
  @Input() type: string;
  @Output() add = new EventEmitter<void>();
  @Output() getIndex = new EventEmitter<number>();
  @Output() del = new EventEmitter<void>();
  @Output() addConnectionThere = new EventEmitter<number>();
  @Output() addConnectionBack = new EventEmitter<number>();
  @Output() deleteConnectionThere =
    new EventEmitter<IBusConnectionGroupIndex>();
  @Output() deleteConnectionBack = new EventEmitter<IBusConnectionGroupIndex>();

  constructor() {}

  ngOnInit(): void {}

  getConnectionsThere(): FormArray {
    return this.formGroup.get('connectionsThere') as FormArray;
  }

  getConnectionsBack(): FormArray {
    return this.formGroup.get('connectionsBack') as FormArray;
  }

  onDeleteConnectionThere(connectionIndex: number): void {
    this.deleteConnectionThere.emit({
      busIndex: this.index,
      connectionIndex,
    });
  }

  onDeleteConnectionBack(connectionIndex: number): void {
    this.deleteConnectionBack.emit({
      busIndex: this.index,
      connectionIndex,
    });
  }

  onAddConnectionThere(): void {
    this.addConnectionThere.emit(this.index);
  }

  onAddConnectionBack(): void {
    this.addConnectionBack.emit(this.index);
  }
}
