import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ImageType } from 'src/app/api/model/image.model';
import { IBasicFormContentInfo } from 'src/app/shared/model/basic-form-content-info.model';
import { BasicFormContentService } from 'src/app/shared/services/basic-form-content/basic-form-content.service';
import { ORGANIZER_PAGES } from '../../constants/organizer-pages.constant';
import { OrganizerPage } from '../../model/organizer-page.model';
import { OrganizersService } from '../organizers/organizers.service';

@Injectable({
  providedIn: 'root',
})
export class OrganizersBasicFormContentService extends BasicFormContentService {
  readonly imageType = ImageType.OrganizersInfo;

  constructor(
    private router: Router,
    private organizersService: OrganizersService
  ) {
    super();
  }

  createOrUpdateInfo(
    eventId: number,
    info: IBasicFormContentInfo,
    infoType: OrganizerPage,
    galleryFiles: File[],
    deletedImagesId: number[],
    activeLanguageCode: string
  ): Observable<any> {
    return this.organizersService.createOrUpdateInfo(
      eventId,
      info,
      infoType,
      galleryFiles,
      deletedImagesId,
      activeLanguageCode
    );
  }

  getInfoByType(
    eventId: number,
    type: string
  ): Observable<IBasicFormContentInfo> {
    return this.organizersService.getOrganizersInfoByType(eventId, type);
  }

  navigateToParent(): void {
    this.router.navigate(['..', 'organizers']);
  }

  getPageTitleI18n(pageType: OrganizerPage): string {
    return ORGANIZER_PAGES.find((page) => page.id === pageType)?.titleI18n;
  }
}
