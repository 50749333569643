export interface IEnvervitImage {
  type: EnervitImageType;
  file: string | File;
}

export enum EnervitImageType {
  WeekBefore = 'week_before',
  JustBefore = 'just_before',
  DuringAndAfter = 'during_and_after',
}
