import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { IEventPreference } from 'src/app/api/model/events.model';
import { EventService } from 'src/app/api/services/event/event.service';

@Injectable({
  providedIn: 'root',
})
export class PreferencesService {
  constructor(private eventService: EventService) {}

  createOrUpdatePreference(
    eventId: number,
    preferenceKey: string,
    shouldBeVisible: boolean
  ): Observable<IEventPreference[]> {
    return this.eventService
      .createOrUpdatePreference(eventId, preferenceKey, shouldBeVisible)
      .pipe(mergeMap(() => this.eventService.getEventPreference(eventId)));
  }

  deletePreference(
    eventId: number,
    preferenceKey: string
  ): Observable<IEventPreference[]> {
    return this.eventService
      .deletePreference(eventId, preferenceKey)
      .pipe(mergeMap(() => this.eventService.getEventPreference(eventId)));
  }
}
