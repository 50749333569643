import { Program } from '../models/program.model';

export function getDefaultProgramByType(
  programType: string,
  personType: string
): any {
  switch (programType) {
    case Program.EVENT_SCHEDULE:
      return {
        about: {
          cs: '',
          en: '',
        },
        active: true,
        dateTimeFrom: new Date(),
        dateTimeTo: new Date(),
        eventId: 0,
        fbLink: {
          cs: '',
          en: '',
        },
        igLink: {
          cs: '',
          en: '',
        },
        stravaLink: {
          cs: '',
          en: '',
        },
        mapsLink: {
          cs: '',
          en: '',
        },
        importantInfoUrl: {
          cs: '',
          en: '',
        },
        id: 0,
        inserted: new Date(),
        place: {
          cs: '',
          en: '',
        },
        sponsorImg: {
          cs: '',
          en: '',
        },
        title: {
          cs: '',
          en: '',
        },
        tickets: {
          cs: '',
          en: '',
        },
        updated: new Date(),
        type: personType,
      };
    case Program.RACE_SCHEDULE:
      return {
        active: true,
        dateTimeFrom: new Date(),
        dateTimeTo: new Date(),
        eventId: 0,
        fbLink: {
          cs: '',
          en: '',
        },
        igLink: {
          cs: '',
          en: '',
        },
        stravaLink: {
          cs: '',
          en: '',
        },
        mapsLink: {
          cs: '',
          en: '',
        },
        heightProfileImg: {
          cs: '',
          en: '',
        },
        id: 0,
        inserted: new Date(),
        place: {
          cs: '',
          en: '',
        },
        sponsorImg: {
          cs: '',
          en: '',
        },
        title: {
          cs: '',
          en: '',
        },
        updated: new Date(),
        mapImg: {
          cs: '',
          en: '',
        },
        startNumEmission: {
          cs: '',
          en: '',
        },
        startType: {
          cs: '',
          en: '',
        },
        startWaves: {
          cs: '',
          en: '',
        },
        technique: {
          cs: '',
          en: '',
        },
        raceCode: {
          cs: '',
          en: '',
        },
        type: personType,
        urlInfoNumRel: {
          cs: '',
          en: '',
        },
        importantInfoUrl: {
          cs: '',
          en: '',
        },
        tickets: {
          cs: '',
          en: '',
        },
      };
    default:
      throw new Error(`Not supported type: ${programType}`);
  }
}
