import { Component, OnInit } from '@angular/core';
import { Routes, ActivatedRoute, Router } from '@angular/router';
import { NewsService } from '../../services/news/news.service';
import { IType } from '../../model/type.model';
import { TypesService } from '../../services/types/types.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss'],
})
export class NewsDetailComponent implements OnInit {
  private newsId: number | string;
  types: IType[] = [];
  title: string;
  text: string;
  formGroup: FormGroup;
  newDetail = null;
  imageURL = 'assets/image/snow.jpg';

  constructor(
    private route: ActivatedRoute,
    private newsService: NewsService,
    private typesService: TypesService,
    private fb: FormBuilder
  ) {
    this.formGroup = this.fb.group({
      title: [''],
      text: [''],
    });
  }

  onChangeType(type): void {}

  save(): void {}

  ngOnInit(): void {
    this.newsId = this.route.snapshot.paramMap.get('newId');
    this.newsService
      .getNewDetail(parseInt(this.newsId, 10))
      .subscribe((detail) => {
        this.newDetail = detail;
      });
    this.types = this.typesService.get().filter((type) => type.id !== 0);
    this.initData();
  }

  initData(): void {
    this.formGroup.get('title').setValue(this.newDetail.title);
    this.formGroup.get('text').setValue(this.newDetail.text);
  }
}
