<button
  mat-flat-button
  *ngIf="weight === 'primary'"
  class="app-button app-button--primary"
  [class.app-button--slim]="isSlim"
  [class.app-button--primary_enabled]="!disabled && !danger"
  [color]="danger ? 'warn' : 'accent'"
  [disabled]="disabled"
>
  <ng-container *ngTemplateOutlet="buttonContentTmp"></ng-container>
</button>

<button
  mat-stroked-button
  *ngIf="weight === 'secondary'"
  class="app-button app-button--secondary"
  [class.app-button--slim]="isSlim"
  [color]="danger ? 'warn' : 'primary'"
  [disabled]="disabled"
>
  <ng-container *ngTemplateOutlet="buttonContentTmp"></ng-container>
</button>

<button
  mat-button
  *ngIf="weight === 'tertiary'"
  class="app-button app-button--tertiary"
  [class.app-button--underline]="underline"
  [class.app-button--slim]="isSlim"
  [color]="danger ? 'default' : 'primary'"
  [disabled]="disabled"
>
  <ng-container *ngTemplateOutlet="buttonContentTmp"></ng-container>
</button>

<!-- template enabling adding ng-content into *ngIf -->
<ng-template #buttonContentTmp>
  <ng-content></ng-content>
</ng-template>
