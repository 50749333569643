import { Program } from '../enums/program.enum';

export function getDefaultProgramByType(
  programType: string,
  personType: string
): any {
  switch (programType) {
    case Program.EVENT_SCHEDULE:
      return {
        about: {
          cs: '',
          en: '',
        },
        active: true,
        dateTimeFrom: new Date(),
        dateTimeTo: new Date(),
        eventId: 0,
        fbLink: {
          cs: '',
          en: '',
        },
        id: 0,
        inserted: new Date(),
        place: {
          cs: '',
          en: '',
        },
        sponsorImg: {
          cs: '',
          en: '',
        },
        title: {
          cs: '',
          en: '',
        },
        updated: new Date(),
        gpsLat: 0,
        gpsLon: 0,
        type: personType,
      };
    case Program.RACE_SCHEDULE:
      return {
        active: true,
        dateTimeFrom: new Date(),
        dateTimeTo: new Date(),
        eventId: 0,
        fbLink: {
          cs: '',
          en: '',
        },
        heightProfileImg: {
          cs: '',
          en: '',
        },
        id: 0,
        inserted: new Date(),
        place: {
          cs: '',
          en: '',
        },
        sponsorImg: {
          cs: '',
          en: '',
        },
        title: {
          cs: '',
          en: '',
        },
        updated: new Date(),
        mapImg: {
          cs: '',
          en: '',
        },
        startNumEmission: {
          cs: '',
          en: '',
        },
        startType: {
          cs: '',
          en: '',
        },
        startWaves: {
          cs: '',
          en: '',
        },
        technique: {
          cs: '',
          en: '',
        },
        raceCode: {
          cs: '',
          en: '',
        },
        gpsLat: 0,
        gpsLon: 0,
        type: personType,
        urlInfoNumRel: {
          cs: '',
          en: '',
        },
        urlInfoRace: {
          cs: '',
          en: '',
        },
      };
    default:
      throw new Error(`Not supported type: ${programType}`);
  }
}
