import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-detail-card',
  templateUrl: './detail-card.component.html',
  styleUrls: ['./detail-card.component.scss'],
})
export class DetailCardComponent implements OnInit {
  constructor() {}
  width = 700;

  @HostListener('window:resize', ['$event'])
  onResize(event): void {}

  ngOnInit(): void {}
}
