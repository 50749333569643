<app-dialog-layout [showClose]="data.showClose" [showActions]="true">
  <ng-container content>
    <div class="flex justify-between items-baseline mb3 mt2">
      <div class="text-large bold">
        {{ data.title }}
      </div>

      <mat-form-field class="app-sorting-event-result-dialog">
        <mat-label>{{ "SORTING_EVENT_RESULTS.YEAR" | translate }}</mat-label>

        <mat-select [formControl]="data.sortingListYearControl">
          <mat-option
            *ngFor="let year of generateSortingListYearOptions()"
            [value]="year"
            (selectionChange)="onSelectionChange(year)"
          >
            {{ year }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="pb3" [class.pt2]="!data.showClose">
      <app-sorting-event-results
        [results]="results"
        (resultChange)="onResultChange($event)"
      ></app-sorting-event-results>
    </div>
  </ng-container>

  <ng-container actions>
    <app-prompt-buttons
      [cancelText]="data.cancelText"
      [confirmText]="data.confirmText"
      [confirmDisabled]="!results.length"
      (cancel)="onClose(true)"
      (confirm)="onClose()"
    >
    </app-prompt-buttons>
  </ng-container>
</app-dialog-layout>
