import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { IType } from 'src/app/news/model/type.model';
import { PROGRAM_LIST_TABLE_COLUMNS } from '../../constants/program-table.constants';
import { IProgramRaceEvent } from '../../models/program.model';

@Component({
  selector: 'app-program-list-table',
  templateUrl: './program-list-table.component.html',
  styleUrls: ['./program-list-table.component.scss'],
})
export class ProgramListTableComponent implements OnInit {
  @Input() program: IProgramRaceEvent[];
  @Input() type: string;
  @Input() scheduleType: string;
  @Input() types: IType[];

  @Output() delete = new EventEmitter<IProgramRaceEvent>();
  @Output() programItemClick = new EventEmitter<IProgramRaceEvent>();

  dataSource: MatTableDataSource<any>;
  columns = PROGRAM_LIST_TABLE_COLUMNS;

  constructor(private router: Router) {}

  ngOnInit(): void {
    // empty
  }

  typeName(id: number): string {
    return this.types.find((type) => type.id === id)?.value || '';
  }

  onProgramItemClick(program: IProgramRaceEvent): void {
    this.programItemClick.emit(program);
  }

  navigate({ id, type }): void {
    const navigationExtras = {
      queryParams: {
        type: type ? type : null,
        scheduleType: this.scheduleType ? this.scheduleType : null,
      },
    };
    this.router.navigate([this.type, 'detail', id], navigationExtras);
  }
}
