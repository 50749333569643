import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  IImportantContact,
  IContactGroup,
} from '../../model/important-contact.model';

@Injectable({
  providedIn: 'root',
})
export class OrganizerContactService {
  private readonly basePath = environment.apiUrl;

  constructor(private httpClient: HttpClient) {}

  getImportantContacts(
    eventId: number,
    activeLanguageCode: string
  ): Observable<IImportantContact[]> {
    return this.httpClient.get<IImportantContact[]>(
      `${this.basePath}/event/${eventId}/${activeLanguageCode}/organizerContact`
    );
  }

  createOrUpdateImportantContacts(
    eventId: number,
    contactGroups: IContactGroup[],
    activeLanguageCode: string
  ): Observable<any> {
    const apiContacts: IImportantContact[] = this.extractContactsFromGroups(
      contactGroups,
      activeLanguageCode
    );

    return this.httpClient.post<void>(
      `${this.basePath}/event/${eventId}/${activeLanguageCode}/organizerContacts`,
      apiContacts
    );
  }

  extractContactsFromGroups(
    contactGroups: IContactGroup[],
    activeLanguageCode: string
  ): IImportantContact[] {
    const contacts: IImportantContact[] = [];

    contactGroups.forEach((contactGroup) => {
      contactGroup.contacts.forEach((contact) => {
        contact.groupName[activeLanguageCode] = contactGroup.groupName;
        contacts.push(contact);
      });
    });

    return contacts;
  }

  deleteImportantContacts(ids: number[]): Observable<void> {
    return this.httpClient.delete<void>(`${this.basePath}/organizerContact`, {
      params: {
        ids: ids.toString(),
      },
    });
  }
}
