import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-schedule-list',
  templateUrl: './schedule-list.component.html',
  styleUrls: ['./schedule-list.component.scss'],
})
export class ScheduleListComponent implements OnInit {
  data = [];
  columns = [];
  constructor() {}

  onSearch(searchInput: string): void {
    if (searchInput) {
    } else {
      this.ngOnInit();
    }
  }

  ngOnInit(): void {}
}
