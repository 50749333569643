<div *ngIf="program">
  <app-back-panel [title]="'PROGRAM.DETAIL.BACK' | translate"></app-back-panel>

  <app-list-top-card [title]="getProgramTitle()"> </app-list-top-card>

  <app-detail-card>
    <div>
      <b> {{ "PROGRAM.DETAIL.TYPE" | translate }}</b>
      <mat-radio-group class="app-news-detail-radio-buttons">
        <mat-radio-button
          *ngFor="let type of types"
          [checked]="type.id === programType"
          [disabled]="true"
          [value]="type.id"
          class="app-news-detail-radio-buttons-button mr2"
          >{{ type.value | translate }}</mat-radio-button
        >
      </mat-radio-group>
    </div>
  </app-detail-card>

  <app-program-detail-event
    *ngIf="programType === 1"
    [event]="program"
  ></app-program-detail-event>

  <app-program-detail-race
    *ngIf="programType === 2"
    [race]="program"
  ></app-program-detail-race>
</div>
<app-spinner *ngIf="!program"></app-spinner>
