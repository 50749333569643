import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotificationsListComponent } from './components/notifications-list/notifications-list.component';
import { NotificationsDetailComponent } from './components/notifications-detail/notifications-detail.component';
import { CanDeactiveGuard } from '../core/guard/can-deactive.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'list',
  },
  {
    path: 'list',
    pathMatch: 'full',
    component: NotificationsListComponent,
  },
  {
    path: 'detail/:id',
    pathMatch: 'full',
    component: NotificationsDetailComponent,
    canDeactivate: [CanDeactiveGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NotificationsRoutingModule {}
