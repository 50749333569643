import { Pipe, PipeTransform } from '@angular/core';
import { IOtherPage } from '../model/other-pages.model';
import { IBasicFormContentInfo } from '../model/basic-form-content-info.model';

@Pipe({
  name: 'getOtherPageTitle',
})
export class GetOtherPageTitlePipe implements PipeTransform {
  transform(
    pages: IOtherPage[],
    activeLanguageCode: string,
    pageContent: IBasicFormContentInfo
  ): unknown {
    const foundPage = pages.find((page) => page.id === pageContent?.type);

    return foundPage?.isBasicPage
      ? foundPage.titleI18n
      : pageContent?.title[activeLanguageCode];
  }
}
