<div>
  <app-list-top-card [title]="'NEWS.TITLE' | translate">
    <mat-form-field class="app-element-margin">
      <mat-label>{{ "NEWS.FILTERS.TYPE" | translate }}</mat-label>
      <mat-select [(value)]="selected" (selectionChange)="onTypeChange($event)">
        <mat-option *ngFor="let type of types" [value]="type.id">
          {{ type.value | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <app-filter-panel
      [title]="'NEWS.FILTERS.SEARCH' | translate"
      (search)="onSearch($event)"
      class="app-element-margin"
    ></app-filter-panel>
    <app-button
      [title]="'NEWS.ADD' | translate | uppercase"
      [icon]="'add'"
      class="app-element-margin"
    ></app-button>
  </app-list-top-card>
  <app-dynamic-table
    [data]="data"
    [type]="'news'"
    [types]="types"
    [columns]="columns"
  ></app-dynamic-table>
</div>
