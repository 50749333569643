import { Component, OnInit } from '@angular/core';
import { ProgramService } from '../../services/program/program.service';
import { TypesService } from '../../services/types/types.service';
import {
  IProgramEventPost,
  IProgramRacePost,
  IType,
} from '../../model/program.model';
import { ActivatedRoute } from '@angular/router';
import { Program } from '../../enums/program.enum';
import { getDefaultProgramByType } from '../../utils/defaultProgram.util';
import { LanguageService } from 'src/app/shared/services/language/language.service';

@Component({
  selector: 'app-program-detail',
  templateUrl: './program-detail.component.html',
  styleUrls: ['./program-detail.component.scss'],
})
export class ProgramDetailComponent implements OnInit {
  constructor(
    private programService: ProgramService,
    private activatedRoute: ActivatedRoute,
    private typesService: TypesService,
    private languageService: LanguageService
  ) {}
  private programId: number;
  types: IType[];
  programType: number;
  program: IProgramEventPost | IProgramRacePost;
  private readonly EVENT_ID = parseInt(localStorage.getItem('eventId'), 10);
  private scheduleType: string;

  ngOnInit(): void {
    this.scheduleType =
      this.activatedRoute.snapshot.queryParamMap.get('scheduleType');
    this.initProgramDetail();
  }

  getProgramType(): string {
    return this.programType === 1
      ? Program.EVENT_SCHEDULE
      : Program.RACE_SCHEDULE;
  }

  getProgramTitle(): string {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    return this.program.title[activeLanguageCode];
  }

  initProgramDetail(): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    this.programId = parseInt(
      this.activatedRoute.snapshot.paramMap.get('programId'),
      10
    );
    this.activatedRoute.queryParams.subscribe((data) => {
      this.programType = parseInt(data?.type, 10);
      this.types = this.typesService.get().filter((type) => type.id !== 0);
      this.programService
        .getProgramDetail(
          this.EVENT_ID,
          this.programId,
          this.getProgramType(),
          this.scheduleType,
          activeLanguageCode
        )
        .subscribe((program) => {
          this.program =
            program[0] ||
            getDefaultProgramByType(
              this.getProgramType(),
              this.scheduleType.toLowerCase()
            );
        });
    });
  }
}
