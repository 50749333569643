import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { LanguageService } from 'src/app/shared/services/language/language.service';
import {
  IImportantContact,
  IContactGroup,
} from 'src/app/api/model/important-contact.model';
import { OrganizerContactService } from 'src/app/api/services/organizer-contact/organizer-contact.service';
import { StorageService } from 'src/app/shared/services/storage/storage.service';
import { EVENT_ID_LS_KEY } from 'src/app/shared/constants/storage.constant';
import { TranslateService } from '@ngx-translate/core';
import { PROGRESS_DIALOG_ACTION_SAVING } from 'src/app/shared/constants/dialog.constants';
import { ApiCallExtensionService } from 'src/app/shared/services/api-call-extension/api-call-extension.service';
import {
  ITranslatedContactGroup,
  ITranslatedImportantContact,
} from '../../model/important-contacts.model';
import { ImportantContactsService } from '../../services/important-contacts/important-contacts.service';
import { Location } from '@angular/common';
import {
  reduceI18nArray,
  reduceI18nObject,
} from '../../utils/reduce-i18n-object.util';
import { updateI18nObject } from '../../utils/update-i18n-object.util';

@Component({
  templateUrl: './important-contacts.page.html',
  styleUrls: ['./important-contacts.page.scss'],
})
export class ImportantContactsPage implements OnInit {
  loading = false;
  formArray: FormArray;
  deletedContactsId: number[] = [];
  private importantContacts: ITranslatedImportantContact[];
  private contacts: IImportantContact[] = [];
  private readonly eventId = this.storageService.getData(
    EVENT_ID_LS_KEY
  ) as number;

  constructor(
    private formBuilder: FormBuilder,
    private importantContactsService: ImportantContactsService,
    private languageService: LanguageService,
    private organizerContactService: OrganizerContactService,
    private storageService: StorageService,
    private translate: TranslateService,
    private apiCallExtensionService: ApiCallExtensionService,
    private location: Location
  ) {
    this.formArray = this.formBuilder.array([]);
  }

  ngOnInit(): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    this.loading = true;

    this.organizerContactService
      .getImportantContacts(this.eventId, activeLanguageCode)
      .subscribe({
        next: (contacts) => {
          this.contacts = contacts;
          this.importantContacts = this.translateImportantContact(contacts);

          const uniqueContactGroups =
            this.importantContactsService.getUniqueContactGroupsWithEmptyContacts(
              this.importantContacts
            );

          this.initFormArray(uniqueContactGroups);
          this.loading = false;
        },
        error: (error: unknown) => {
          console.error(error);
        },
      });
  }

  canDeactivate(): boolean {
    return this.formArray.untouched;
  }

  onSaveClick(): void {
    const importantContacts = this.formToContactGroups();
    const activeLanguageCode = this.languageService.getActiveLanguage();
    this.formArray.markAsUntouched();

    this.apiCallExtensionService
      .extendApiCall(
        () =>
          this.importantContactsService.saveImportantContact(
            this.eventId,
            importantContacts,
            this.deletedContactsId,
            activeLanguageCode
          ),
        this.translate.instant(PROGRESS_DIALOG_ACTION_SAVING)
      )
      .subscribe({
        next: () => {
          this.navigateToOrganizers();
        },
      });
  }

  onCancelClick(): void {
    this.navigateToOrganizers();
  }

  private navigateToOrganizers(): void {
    this.location.back();
  }

  translateImportantContact(
    contacts: IImportantContact[]
  ): ITranslatedImportantContact[] {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    const nextLanguage =
      this.languageService.getActiveLanguage() === 'cs' ? 'en' : 'cs';
    const translatedContacts: ITranslatedImportantContact[] = [];

    contacts.forEach((contact) => {
      translatedContacts.push({
        id: contact.id,
        inserted: contact.inserted,
        updated: contact.updated,
        groupName:
          contact.groupName[activeLanguageCode] === ''
            ? contact.groupName[nextLanguage]
            : contact.groupName[activeLanguageCode],
        name: contact.name[activeLanguageCode],
        phone: contact.phone[activeLanguageCode],
      });
    });

    return translatedContacts;
  }

  initFormArray(uniqueContactGroups: ITranslatedContactGroup[]): void {
    uniqueContactGroups.forEach(() => {
      this.addContactGroupFormGroup();
    });

    const transformedContacts =
      this.importantContactsService.transformImportantContactsFromAPI(
        this.importantContacts
      );

    this.formArray.setValue(uniqueContactGroups);
    this.initContactFormArray(transformedContacts);
  }

  initContactFormArray(transformedContacts: ITranslatedContactGroup[]): void {
    for (let i = 0; i < this.formArray.length; i++) {
      const contactGroupFormControl = this.formArray.at(i);
      const contacts = transformedContacts.find(
        (contact) =>
          contact.groupName === contactGroupFormControl.value?.groupName
      )?.contacts;

      contacts.forEach(() => {
        this.addContactFormGroup(
          contactGroupFormControl.get('contacts') as FormArray
        );
      });
      contactGroupFormControl.get('contacts').setValue(contacts);
    }
  }

  addContactGroupFormGroup(): void {
    this.formArray.push(this.createContactGroupFormGroup());
  }

  addContactFormGroup(formArray: FormArray): void {
    formArray.push(this.createContacFormGroup());
  }

  onClickDeleteContactGroup(index: number): void {
    const contactGroup = this.formArray.at(index)
      .value as ITranslatedContactGroup;

    contactGroup.contacts.forEach((contact) => {
      if (contact.id !== 0) {
        this.deletedContactsId.push(contact.id);
      }
    });
    this.formArray.removeAt(index);
  }

  onClickDeleteContact(index: number, personIndex?: number): void {
    const contactGroupFormGroup = this.formArray.at(index);
    const contactGroup = contactGroupFormGroup.value as ITranslatedContactGroup;
    const person = contactGroup.contacts[personIndex];

    if (person.id !== 0) {
      this.deletedContactsId.push(person.id);
    }
    (contactGroupFormGroup.get('contacts') as FormArray).removeAt(personIndex);
  }

  createContactGroupFormGroup(): FormGroup {
    return this.formBuilder.group({
      groupName: [''],
      contacts: this.formBuilder.array([]),
    });
  }

  createContacFormGroup(): FormGroup {
    return this.formBuilder.group({
      groupName: [''],
      name: [''],
      phone: [''],
      id: [0],
      inserted: [''],
      updated: [''],
    });
  }

  untranslateImportantContacts(
    translatedContacts: ITranslatedImportantContact[]
  ): IImportantContact[] {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    const finalContacts: IImportantContact[] = [];

    this.contacts.forEach((contact) => {
      const foundTranslatedContact = translatedContacts.find(
        (translatedContact) =>
          translatedContact.id === contact.id && translatedContact.id !== 0
      );

      if (foundTranslatedContact) {
        finalContacts.push(
          updateI18nObject(contact, foundTranslatedContact, activeLanguageCode)
        );
      }
    });

    translatedContacts.forEach((translatedContact) => {
      if (translatedContact.id === 0) {
        finalContacts.push({
          id: translatedContact.id,
          inserted: translatedContact.inserted,
          updated: translatedContact.updated,
          groupName: {
            cs: activeLanguageCode === 'cs' ? translatedContact.groupName : '',
            en: activeLanguageCode === 'en' ? translatedContact.groupName : '',
          },
          name: {
            cs: activeLanguageCode === 'cs' ? translatedContact.name : '',
            en: activeLanguageCode === 'en' ? translatedContact.name : '',
          },
          phone: {
            cs: activeLanguageCode === 'cs' ? translatedContact.phone : '',
            en: activeLanguageCode === 'en' ? translatedContact.phone : '',
          },
        });
      }
    });

    return finalContacts;
  }

  formToContactGroups(): IContactGroup[] {
    const translatedContactGroups = this.formArray
      .value as ITranslatedContactGroup[];
    const contactGroups: IContactGroup[] = [];

    translatedContactGroups.forEach((importantContact) => {
      contactGroups.push({
        groupName: importantContact.groupName,
        contacts: this.untranslateImportantContacts(importantContact.contacts),
      });
    });

    return contactGroups;
  }

  getContactsFormArray(contactGroupformGroup: FormGroup): FormArray {
    return contactGroupformGroup.get('contacts') as FormArray;
  }
}
