export interface INotification {
  dateTime: Date;
  id: number;
  inserted: Date;
  message: string;
  sent: boolean;
  lang: string;
  title: string;
  topic: string;
  screenPath: string;
  updated: Date;
  eventTitle: string;
}

export interface INotificationRoute {
  flag: NotificationRoute;
  translation: string;
}

export interface INotificationScreenPaths {
  name: string;
  path: string;
}

export interface IPath {
  eventId: number;
  raceCode: string;
  screen: string;
}

export enum NotificationRoute {
  basic = 'basic',
  eventDetail = 'eventDetail',
  game = 'game',
  informations = 'informations',
  registration = 'registration',
  results = 'results',
  sponsor = 'sponsor',
  waxes = 'waxes',
}
