import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { EventService } from 'src/app/api/services/event/event.service';
import {
  IBasicFinalContent,
  IBasicFormContentInfo,
  IExtendBasicFormContentInfo,
  ITranslatedBasicFinalFormContentInfo,
  ITranslatedBasicFormContentInfo,
} from 'src/app/shared/model/basic-form-content-info.model';
import { LanguageService } from 'src/app/shared/services/language/language.service';
import { map, mergeMap, tap } from 'rxjs/operators';
import { ApiCallExtensionService } from 'src/app/shared/services/api-call-extension/api-call-extension.service';
import { TranslateService } from '@ngx-translate/core';
import { PROGRESS_DIALOG_ACTION_SAVING } from 'src/app/shared/constants/dialog.constants';
import { IOtherPage, OtherPage } from 'src/app/shared/model/other-pages.model';
import { OtherPagesService } from 'src/app/shared/services/other-pages/other-pages.service';
import { IComponentCanDeactivate } from 'src/app/core/model/can-deactive-guard.model';
import { BasicFormDetailService } from '../../services/basic-form-detail.service';
import { IImage } from 'src/app/api/model/image.model';
import { BasicFormContentService } from '../../services/basic-form-content/basic-form-content.service';
import { ImagesService } from 'src/app/api/service/images/images.service';
import { iif } from 'rxjs';
import { ExtendedFormContentService } from '../../services/extended-form-content/extended-form-content.service';

@Component({
  templateUrl: './basic-form-detail.page.html',
  styleUrls: ['./basic-form-detail.page.scss'],
})
export class BasicFormDetailPage implements OnInit, IComponentCanDeactivate {
  private pageId: number;
  loading = false;
  extended = false;
  formGroup: FormGroup;
  private subs = new Subscription();
  pageContent: IBasicFinalContent;
  pageType: OtherPage;
  private deletedImageIds: number[] = [];

  get eventId(): number {
    return this.eventService.getEventId();
  }

  get pages(): IOtherPage[] {
    return this.basicFormDetailService.getOtherPages();
  }

  get activeLanguageCode(): string {
    return this.languageService.getActiveLanguage();
  }

  get galleryFormArray(): FormArray {
    return this.formGroup.get('basicContentGallery') as FormArray;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private otherPagesService: OtherPagesService,
    private eventService: EventService,
    private languageService: LanguageService,
    private apiCallExtensionService: ApiCallExtensionService,
    private translate: TranslateService,
    private location: Location,
    public basicFormDetailService: BasicFormDetailService,
    private basicFormContentService: BasicFormContentService,
    private imagesService: ImagesService,
    private extendedFormContentService: ExtendedFormContentService<IExtendBasicFormContentInfo>
  ) {}

  ngOnInit(): void {
    this.pageId = parseInt(this.activatedRoute.snapshot.params.id, 10);
    this.pageType = this.activatedRoute.snapshot.params.type;
    this.extended = JSON.parse(
      this.activatedRoute.snapshot.queryParamMap.get('extended')
    );

    this.formGroup = this.createFormGroup();
    this.init();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  canDeactivate(): boolean {
    return this.formGroup.untouched;
  }

  init(): void {
    this.loading = true;

    this.subs.add(
      this.getPages()
        .pipe(
          tap((pages) => {
            const foundPage = pages.find((page) => page.id === this.pageId);

            this.initFormContent(foundPage);
          }),
          map(() => this.translateBasicFormContentInfo(this.pageContent)),
          tap((translatedFormContent) => {
            this.formGroup.get('basicContent').setValue({
              ...translatedFormContent,
              youtubeLink: this.getProperyValue('youtubeLink'),
            });
          }),
          mergeMap((translatedBasicFormContentInfo) =>
            this.getImageGallery(translatedBasicFormContentInfo)
          )
        )
        .subscribe({
          next: (images) => {
            this.onImageGalleryLoaded(images);
            this.loading = false;
          },
        })
    );
  }

  private getProperyValue(name: string): string {
    return this.pageContent[name] ?? '';
  }

  getImageGallery(
    basicFormContentInfo: ITranslatedBasicFormContentInfo
  ): Observable<IImage[]> {
    const activeLanguageCode = this.languageService.getActiveLanguage();

    return this.imagesService.getImages(
      basicFormContentInfo.id,
      this.getImageType(),
      activeLanguageCode
    );
  }

  private getImageType(): string {
    return [OtherPage.Music, OtherPage.Extra].includes(this.pageType)
      ? this.extendedFormContentService.getImageType()
      : this.basicFormContentService.imageType;
  }

  onSaveClick(): void {
    this.updateBasicFormContentInfo();
    this.formGroup.markAsUntouched();

    this.apiCallExtensionService
      .extendApiCall(
        () => this.createOrUpdate(),
        this.translate.instant(PROGRESS_DIALOG_ACTION_SAVING)
      )
      .subscribe({
        next: () => {
          this.navigateToParent();
        },
      });
  }

  private mapPageContent(): any {
    switch (this.pageType) {
      case OtherPage.Extra: {
        const content = { ...this.pageContent };
        delete content.youtubeLink;
        return content;
      }
      case OtherPage.Music: {
        const content = { ...this.pageContent };
        return content;
      }
      default: {
        const content = { ...this.pageContent };
        delete content.youtubeLink;
        return content;
      }
    }
  }

  private getPages(): Observable<
    IBasicFormContentInfo[] | IExtendBasicFormContentInfo[]
  > {
    return iif(
      () => this.isClassicOtherPage(),
      this.otherPagesService.getOtherPagesList(
        this.eventService.getEventId(),
        this.pageType
      ),
      this.extendedFormContentService.getExtendedList(
        this.eventService.getEventId()
      )
    );
  }

  private createOrUpdate(): Observable<
    IBasicFormContentInfo | IExtendBasicFormContentInfo
  > {
    const activeLanguageCode = this.languageService.getActiveLanguage();

    return iif(
      () => this.isClassicOtherPage(),
      this.otherPagesService.createOrUpdateInfo(
        this.eventId,
        this.mapPageContent(),
        this.pageType,
        this.getUploadedImages(),
        this.deletedImageIds,
        activeLanguageCode
      ),
      this.extendedFormContentService.createOrUpdate(
        this.eventId,
        this.mapPageContent(),
        this.getUploadedImages(),
        this.deletedImageIds,
        activeLanguageCode
      )
    );
  }

  private isClassicOtherPage(): boolean {
    return ![OtherPage.Music, OtherPage.Extra].includes(this.pageType);
  }

  getUploadedImages(): File[] {
    const galleryImages = this.galleryFormArray.value as IImage[];

    return galleryImages.reduce<File[]>((imageFiles, image: IImage) => {
      if (image.image instanceof File) {
        return imageFiles.concat(image.image);
      }
      return imageFiles;
    }, []);
  }

  updateBasicFormContentInfo(): void {
    const translatedBasicFormContentInfo = this.formGroup.get('basicContent')
      .value as ITranslatedBasicFormContentInfo;

    this.pageContent = this.untranslateBasicFormContentInfo(
      translatedBasicFormContentInfo
    );
  }

  untranslateBasicFormContentInfo(
    translatedBasicFormContentInfo: ITranslatedBasicFinalFormContentInfo
  ): IBasicFinalContent {
    const activeLanguageCode = this.languageService.getActiveLanguage();

    return {
      ...translatedBasicFormContentInfo,
      description: {
        ...this.pageContent.description,
        [activeLanguageCode]: translatedBasicFormContentInfo.description,
      },
      link: {
        ...this.pageContent.link,
        [activeLanguageCode]: translatedBasicFormContentInfo.link,
      },
      title: {
        ...this.pageContent.title,
        [activeLanguageCode]: translatedBasicFormContentInfo.title,
      },
    };
  }

  navigateToParent(): void {
    this.location.back();
  }

  addGalleryFormGroup(): void {
    this.galleryFormArray.push(this.createGalleryImageFormGroup());
  }

  createGalleryImageFormGroup(): FormGroup {
    return this.formBuilder.group({
      inserted: [''],
      updated: [''],
      id: [''],
      lang: [''],
      orderBy: [''],
      type: [''],
      image: [''],
    });
  }

  onImageGalleryLoaded(images: IImage[]): void {
    images.forEach(() => {
      this.addGalleryFormGroup();
    });
    this.galleryFormArray.setValue(images);
  }

  translateBasicFormContentInfo(
    basicFormContentInfo: IBasicFormContentInfo
  ): ITranslatedBasicFormContentInfo {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    return {
      ...basicFormContentInfo,
      description: basicFormContentInfo.description[activeLanguageCode],
      link: basicFormContentInfo.link[activeLanguageCode],
      title: basicFormContentInfo.title[activeLanguageCode],
    };
  }

  onDeleteImage(index: number): void {
    const galleryImages = this.galleryFormArray.value as IImage[];

    this.deletedImageIds.push(galleryImages[index]?.id);
    this.galleryFormArray.removeAt(index);
  }

  initFormContent(pageContent: IBasicFormContentInfo): void {
    const extendPageContent: IExtendBasicFormContentInfo = {
      ...pageContent,
      youtubeLink: '',
    };
    const defaultBasicFormContentInfo = this.createDefaultFormContent();

    const content = pageContent
      ? !this.isClassicOtherPage()
        ? pageContent
        : extendPageContent
      : defaultBasicFormContentInfo;

    this.pageContent = content;
  }

  createDefaultFormContent():
    | IBasicFormContentInfo
    | IExtendBasicFormContentInfo {
    return {
      inserted: new Date(),
      updated: new Date(),
      id: 0,
      type: this.pageType,
      youtubeLink: '',
      title: {
        cs: '',
        en: '',
      },
      description: {
        cs: '',
        en: '',
      },
      link: {
        cs: '',
        en: '',
      },
    };
  }

  createFormGroup(): FormGroup {
    return this.formBuilder.group({
      basicContentGallery: this.formBuilder.array([]),
      basicContent: this.formBuilder.group({
        inserted: [''],
        updated: [''],
        id: [''],
        type: [''],
        title: [''],
        description: [''],
        link: [''],
        youtubeLink: [''],
      }),
    });
  }
}
