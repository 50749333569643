import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IEventPreference } from 'src/app/api/model/events.model';
import { IPageVisibilityChangeEvent } from 'src/app/shared/model/page-list-item.model';
import {
  IOrganizerPage,
  OrganizerPage,
} from '../../model/organizer-page.model';

@Component({
  selector: 'app-organizer-pages-table',
  templateUrl: './organizer-pages-table.component.html',
  styleUrls: ['./organizer-pages-table.component.scss'],
})
export class OrganizerPagesTableComponent implements OnInit {
  @Input() organizerPages: IOrganizerPage[];
  @Input() preferences: IEventPreference[];
  @Input() activeLanguageCode: string;
  @Output() navigateToPage = new EventEmitter<OrganizerPage>();
  @Output() changePageVisibility = new EventEmitter<
    IPageVisibilityChangeEvent<OrganizerPage>
  >();
  columns = ['enabled', 'title', 'edit'];

  constructor() {}

  ngOnInit(): void {}

  canCheck(value: any): boolean {
    return value ?? true;
  }

  onNavigateToPage({ id }: IOrganizerPage): void {
    this.navigateToPage.emit(id);
  }

  isPageVisibleInApp(pageId: string): boolean {
    return this.preferences.some((preference) => {
      const keyValues = preference.key.split(';');
      return (
        keyValues[1] === pageId && keyValues[0] === this.activeLanguageCode
      );
    });
  }

  public onVisibilityChange(
    statePage: boolean,
    organizerPage: IOrganizerPage
  ): void {
    this.changePageVisibility.emit({
      page: organizerPage,
      shouldBeVisible: statePage,
    });
  }
}
