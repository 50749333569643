import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-simple-dialog-content',
  templateUrl: './simple-dialog-content.component.html',
  styleUrls: ['./simple-dialog-content.component.scss'],
})
export class SimpleDialogContentComponent implements OnInit {
  @Input() imgPath: string | undefined;
  @Input() mainText: string | undefined;
  @Input() subText: string | undefined;

  constructor() {}

  ngOnInit(): void {
    // empty
  }
}
