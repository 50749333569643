<div
  class="app-footer-wrapper left-0 bottom-0 full-width fixed mt4 px3 border-box z1"
>
  <div
    class="app-footer-box app-footer-content-wrapper full-height items-center flex justify-between"
  >
    <div>
      <app-button
        *ngIf="showCancelButton"
        (click)="cancel.emit()"
        [title]="'CORE.BUTTON.CANCEL' | translate"
        [icon]="'cancel'"
        [secondary]="true"
      ></app-button>
    </div>

    <div>
      <app-custom-button
        weight="primary"
        (click)="save.emit()"
        [disabled]="disabled"
      >
        <div class="flex items-center">
          <div class="flex">
            <mat-icon class="app-footer-icon mr1">save</mat-icon>
          </div>
          <div>{{ "CORE.BUTTON.SAVE" | translate | uppercase }}</div>
        </div>
      </app-custom-button>
    </div>
  </div>
</div>
