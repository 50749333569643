import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IStartInstruction } from '../../model/start-instruction.model';

@Injectable({
  providedIn: 'root',
})
export class StartInstructionsService {
  private readonly basePath = environment.apiUrl;

  constructor(private httpClient: HttpClient) {}

  getStartInstructions(scheduleRaceId: number): Observable<IStartInstruction> {
    return this.httpClient.get<IStartInstruction>(
      `${this.basePath}/startInstructions/getStartInstructions`,
      {
        params: {
          raceScheduleId: scheduleRaceId.toString(),
        },
      }
    );
  }

  patchStartInstructions(
    startInstruction: IStartInstruction
  ): Observable<IStartInstruction> {
    return this.httpClient.patch<IStartInstruction>(
      `${this.basePath}/startInstructions`,
      startInstruction
    );
  }
}
