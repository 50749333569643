<div class="flex justify-center items-center">
  <div>
    <div class="mb1 flex justify-center">
      <div *ngIf="imgPath">
        <img class="mb2" [src]="imgPath" />
      </div>
    </div>

    <div>
      <div
        *ngIf="mainText"
        class="text-large center bold"
        [class.mb2]="subText"
      >
        <div>{{ mainText }}</div>
      </div>

      <div
        *ngIf="subText"
        class="app-simple-dialog-content-sub-text text-medium center"
      >
        <div>{{ subText }}</div>
      </div>
    </div>
  </div>
</div>
