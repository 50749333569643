import { IPage } from '../../../shared/model/page-list-item.model';

export enum OrganizerPage {
  BaseMap = 'org_baseMap',
  ImportantContacts = 'org_important_contacts',
  OtherInformation = 'org_other_information',
  Transport = 'org_transport',
  Accommodation = 'org_accommodation',
  Catering = 'org_catering',
  Position = 'org_position',
  HygieneMeasures = 'org_hygiene_measures',
  ExtraordinaryEvents = 'org_extraordinary_events',
  ImportantInfo = 'org_extraordinary_info',
}

export type IOrganizerPage = IPage<OrganizerPage>;
