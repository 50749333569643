import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/core/guard/auth.guard';
import { MainLayoutComponent } from '../app/core/components/main-layout/main-layout.component';

const ROUTES: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'game',
    loadChildren: () => import('./game/game.module').then((m) => m.GameModule),
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'program',
        loadChildren: () =>
          import('./modules/programs/programs.module').then(
            (m) => m.ProgramsModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'other-pages',
        loadChildren: () =>
          import('./modules/other-pages/other-pages.module').then(
            (m) => m.OtherPagesModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./notifications/notifications.module').then(
            (m) => m.NotificationsModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'general',
        loadChildren: () =>
          import('./modules/general/general.module').then(
            (m) => m.GeneralModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'extra',
        loadChildren: () =>
          import('./modules/extra-info/extra-info.module').then(
            (m) => m.ExtraInfoModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: '',
        redirectTo: 'general',
        pathMatch: 'full',
      },
      {
        path: '**',
        redirectTo: 'general',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
