import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { IOtherPage, OtherPage } from '../model/other-pages.model';

export const OTHER_PAGES: IOtherPage[] = [
  {
    id: OtherPage.BaseMap,
    titleI18n: marker('PAGES.BASEMAP'),
    type: 'BASEMAP',
    translation: '',
    key: OtherPage.BaseMap,
    canCheck: true,
    isBasicPage: false,
    containList: false,
  },

  {
    id: OtherPage.Tickets,
    titleI18n: marker('PAGES.TICKETS'),
    type: 'TICKETS',
    translation: '',
    key: OtherPage.Tickets,
    canCheck: true,
    isBasicPage: true,
    containList: false,
  },
  {
    id: OtherPage.Accommodation,
    titleI18n: marker('PAGES.ACCOMODATION'),
    type: 'ACCOMODATION',
    translation: '',
    key: OtherPage.Accommodation,
    canCheck: true,
    isBasicPage: false,
    containList: true,
  },

  {
    id: OtherPage.Food,
    titleI18n: marker('PAGES.FOOD'),
    type: 'FOOD',
    translation: '',
    key: OtherPage.Food,
    canCheck: true,
    isBasicPage: false,
    containList: true,
  },

  {
    id: OtherPage.PracticalInfo,
    titleI18n: marker('PAGES.PRACTICAL_INFO'),
    type: 'PRACTICAL_INFO',
    translation: '',
    key: OtherPage.PracticalInfo,
    canCheck: true,
    isBasicPage: true,
    containList: false,
  },
  {
    id: OtherPage.Faq,
    titleI18n: marker('PAGES.FAQ'),
    type: 'FAQ',
    translation: '',
    key: OtherPage.Faq,
    canCheck: true,
    isBasicPage: true,
    containList: false,
  },

  {
    id: OtherPage.Partners,
    titleI18n: marker('PAGES.PARTNERS'),
    type: 'PARTNERS',
    translation: '',
    key: OtherPage.Partners,
    canCheck: true,
    isBasicPage: true,
    containList: false,
  },

  {
    id: OtherPage.ImportantContacts,
    titleI18n: marker('PAGES.IMPORTANT_CONCATS'),
    type: 'IMPORTANT_CONCATS',
    translation: '',
    key: OtherPage.ImportantContacts,
    canCheck: true,
    isBasicPage: false,
    containList: false,
  },

  {
    id: OtherPage.Music,
    titleI18n: marker('PAGES.MUSIC'),
    type: 'MUSIC',
    translation: '',
    key: OtherPage.Music,
    canCheck: true,
    isBasicPage: false,
    containList: true,
  },
];
