import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ListTopCardComponent } from './component/list-top-card/list-top-card.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DynamicTableComponent } from './component/dynamic-table/dynamic-table.component';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FilterPanelComponent } from './component/filter-panel/filter-panel.component';
import { DetailCardComponent } from './component/detail-card/detail-card.component';
import { BackPanelComponent } from './component/back-panel/back-panel.component';
import { ButtonComponent } from './component/button/button.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmptyResultComponent } from './component/empty-result/empty-result.component';
import { TruncatePipe } from './pipes/truncate/truncate.pipe';
import { SpinnerComponent } from './component/spinner/spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FileInputComponent } from './component/file-input/file-input.component';
import { ImagePanelComponent } from './component/image-panel/image-panel.component';
import { ImageInputComponent } from './component/image-input/image-input.component';
import { IconTitleComponent } from './component/icon-title/icon-title.component';
import { ImageGalleryComponent } from './component/image-gallery/image-gallery.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DialogWindowComponent } from './component/dialog-window/dialog-window.component';
import { DialogQuestionComponent } from './component/dialog-question/dialog-question.component';
import { LayoutModule } from '@angular/cdk/layout';
import { AddSectionWrapperComponent } from './component/add-section-wrapper/add-section-wrapper.component';
import { TextEditorComponent } from './component/text-editor/text-editor.component';
import { QuillModule } from 'ngx-quill';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { HeaderComponent } from './component/sections/header/header.component';
import { ContentComponent } from './component/sections/content/content.component';
import { FooterComponent } from './component/sections/footer/footer.component';
import { BasicFormContentComponent } from './component/forms/basic-form-content/basic-form-content.component';
import { BasicFormContentPage } from './pages/basic-form-content/basic-form-content.page';
import { FilterPagesByNamePipe } from './pipes/filter-pages-by-name/filter-pages-by-name.pipe';
import { TranslatePagesTitlesPipe } from './pipes/translate-pages-titles/translate-pages-titles.pipe';
import { DialogComponent } from './component/dialogs/dialog/dialog.component';
import { DialogLayoutComponent } from './component/dialogs/dialog-layout/dialog-layout.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PromptButtonsComponent } from './component/prompt-buttons/prompt-buttons.component';
import { ProgressDialogComponent } from './component/dialogs/progress-dialog/progress-dialog.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { ConfirmDialogComponent } from './component/dialogs/confirm-dialog/confirm-dialog.component';
import { SimpleDialogContentComponent } from './component/dialogs/simple-dialog-content/simple-dialog-content.component';
import { CustomButtonComponent } from './component/custom-button/custom-button.component';
import { SnackbarComponent } from './component/snackbar/snackbar.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SearchBoxComponent } from './component/search-box/search-box.component';
import { BasicTableComponent } from './component/tables/basic-table/basic-table.component';
import { ExtractBasicTablePropsPipe } from './pipes/extract-basic-table-props.pipe';
import { BasicFormDetailPage } from './pages/basic-form-detail/basic-form-detail.page';
import { BasicFormDetailContentComponent } from './component/basic-form-detail-content/basic-form-detail-content.component';
import { ImportantContactsPage } from './pages/important-contacts/important-contacts.page';
import { GetOtherPageTitlePipe } from './pipes/get-other-page-title.pipe';
import { CustomOtherPagesListPage } from './pages/custom-other-pages-list/custom-other-pages-list.page';
import { GetCustomOtherPagesListTitlePipe } from './pipes/get-custom-other-pages-list-title.pipe';
import { SearchBasicTableElementPipe } from './pipes/search-basic-table-element.pipe';
import { SpinnerContentComponent } from './component/spinner-content/spinner-content.component';

@NgModule({
  declarations: [
    ListTopCardComponent,
    DynamicTableComponent,
    FilterPanelComponent,
    DetailCardComponent,
    BackPanelComponent,
    ButtonComponent,
    EmptyResultComponent,
    TruncatePipe,
    SpinnerComponent,
    FileInputComponent,
    ImagePanelComponent,
    ImageInputComponent,
    IconTitleComponent,
    ImageGalleryComponent,
    DialogWindowComponent,
    DialogQuestionComponent,
    AddSectionWrapperComponent,
    TextEditorComponent,
    HeaderComponent,
    ContentComponent,
    FooterComponent,
    BasicFormContentComponent,
    BasicFormContentPage,
    FilterPagesByNamePipe,
    TranslatePagesTitlesPipe,
    DialogComponent,
    DialogLayoutComponent,
    PromptButtonsComponent,
    ProgressDialogComponent,
    ConfirmDialogComponent,
    SimpleDialogContentComponent,
    CustomButtonComponent,
    SnackbarComponent,
    SearchBoxComponent,
    BasicTableComponent,
    ExtractBasicTablePropsPipe,
    BasicFormDetailPage,
    BasicFormDetailContentComponent,
    ImportantContactsPage,
    GetOtherPageTitlePipe,
    CustomOtherPagesListPage,
    GetCustomOtherPagesListTitlePipe,
    SearchBasicTableElementPipe,
    SpinnerContentComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatListModule,
    MatTableModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    LayoutModule,
    QuillModule.forRoot(),
    CKEditorModule,
    DragDropModule,
    MatTooltipModule,
  ],
  exports: [
    CommonModule,
    TranslateModule,
    ListTopCardComponent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    DynamicTableComponent,
    FilterPanelComponent,
    DetailCardComponent,
    BackPanelComponent,
    ButtonComponent,
    EmptyResultComponent,
    TruncatePipe,
    SpinnerComponent,
    ImagePanelComponent,
    FileInputComponent,
    ImageInputComponent,
    IconTitleComponent,
    ImageGalleryComponent,
    MatCheckboxModule,
    DialogWindowComponent,
    DialogQuestionComponent,
    AddSectionWrapperComponent,
    TextEditorComponent,
    HeaderComponent,
    ContentComponent,
    FooterComponent,
    MatTableModule,
    BasicFormContentPage,
    BasicFormContentComponent,
    FilterPagesByNamePipe,
    TranslatePagesTitlesPipe,
    DialogLayoutComponent,
    DragDropModule,
    CKEditorModule,
    PromptButtonsComponent,
    ProgressDialogComponent,
    MatTabsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatRadioModule,
    MatDialogModule,
    MatTableModule,
    MatTabsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    LayoutModule,
    MatTooltipModule,
    SearchBoxComponent,
    BasicTableComponent,
    ExtractBasicTablePropsPipe,
    BasicFormDetailPage,
    BasicFormContentComponent,
    ImportantContactsPage,
    CustomOtherPagesListPage,
  ],
  entryComponents: [DialogQuestionComponent],
})
export class SharedModule {}
