import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarRef,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarComponent } from '../../component/snackbar/snackbar.component';
import { DEFAULT_SNACKBAR_DURATION_MS } from '../../constants/snackbar.constants';
import {
  ISnackbarData,
  SnackbarHorizontalPosition,
  SnackbarVerticalPosition,
} from '../../model/snackbar.model';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  openSnackbar(
    params: ISnackbarData,
    verticalPosition: MatSnackBarVerticalPosition = SnackbarVerticalPosition.Top,
    horizontalPosition: MatSnackBarHorizontalPosition = SnackbarHorizontalPosition.Right,
    duration = DEFAULT_SNACKBAR_DURATION_MS
  ): MatSnackBarRef<SnackbarComponent> {
    return this.snackBar.openFromComponent(SnackbarComponent, {
      verticalPosition,
      horizontalPosition,
      duration,
      data: params,
      panelClass: params.classes,
    });
  }

  getSuccessSnackbarData(): ISnackbarData {
    return {
      icon: 'check_circle_outline',
      success: true,
      mainText: this.translate.instant(marker('COMMON.API_REQUEST.SUCCESS')),
      classes: ['app-snackbar--success'],
    };
  }

  getErrorSnackbarData(): ISnackbarData {
    return {
      icon: 'close',
      success: false,
      mainText: this.translate.instant(marker('COMMON.API_REQUEST.ERROR')),
      classes: ['app-snackbar--error'],
    };
  }
}
