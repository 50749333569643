import { Injectable } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  FormArray,
  Form,
} from '@angular/forms';
import {
  ITransportBus,
  ITransportBusTranslate,
} from 'src/app/other-pages/model/other-pages.model';
import { GroupTypes } from 'src/app/shared/enums/group-types.enum';
import { TIME_REGEX } from '../../constants/time.constants';
import { timeFormat } from '../../utils/dateFormatter.util';

@Injectable({
  providedIn: 'root',
})
export class FormsService {
  constructor(private formBuilder: FormBuilder) {}

  addFormGroup(type: string, formGroup: any): FormGroup {
    (formGroup.get(type) as FormArray).push(this.createGroup(type));
    return formGroup;
  }

  initFormGroup(element: any[], type: string, formGroup: any): FormGroup {
    element.forEach(() => {
      this.addFormGroup(type, formGroup);
    });
    formGroup.get(type).setValue(element);
    return formGroup;
  }

  addBusFormGroup(formGroup: any): FormGroup {
    (formGroup.get('bus') as FormArray).push(
      this.formBuilder.group({
        connectionsBack: this.createBusLineFormGroup([]),
        connectionsThere: this.createBusLineFormGroup([]),
        id: [0],
        inserted: [''],
        linkBack: [''],
        linkThere: [''],
        name: [''],
        nameBack: [''],
        nameThere: [''],
        stopNameBack: [''],
        stopNameThere: [''],
        updated: [''],
        gpsLatBack: [''],
        gpsLatThere: [''],
        gpsLonBack: [''],
        gpsLonThere: [''],
        routeName: [''],
      })
    );

    return formGroup;
  }

  initBusesFormGroup(
    buses: ITransportBusTranslate[],
    formGroup: any
  ): FormGroup {
    const updatedBuses: ITransportBusTranslate[] = [];
    buses.forEach((bus, index) => {
      (formGroup.get('bus') as FormArray).push(
        this.formBuilder.group({
          connectionsBack: this.createBusLineFormGroup(bus.connectionsBack),
          connectionsThere: this.createBusLineFormGroup(bus.connectionsThere),
          id: [0],
          inserted: [''],
          linkBack: [''],
          linkThere: [''],
          name: [''],
          nameBack: [''],
          nameThere: [''],
          stopNameBack: [''],
          stopNameThere: [''],
          updated: [''],
          gpsLatBack: [''],
          gpsLatThere: [''],
          gpsLonBack: [''],
          gpsLonThere: [''],
          routeName: [''],
        })
      );

      bus.connectionsBack = this.initConnections(bus.connectionsBack);
      bus.connectionsThere = this.initConnections(bus.connectionsThere);
      updatedBuses.push(bus);
    });

    formGroup.get('bus').patchValue(updatedBuses);
    return formGroup;
  }

  initConnections(connections: string[]): any[] {
    return connections.map((connection) => {
      const date = new Date(connection);
      date.setHours(date.getHours() - 1);

      return {
        date: new Date(date),
        time: timeFormat(new Date(date)),
      };
    });
  }

  createBusLineFormGroup(connections: string[]): FormArray {
    const formArray = new FormArray([]);

    connections.forEach(() => {
      formArray.push(
        this.formBuilder.group({
          date: ['', [Validators.required]],
          time: ['', [Validators.pattern(TIME_REGEX), Validators.required]],
        })
      );
    });

    return formArray;
  }

  initFormGroupEnervit(
    element: any[],
    type: string,
    formGroup: any
  ): FormGroup {
    element.forEach(() => {
      this.addFormGroup(type, formGroup);
    });
    const filteredByType = element.filter((info) => info.type === type);

    formGroup.get(type).setValue(filteredByType);
    return formGroup;
  }

  getFormArray(type: string, formGroup: FormGroup): FormArray {
    return formGroup.get(type) as FormArray;
  }

  deleteFormGroup(type: string, index: number, formGroup: FormGroup): any {
    return (formGroup.get(type) as FormArray).removeAt(index);
  }

  deleteIndex(index: number, type: string, formGroup: FormGroup): any {
    return this.deleteFormGroup(type, index, formGroup);
  }

  initFormArray(formArray: FormArray, data: any[]): FormArray {
    formArray.setValue(data);
    return formArray;
  }

  createGroup(type: string): FormGroup {
    switch (type) {
      case 'parking':
        return this.formBuilder.group({
          id: [0],
          name: [''],
          description: [''],
          link: [''],
          updated: [''],
          inserted: [''],
          gpsLat: [''],
          gpsLon: [''],
        });
      case 'taxi':
        return this.formBuilder.group({
          id: [0],
          inserted: [''],
          mobile: [''],
          name: [''],
          phone: [''],
          updated: [''],
          web: [''],
        });
      case 'parkingInfo':
        return this.formBuilder.group({
          id: [0],
          description: [''],
          inserted: [''],
          warning: [''],
          name: [''],
          updated: [''],
        });
      case 'bus':
        return this.formBuilder.group({
          connectionsBack: this.formBuilder.array([]),
          connectionsThere: this.formBuilder.array([]),
          id: [0],
          inserted: [''],
          linkBack: [''],
          linkThere: [''],
          name: [''],
          nameBack: [''],
          nameThere: [''],
          stopNameBack: [''],
          stopNameThere: [''],
          updated: [''],
          gpsLatBack: [''],
          gpsLatThere: [''],
          gpsLonBack: [''],
          gpsLonThere: [''],
          routeName: [''],
        });
      case GroupTypes.IMPORTANT_INFO:
        return this.formBuilder.group({
          inserted: [''],
          updated: [''],
          id: [0],
          type: [''],
          title: [''],
          description: [''],
          link: [''],
        });
      case 'WEEK_BEFORE' || 'JUST_BEFORE' || 'DURING_AND_AFTER':
        return this.formBuilder.group({
          description: [''],
          id: [0],
          inserted: [''],
          subtitle: [''],
          title: [''],
          type: [type],
          updated: [''],
        });
      case 'contacts':
        return this.formBuilder.group({
          groupName: [''],
          name: [''],
          phone: [''],
          id: [0],
          inserted: [''],
          updated: [''],
        });
      case GroupTypes.GALLERY:
        return this.formBuilder.group({
          inserted: [''],
          updated: [''],
          id: [0],
          orderBy: [''],
          type: [''],
          image: [''],
        });
      case 'products':
        return this.formBuilder.group({
          description: [''],
          id: [0],
          image: [''],
          inserted: [''],
          name: [''],
          type: [''],
          updated: [''],
        });
      default: {
        throw new Error('Object is missing!');
      }
    }
  }
}
