<div
  class="app-login-body"
  [ngStyle]="{ backgroundImage: backgroungImagePath }"
>
  <div class="app-login-wrapper">
    <div class="app-login">
      <div class="app-login-header">{{ "CORE.LOGIN.TITLE" | translate }}</div>
      <div class="app-login-inputs">
        <div>
          <form [formGroup]="formGroup">
            <div class="app-login-element">
              <mat-form-field>
                <mat-label>{{ "CORE.LOGIN.USERNAME" | translate }}</mat-label>
                <input
                  [formGroup]="formGroup"
                  formControlName="username"
                  matInput
                />
              </mat-form-field>
            </div>

            <div class="app-login-element">
              <mat-form-field [formGroup]="formGroup">
                <mat-label>{{ "CORE.LOGIN.PASSWORD" | translate }}</mat-label>
                <input
                  [formGroup]="formGroup"
                  formControlName="password"
                  type="password"
                  matInput
                />
              </mat-form-field>
            </div>
            <div *ngIf="error" class="app-login-error">
              <div>
                {{ "CORE.LOGIN.ERROR" | translate }}
              </div>
            </div>
          </form>

          <div class="app-login-element">
            <app-button
              class="app-button"
              [title]="'CORE.LOGIN.BUTTON.TITLE' | translate | uppercase"
              (click)="login()"
            ></app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
