import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IImage } from '../../model/image.model';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ImagesService {
  private readonly basePath = environment.apiUrl;
  constructor(private httpClient: HttpClient) {}

  getImages(
    eventId: number,
    imageType: string,
    activeLanguageCode: string
  ): Observable<IImage[]> {
    return this.httpClient.get<IImage[]>(
      `${this.basePath}/images/related/${eventId}/${activeLanguageCode}`,
      {
        params: {
          imgType: imageType,
        },
      }
    );
  }

  uploadImages(
    eventId: number,
    programId: number,
    imageType: string,
    images: File[],
    activeLanguageCode: string
  ): Observable<void> {
    if (!images.length) {
      return of(null);
    }

    const formData = new FormData();
    images.forEach((image) => {
      formData.append('files', image, image.name.toLowerCase());
    });

    return this.httpClient.post<void>(
      `${this.basePath}/event/${eventId}/related/${programId}/${activeLanguageCode}/uploadImages`,
      formData,
      {
        params: {
          imgType: imageType,
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  }

  uploadRelatedImages(
    eventId: number,
    raceId: number,
    galleryFiles: File[],
    type: string,
    deletedImagesId: number[],
    activeLanguageCode: string
  ): Observable<any> {
    return this.deleteImages(eventId, deletedImagesId).pipe(
      mergeMap(() => {
        return this.uploadImages(
          eventId,
          raceId,
          type,
          galleryFiles,
          activeLanguageCode
        );
      })
    );
  }

  // uploadRelatedImages(
  //   eventId: number,
  //   raceId: number,
  //   galleryFiles: File[],
  //   type: string,
  //   deletedImagesId: number[],
  //   activeLanguageCode: string
  // ): Observable<any> {
  //   return forkJoin([
  //     this.uploadImages(
  //       eventId,
  //       raceId,
  //       type,
  //       galleryFiles,
  //       activeLanguageCode
  //     ),
  //     this.deleteImages(eventId, deletedImagesId),
  //   ]);
  // }

  deleteImages(eventId: number, ids: number[]): Observable<void> {
    if (ids.length === 0) {
      return of(0 as unknown as void);
    }
    return this.httpClient.delete<void>(
      `${this.basePath}/event/${eventId}/deleteImages`,
      {
        params: {
          id: ids.toString(),
        },
      }
    );
  }

  getImageUrl(imageUrl: string): string {
    if (!imageUrl) {
      return null;
    }
    return `${this.basePath}/storage${imageUrl}`;
  }
}
