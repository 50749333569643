<app-list-top-card [title]="'PROGRAM.TITLE' | translate">
  <div class="mr2" [formGroup]="formGroup">
    <mat-form-field [class.mat-form-field-responsive]="isSmall">
      <mat-label>{{ "PROGRAM.EVENT.DATE" | translate }}</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="programDate" />

      <mat-datepicker-toggle matSuffix [for]="picker">
        <mat-icon matDatepickerToggleIcon>date_range</mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>

  <app-search-box
    class="mr2"
    [label]="'PROGRAM.FILTERS.SEARCH' | translate"
    [value]="searchValue"
    (search)="onSearch($event)"
  ></app-search-box>

  <app-button
    [title]="'PROGRAM.ADD' | translate | uppercase"
    [icon]="'add'"
    (click)="createProgram(1)"
  ></app-button>

  <app-button
    *ngIf="hasPermission()"
    [title]="'PROGRAM.RACE.ADD' | translate | uppercase"
    [icon]="'add'"
    class="app-element-margin"
    (click)="createProgram(2)"
  ></app-button>
</app-list-top-card>

<app-program-list-table
  *ngIf="programList"
  [program]="programList"
  [type]="'program'"
  [types]="types"
  [scheduleType]="scheduleType"
  (delete)="onDeleteProgramClick($event)"
  (programItemClick)="onProgramItemClick($event)"
></app-program-list-table>

<app-spinner *ngIf="!programList"></app-spinner>
