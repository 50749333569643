import { Injectable } from '@angular/core';
import { Observable, of, EMPTY, forkJoin } from 'rxjs';
import {
  IProgram,
  IProgramEventPost,
  IProgramRacePost,
  IProgramRaceEvent,
  IProgramEventPostTypeId,
  IProgramRacePostTypeId,
  IImageGallery,
  IRaceCode,
} from '../../model/program.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, mergeMap } from 'rxjs/operators';
import {
  dateFormat,
  timeFormat,
} from 'src/app/shared/utils/dateFormatter.util';
import { ImagesService } from 'src/app/api/service/images/images.service';

@Injectable({
  providedIn: 'root',
})
export class ProgramService {
  private readonly basePath = environment.apiUrl;
  private readonly PROGRAM_TYPES = {
    1: 'eventSchedule',
    2: 'raceSchedule',
  };

  constructor(
    private httpClient: HttpClient,
    private imagesService: ImagesService
  ) {}

  getProgramDetail(
    eventId: number,
    programId: number,
    type: string,
    typeShedule: string,
    activeLanguageCode: string
  ): Observable<IProgramRacePost | IProgramEventPost> {
    return this.listProgram(eventId, typeShedule, activeLanguageCode).pipe(
      map((program) => {
        return program[type].filter((value) => value?.id === programId);
      })
    );
  }

  getRaceCodes(): Observable<IRaceCode[]> {
    return this.httpClient.get<IRaceCode[]>(`${this.basePath}/raceCodes`);
  }

  transformedProgramList(
    eventId: number,
    typeShedule: string,
    activeLanguageCode: string
  ): Observable<IProgramRaceEvent[]> {
    return this.listProgram(eventId, typeShedule, activeLanguageCode).pipe(
      map((program) => {
        const response: IProgramRaceEvent[] = [];
        if (program?.raceSchedule) {
          program.raceSchedule.forEach((race) => {
            response.push(
              this.transformeEventRaceProgram(2, race, activeLanguageCode)
            );
          });
        }
        if (program?.eventSchedule) {
          program.eventSchedule.forEach((event) => {
            response.push(
              this.transformeEventRaceProgram(1, event, activeLanguageCode)
            );
          });
        }
        return response;
      })
    );
  }

  listProgram(
    eventId: number,
    typeShedule: string,
    activeLanguageCode: string
  ): Observable<IProgram> {
    return this.httpClient.get<IProgram>(
      `${this.basePath}/event/${eventId}/schedule`,
      {
        params: {
          type: typeShedule,
        },
      }
    );
  }

  transformeEventRaceProgram(
    type: number,
    value: IProgramRacePost | IProgramEventPost,
    activeLanguageCode: string
  ): IProgramRaceEvent {
    return {
      id: value.id,
      date: dateFormat(value.dateTimeFrom),
      time: timeFormat(new Date(value.dateTimeFrom)),
      title: value.title[activeLanguageCode],
      type,
    };
  }

  createOrUpdateProgram(
    eventId: number,
    body: IProgramEventPost
  ): Observable<void> {
    return this.httpClient.post<void>(
      `${this.basePath}/event/${eventId}/schedule/event`,
      body
    );
  }

  updateProgramEvent(
    eventId: number,
    body: IProgramEventPost,
    image: File,
    type: string,
    programType: number,
    activeLanguageCode: string
  ): Observable<IProgramEventPost> {
    return this.createOrUpdateProgramEvent(
      eventId,
      body,
      image,
      type,
      programType,
      activeLanguageCode
    );
  }

  createOrUpdateProgramEvent(
    eventId: number,
    body: IProgramEventPost,
    image: File,
    type: string,
    programType: number,
    activeLanguageCode: string
  ): Observable<IProgramEventPost> {
    body.sponsorImg.cs = '';
    body.sponsorImg.en = '';
    return this.httpClient
      .post<IProgramEventPost>(
        `${this.basePath}/event/${eventId}/schedule/event`,
        body
      )
      .pipe(
        mergeMap((event) => {
          if (image) {
            return this.uploadImage(
              event.id,
              image,
              type,
              programType,
              activeLanguageCode
            );
          } else {
            return this.deleteEventSponsorImage(
              event.id,
              activeLanguageCode
            ).pipe(
              mergeMap(() => {
                return of(event as unknown as any);
              })
            );
          }
        })
      );
  }

  createOrUpdateProgramRace(
    eventId: number,
    body: IProgramRacePost
  ): Observable<IProgramRacePost> {
    body.sponsorImg.cs = '';
    body.sponsorImg.en = '';
    body.heightProfileImg.cs = '';
    body.heightProfileImg.en = '';
    body.mapImg.cs = '';
    body.mapImg.en = '';
    return this.httpClient.post<IProgramRacePost>(
      `${this.basePath}/event/${eventId}/schedule/race`,
      body
    );
  }

  deleteEventSponsorImage(
    sheduleId: number,
    activeLanguageCode: string
  ): Observable<any> {
    return this.httpClient.delete<any>(
      `${this.basePath}/eventSchedule/${sheduleId}/${activeLanguageCode}/sponsor`
    );
  }

  deleteRaceSponsorImage(
    sheduleId: number,
    activeLanguageCode: string
  ): Observable<any> {
    return this.httpClient.delete<any>(
      `${this.basePath}/raceSchedule/${sheduleId}/${activeLanguageCode}/sponsor`
    );
  }

  uploadImage(
    programId: number,
    image: File,
    type: string,
    programType: number,
    activeLanguageCode: string
  ): Observable<any> {
    const formData = new FormData();
    formData.append('file', image, image?.name.toLowerCase());

    return this.httpClient.post<any>(
      `${this.basePath}/${this.PROGRAM_TYPES[programType]}/${programId}/${activeLanguageCode}/${type}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  }

  deleteRaceImage(
    programId: number,
    type: string,
    programType: number,
    activeLanguageCode: string
  ): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.basePath}/${this.PROGRAM_TYPES[programType]}/${programId}/${activeLanguageCode}/${type}`
    );
  }

  getImageUrl(imageUrl: string): string {
    if (!imageUrl) {
      return null;
    }
    return `${this.basePath}/storage${imageUrl}`;
  }

  deleteProgram(eventIds: number[], type: string): Observable<void> {
    const path = parseInt(type, 10) === 1 ? 'eventSchedule' : 'raceSchedule';
    return this.httpClient.delete<void>(`${this.basePath}/${path}`, {
      params: {
        ids: eventIds.map((id) => id.toString()),
      },
    });
  }
}
