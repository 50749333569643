import { Pipe, PipeTransform } from '@angular/core';
import { IBasicTable } from '../model/basic-table.model';
import { filterDataBySearchInput } from '../utils/filter.util';

@Pipe({
  name: 'searchBasicTableElement',
})
export class SearchBasicTableElementPipe implements PipeTransform {
  transform(elements: IBasicTable[], searchValue: string): unknown {
    return filterDataBySearchInput(elements, 'title', searchValue);
  }
}
