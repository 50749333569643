<div *ngIf="data">
  <app-list-top-card [title]="'PROGRAM.TITLE' | translate">
    <div class="mr2" [formGroup]="formGroup">
      <mat-form-field [class.mat-form-field-responsive]="isSmall">
        <mat-label>{{ "PROGRAM.EVENT.DATE" | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          formControlName="programDate"
        />
        <mat-datepicker-toggle matSuffix [for]="picker">
          <mat-icon matDatepickerToggleIcon>date_range</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>

    <app-search-box
      [label]="'PROGRAM.FILTERS.SEARCH' | translate"
      [value]="searchValue"
      (search)="onSearch($event)"
    ></app-search-box>

    <app-button
      [title]="'PROGRAM.ADD' | translate | uppercase"
      [icon]="'add'"
      class="app-element-margin"
      (click)="createProgram(1)"
    ></app-button>

    <app-button
      *ngIf="hasPermission()"
      [title]="'PROGRAM.RACE.ADD' | translate | uppercase"
      [icon]="'add'"
      class="app-element-margin"
      (click)="createProgram(2)"
    ></app-button>
  </app-list-top-card>

  <app-dynamic-table
    (deleteItem)="deleteProgram($event)"
    [data]="data"
    [type]="'program'"
    [types]="types"
    [scheduleType]="scheduleType"
    [columns]="columns"
  ></app-dynamic-table>
</div>
<app-spinner *ngIf="!data"></app-spinner>
