import { Component, OnInit, HostListener } from '@angular/core';
import { IType } from 'src/app/news/model/type.model';
import { filterData } from 'src/app/shared/utils/filter.util';
import { ProgramService } from '../../services/program.service';
import { IProgram, IProgramRaceEvent } from '../../models/program.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { IPermisson } from 'src/app/api/model/permisson.model';
import { PermissonService } from 'src/app/api/service/permission/permisson.service';
import { LanguageService } from 'src/app/shared/services/language/language.service';
import { EventService } from 'src/app/api/services/event/event.service';
import { Observable } from 'rxjs';
import { IEventResult } from 'src/app/api/model/events.model';
import { filter, mergeMap, tap } from 'rxjs/operators';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { PROGRAM_TYPES } from '../../constants/program.constants';
import { SortingEventResultsDialogComponent } from '../../components/sorting-event-result-dialog/sorting-event-results-dialog.component';
import { ConfirmDialogComponent } from 'src/app/shared/component/dialogs/confirm-dialog/confirm-dialog.component';
import {
  PROGRESS_DIALOG_ACTION_DELETING,
  PROGRESS_DIALOG_ACTION_SAVING,
} from 'src/app/shared/constants/dialog.constants';
import { SortingListService } from '../../services/sorting-list.service';
import { ApiCallExtensionService } from 'src/app/shared/services/api-call-extension/api-call-extension.service';
import { UserType } from 'src/app/core/model/user-type.model';

@Component({
  templateUrl: './program-list.page.html',
  styleUrls: ['./program-list.page.scss'],
})
export class ProgramListPage implements OnInit {
  selectedValue: string;
  pageWidth: number;
  isSmall = false;
  types: IType[] = PROGRAM_TYPES;
  program: IProgram;
  programList: IProgramRaceEvent[];
  formGroup: FormGroup;
  searchValue = '';
  scheduleType = UserType.USER;
  readonly columns = ['title', 'time', 'date', 'type', 'edit', 'delete'];
  private readonly EVENT_ID = parseInt(localStorage.getItem('eventId'), 10);

  sortingListYearControl = new FormControl(new Date().getFullYear());

  constructor(
    private programService: ProgramService,
    private router: Router,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private permissonService: PermissonService,
    private languageService: LanguageService,
    private eventService: EventService,
    private dialogService: DialogService,
    private translate: TranslateService,
    private sortingListService: SortingListService,
    private apiCallExtensionService: ApiCallExtensionService
  ) {
    this.formGroup = this.formBuilder.group({
      programDate: [''],
    });

    this.formGroup.get('programDate').valueChanges.subscribe((date) => {
      this.searchProgram();
    });

    this.sortingListYearControl.valueChanges
      .pipe(mergeMap(() => this.getEventResults()))
      .subscribe({
        next: (results) => {
          this.sortingListService.onChangeEventResult(results);
        },
      });
  }

  onSearch(searchInput: string): void {
    this.searchValue = searchInput;
    this.searchProgram();
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.isSmall = this.breakpointObserver.isMatched('(max-width: 1400px)');
  }

  openSortintListDialog(
    results: IEventResult[]
  ): MatDialogRef<SortingEventResultsDialogComponent> {
    return this.dialog.open(SortingEventResultsDialogComponent, {
      minWidth: '40%',
      data: {
        showClose: true,
        results,
        cancelText: this.translate.instant(marker('CORE.BUTTON.CANCEL')),
        confirmText: this.translate.instant(marker('CORE.BUTTON.SAVE')),
        confirmDisabled: !results.length,
        sortingListYearControl: this.sortingListYearControl,
        title: this.translate.instant(marker('PROGRAM.SORTING.TITLE')),
      },
    });
  }

  getEventResults(): Observable<IEventResult[]> {
    return this.eventService.getEventResults(
      this.sortingListYearControl.value,
      this.eventService.getEventExtId()
    );
  }

  onSortingListClick(): void {
    this.getEventResults()
      .pipe(
        mergeMap((results: IEventResult[]) =>
          this.openSortintListDialog(results).afterClosed()
        ),
        filter((confirmed) => confirmed),
        mergeMap((sortedResults: IEventResult[]) =>
          this.apiCallExtensionService.extendApiCall(
            () =>
              this.eventService.reorderEventResults(
                this.changeEventResultsOrder(sortedResults)
              ),
            this.translate.instant(PROGRESS_DIALOG_ACTION_SAVING)
          )
        )
      )
      .subscribe();
  }

  onProgramItemClick(program: IProgramRaceEvent): void {
    const navigationExtras = {
      queryParams: {
        type: program.type ?? null,
        scheduleType: this.scheduleType ? this.scheduleType : null,
      },
    };

    this.router.navigate(['program', 'detail', program.id], navigationExtras);
  }

  private changeEventResultsOrder(
    sortedResults: IEventResult[]
  ): IEventResult[] {
    return sortedResults.map(
      (reorderedEventResult: IEventResult, index: number) => {
        return {
          ...reorderedEventResult,
          race: {
            ...reorderedEventResult.race,
            raceOrder: index,
          },
        };
      }
    );
  }

  onDeleteProgramClick(program: IProgramRaceEvent): void {
    const dialogRef = this.openDeleteProgramDialog(program.title);

    dialogRef
      .afterClosed()
      .pipe(
        filter((confirmed) => confirmed),
        mergeMap(() =>
          this.apiCallExtensionService.extendApiCall(
            () =>
              this.programService.deleteProgram(
                [program.id],
                program.type.toString()
              ),
            this.translate.instant(PROGRESS_DIALOG_ACTION_DELETING)
          )
        )
      )
      .subscribe({
        next: () => {
          this.initProgram();
        },
      });
  }

  openDeleteProgramDialog(
    programTitle: string
  ): MatDialogRef<ConfirmDialogComponent> {
    return this.dialogService.openConfirmDialog({
      danger: true,
      mainText: this.translate.instant(marker('CORE.ALERT.DELETE_ITEM.TITLE')),
      subText: this.translate.instant(
        marker('CORE.ALERT.DELETE_ITEM.SUBTITLE'),
        {
          itemTitle: programTitle,
        }
      ),
      cancelText: this.translate.instant(marker('CORE.BUTTON.BACK')),
      confirmText: this.translate.instant(marker('CORE.DELETE')),
      showClose: true,
      imgPath: 'assets/image/delete.svg',
    });
  }

  searchProgram(): void {
    const searchInput = this.searchValue;
    const activeLanguageCode = this.languageService.getActiveLanguage();
    const date = this.formGroup.get('programDate').value;
    if (searchInput || date) {
      this.programService
        .transformedProgramList(
          this.EVENT_ID,
          this.scheduleType,
          activeLanguageCode
        )
        .subscribe({
          next: (programList) => {
            this.programList = filterData(
              programList,
              'title',
              searchInput,
              date
            );
          },
        });
    } else {
      this.initProgram();
    }
  }

  createProgram(typeId: number): void {
    const navigationExtras = {
      queryParams: {
        type: typeId,
        scheduleType: this.scheduleType,
      },
    };

    this.router.navigate(['program', 'detail', 0], navigationExtras);
  }

  hasPermission(): boolean {
    const permision: IPermisson = {
      part: 'program',
      type: 2,
      action: 'create',
    };
    return this.permissonService.hasPermission(permision);
  }

  ngOnInit(): void {
    this.initProgram();
    this.isSmall = this.breakpointObserver.isMatched('(max-width: 1400px)');
  }

  initProgram(): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    //  this.scheduleType = this.activatedRoute.snapshot.paramMap.get('type');
    this.programService
      .transformedProgramList(
        this.EVENT_ID,
        this.scheduleType,
        activeLanguageCode
      )
      .subscribe((program) => {
        this.programList = program;
      });
  }
}
