import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  getData(key: string): unknown | null {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (error) {
      console.warn('Could not parse stored items - returning null');
      return null;
    }
  }

  saveData(key: string, data: unknown): void {
    if (typeof data === 'undefined') {
      console.warn(
        'Trying to store undefined to JSON storage - stored null instead'
      );
      localStorage.setItem(key, null);
    } else {
      localStorage.setItem(key, JSON.stringify(data));
    }
  }

  clearData(key: string): void {
    localStorage.removeItem(key);
  }

  clearAllData(): void {
    localStorage.clear();
  }
}
