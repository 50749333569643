<app-dialog-layout class="app-progress-dialog" [showActions]="false">
  <ng-container content>
    <div class="px2 py2">
      <div
        class="flex justify-center"
        [class.mb3]="data.mainText || data.subText"
      >
        <app-spinner-content
          [inDialogComponent]="data.inDialogComponent"
        ></app-spinner-content>
      </div>

      <div
        *ngIf="data.mainText"
        class="text-medium flex justify-center"
        [class.mb2]="data.subText"
      >
        <div>{{ data.mainText }}</div>
      </div>

      <div
        *ngIf="data.subText"
        class="app-progress-dialog-sub-text text-normal flex justify-center mb2"
      >
        <div>{{ data.subText }}</div>
      </div>
    </div>
  </ng-container>
</app-dialog-layout>
