import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ProgramService } from '../../services/program/program.service';
import { IProgramEventPost } from '../../model/program.model';
import { ActivatedRoute, Router } from '@angular/router';
import {
  setDateWithTime,
  timeFormat,
} from 'src/app/shared/utils/dateFormatter.util';
import { ImagesService } from 'src/app/api/service/images/images.service';
import { ImageType } from 'src/app/api/model/image.model';
import { LanguageService } from 'src/app/shared/services/language/language.service';
import { TIME_REGEX } from 'src/app/shared/constants/time.constants';

@Component({
  selector: 'app-program-detail-event',
  templateUrl: './program-detail-event.component.html',
  styleUrls: ['./program-detail-event.component.scss'],
})
export class ProgramDetailEventComponent implements OnInit {
  private readonly EVENT_ID = parseInt(localStorage.getItem('eventId'), 10);
  private programId: string;
  @Input() event: IProgramEventPost;
  deletedImagesId: number[] = [];
  formGroup: FormGroup;
  mapUrl: string;
  sponzorUrl: string;
  time: string;
  loading = false;
  private scheduleType: string;

  constructor(
    private formBuilder: FormBuilder,
    private programService: ProgramService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private imagesService: ImagesService,
    private languageService: LanguageService
  ) {
    this.formGroup = this.formBuilder.group({
      time: this.formBuilder.group({
        timeFrom: ['', [Validators.pattern(TIME_REGEX)]],
        timeTo: ['', [Validators.pattern(TIME_REGEX)]],
      }),
      gallery: this.formBuilder.array([]),
      event: this.formBuilder.group({
        about: [''],
        active: [''],
        dateTimeFrom: [''],
        dateTimeTo: [''],
        eventId: [''],
        fbLink: [''],
        id: [''],
        inserted: [''],
        place: [''],
        sponsorImg: [''],
        title: [''],
        updated: [''],
        gpsLat: [''],
        gpsLon: [''],
        type: [''],
      }),
    });
  }

  ngOnInit(): void {
    this.initEvent();
    this.programId = this.activatedRoute.snapshot.paramMap.get('programId');
    this.scheduleType =
      this.activatedRoute.snapshot.queryParamMap.get('scheduleType');
    this.initImageGallery();
  }

  initEvent(): void {
    if (this.event) {
      const eventTimeFrom = timeFormat(new Date(this.event.dateTimeFrom));
      const eventTimeTo = timeFormat(new Date(this.event.dateTimeTo));
      this.formGroup.get('time').get('timeFrom').setValue(eventTimeFrom);
      this.formGroup.get('time').get('timeTo').setValue(eventTimeTo);
      this.initEventFormGroup();
    }
  }

  initEventFormGroup(): void {
    const languageCode = this.languageService.getActiveLanguage();
    if (this.event) {
      const sponsorImg =
        this.event.sponsorImg[languageCode] !== 'null'
          ? this.event.sponsorImg[languageCode]
          : '';

      const eventFormGroup = this.formGroup.get('event');

      eventFormGroup.get('about').setValue(this.event.about[languageCode]);
      eventFormGroup.get('active').setValue(this.event.active);
      eventFormGroup.get('dateTimeFrom').setValue(this.event.dateTimeFrom);
      eventFormGroup.get('dateTimeTo').setValue(this.event.dateTimeTo);
      eventFormGroup.get('eventId').setValue(this.event.eventId);
      eventFormGroup.get('fbLink').setValue(this.event.fbLink[languageCode]);
      eventFormGroup.get('id').setValue(this.event.id);
      eventFormGroup.get('place').setValue(this.event.place[languageCode]);
      eventFormGroup.get('sponsorImg').setValue(sponsorImg);
      eventFormGroup.get('title').setValue(this.event.title[languageCode]);
      eventFormGroup.get('updated').setValue(this.event.updated);
      eventFormGroup.get('gpsLat').setValue(this.event.gpsLat);
      eventFormGroup.get('gpsLon').setValue(this.event.gpsLon);
      eventFormGroup.get('type').setValue(this.event.type);
    }
  }

  navigate(): void {
    this.router.navigate(['program', 'list', this.scheduleType]);
  }

  deleteImage(type: string): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    this.programService
      .deleteRaceImage(
        parseInt(this.programId, 10),
        type,
        1,
        activeLanguageCode
      )
      .subscribe();
  }

  initImageGallery(): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    this.loading = true;
    this.imagesService
      .getImages(
        parseInt(this.programId, 10),
        ImageType.ScheduleEvent,
        activeLanguageCode
      )
      .subscribe((images) => {
        images.forEach(() => {
          this.addFormGroup('gallery');
        });
        this.formGroup.get('gallery').setValue(images);
        this.loading = false;
      });
  }

  addFormGroup(type: string): void {
    (this.formGroup.get(type) as FormArray).push(this.createGroup(type));
  }

  createGroup(type: string): FormGroup {
    switch (type) {
      case 'gallery':
        return this.formBuilder.group({
          inserted: [''],
          updated: [''],
          id: [''],
          lang: [''],
          orderBy: [''],
          type: [''],
          image: [''],
        });
    }
  }

  changeLoadingState(): void {
    this.loading = !this.loading;
  }

  getFormArray(type: string): FormArray {
    return this.formGroup.get(type) as FormArray;
  }

  deleteFormGroup(type: string, index: number): void {
    (this.formGroup.get(type) as FormArray).removeAt(index);
  }

  deleteIndex(index: number, type: string): void {
    const result = this.formGroup.get(type).value;
    const filteredResult = result.filter((info) => info !== result[index]);
    this.addDeleteIdByType(result[index]?.id, type);
    this.deleteFormGroup(type, index);
    this.formGroup.get(type).setValue(filteredResult);
  }

  addDeleteIdByType(id: number, type: string): void {
    switch (type) {
      case 'gallery':
        this.deletedImagesId.push(id);
        break;
    }
  }

  transformEventDetailObj(): void {
    const eventFormGroup = this.formGroup.get('event');
    const activeLanguageCode = this.languageService.getActiveLanguage();

    this.event.about[activeLanguageCode] = eventFormGroup.get('about').value;
    this.event.active = eventFormGroup.get('active').value;
    this.event.dateTimeFrom = eventFormGroup.get('dateTimeFrom').value;
    this.event.dateTimeTo = eventFormGroup.get('dateTimeTo').value;
    this.event.eventId = eventFormGroup.get('eventId').value;
    this.event.fbLink[activeLanguageCode] = eventFormGroup.get('fbLink').value;
    this.event.gpsLat = eventFormGroup.get('gpsLat').value;
    this.event.gpsLon = eventFormGroup.get('gpsLon').value;
    this.event.id = eventFormGroup.get('id').value;
    this.event.inserted = eventFormGroup.get('inserted').value;
    this.event.place[activeLanguageCode] = eventFormGroup.get('place').value;
    this.event.sponsorImg[activeLanguageCode] =
      eventFormGroup.get('sponsorImg').value;
    this.event.title[activeLanguageCode] = eventFormGroup.get('title').value;
    this.event.type = eventFormGroup.get('type').value;
    this.event.updated = eventFormGroup.get('updated').value;
  }

  save(): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();

    if (this.validate()) {
      return;
    }

    const imageFile = this.formGroup.get('event').get('sponsorImg').value;
    this.formatDateTime('dateTimeFrom', 'timeFrom');
    this.formatDateTime('dateTimeTo', 'timeTo');
    this.transformEventDetailObj();
    const file = imageFile instanceof File ? imageFile : null;
    const galleryFiles: File[] = [];
    this.formGroup.get('gallery').value.forEach((value) => {
      if (value.image instanceof File) {
        galleryFiles.push(value.image);
      }
    });
    this.changeLoadingState();
    if (this.EVENT_ID !== 0) {
      this.imagesService
        .deleteImages(this.EVENT_ID, this.deletedImagesId)
        .subscribe();
    }
    this.programService
      .createOrUpdateProgramEvent(
        this.EVENT_ID,
        this.event,
        file,
        'uploadSponsor',
        1,
        activeLanguageCode
      )
      .subscribe((program) => {
        this.saveImage(program.id, 'sponsorImg', 'uploadSponsor');
        this.imagesService
          .uploadImages(
            this.EVENT_ID,
            program.id,
            ImageType.ScheduleEvent,
            galleryFiles,
            activeLanguageCode
          )
          .subscribe(() => {
            this.changeLoadingState();
            this.navigate();
          });
      });
  }

  saveImage(programId: number, value: string, type: string): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    const prop = this.formGroup.get('event').get(value).value;
    if (prop instanceof File) {
      this.programService
        .uploadImage(programId, prop, type, 1, activeLanguageCode)
        .subscribe();
    }
  }

  formatDateTime(dateType: string, timeType: string): void {
    const DATE = this.formGroup.get('event').get(dateType).value;
    const TIME = this.formGroup.get('time').get(timeType).value;
    const formatedDate = setDateWithTime(DATE, TIME);
    this.formGroup.get('event').get(dateType).setValue(formatedDate);
  }

  validate(): boolean {
    if (this.formGroup.get('time').get('timeFrom').invalid) {
      this.formGroup.get('time').setErrors({ time: true });
      return true;
    }
    if (this.formGroup.get('time').get('timeTo').invalid) {
      this.formGroup.get('time').setErrors({ time: true });
      return true;
    }
    return false;
  }
}
