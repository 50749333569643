<div class="mb4">
  <div class="flex justify-between">
    <div class="mb2 app-other-pages-title">
      {{ "PROGRAM.OTHER-TRANSPORT.ENERVIT.PRODUCTS.TITLE" | translate }}
      {{ index + 1 }}
    </div>
    <div>
      <app-icon-title
        (click)="getIndex.emit(index)"
        icon="delete"
      ></app-icon-title>
    </div>
  </div>

  <div class="mt2">
    <div class="mb3" [formGroup]="formGroup">
      <mat-form-field class="app-form-field-full-width">
        <mat-label>{{
          "OTHER-PAGES.ENERVIT.PRODUCTS.TYPE" | translate
        }}</mat-label>
        <input formControlName="type" matInput />
      </mat-form-field>
    </div>
  </div>

  <div class="mt2">
    <div class="mb3" [formGroup]="formGroup">
      <mat-form-field class="app-form-field-full-width">
        <mat-label>{{
          "OTHER-PAGES.ENERVIT.PRODUCTS.NAME" | translate
        }}</mat-label>
        <input formControlName="name" matInput />
      </mat-form-field>
    </div>
  </div>

  <div class="mt2">
    <div class="mb3" [formGroup]="formGroup">
      <mat-form-field class="app-form-field-full-width">
        <mat-label>{{
          "OTHER-PAGES.ENERVIT.PRODUCTS.DESCRIPTION" | translate
        }}</mat-label>
        <input formControlName="description" matInput />
      </mat-form-field>
    </div>
  </div>

  <div class="mt2">
    <div class="mb3" [formGroup]="formGroup">
      <mat-form-field class="app-form-field-full-width">
        <mat-label>{{
          "OTHER-PAGES.ENERVIT.PRODUCTS.LINK" | translate
        }}</mat-label>
        <input formControlName="link" matInput />
      </mat-form-field>
    </div>
  </div>

  <div class="mb4" [formGroup]="formGroup">
    <app-image-input
      [control]="formGroup.get('image')"
      [width]="110"
      [height]="180"
      [title]="'OTHER-PAGES.ENERVIT.PRODUCTS.IMAGE' | translate"
      (delete)="del.emit()"
    ></app-image-input>
  </div>
</div>
