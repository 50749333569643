import { Component, OnInit } from '@angular/core';
import { IEvent } from 'src/app/api/model/events.model';
import { EventService } from '../../../api/services/event/event.service';
import { EVENT_LOGO } from '../../constants/event-logo.constant';
import { EventCode } from '../../model/event.model';
import { IEventLogo } from '../../model/event.model';
import { ILink } from '../../model/link.model';
import { LanguageService } from 'src/app/shared/services/language/language.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PROJECT_VERSION } from '../../constants/project-version.constant';
import { LINKS } from '../../constants/link.constant';
import {
  SUPPORTED_LANGUAGES,
  SUPPORTED_LANGUAGES_CODES,
} from '../../../shared/constants/supported-languages.constant';
import { StorageService } from 'src/app/shared/services/storage/storage.service';
import {
  EVENT_EXT_ID_LS_KEY,
  EVENT_ID_LS_KEY,
  EVENT_SHORT_CODE_LS_KEY,
} from 'src/app/shared/constants/storage.constant';
import { ISupportedLanguage } from 'src/app/shared/model/language.model';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  events: IEvent[];
  links: ILink[] = LINKS;
  supportedLanguages: ISupportedLanguage[] = SUPPORTED_LANGUAGES;
  version = PROJECT_VERSION;

  get activeEvent(): IEvent {
    return this.events?.find(
      (eventElement) =>
        eventElement.id === this.storageService.getData(EVENT_ID_LS_KEY)
    );
  }

  get languageName(): string {
    return this.supportedLanguages.find(
      (supportedLanguage) =>
        supportedLanguage.languageCode === this.getActiveLanguage()
    ).languageName;
  }

  constructor(
    private eventService: EventService,
    private languageService: LanguageService,
    private router: Router,
    private translate: TranslateService,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.eventService.getSortedEventList().subscribe((events) => {
      this.events = events;
      this.setEventToLocalStorage(events);
    });
  }

  private setEventToLocalStorage(events: IEvent[]): void {
    const storedEventId = this.storageService.getData(
      EVENT_ID_LS_KEY
    ) as number;

    if (
      storedEventId &&
      events.length &&
      !this.isStoredEventIdIncludedInEvents(storedEventId, events)
    ) {
      this.storageService.saveData(EVENT_ID_LS_KEY, events[0].id);
      this.storageService.saveData(
        EVENT_SHORT_CODE_LS_KEY,
        events[0]?.eventShortCode.toString()
      );
      this.storageService.saveData(EVENT_EXT_ID_LS_KEY, events[0].extId);
    } else if (!storedEventId && events.length) {
      this.storageService.saveData(EVENT_ID_LS_KEY, events[0].id);
      this.storageService.saveData(
        EVENT_SHORT_CODE_LS_KEY,
        events[0]?.eventShortCode.toString()
      );
      this.storageService.saveData(EVENT_EXT_ID_LS_KEY, events[0].extId);
    }
  }

  private isStoredEventIdIncludedInEvents(
    storedEventId: number,
    events: IEvent[]
  ): boolean {
    return events.some((event) => event.id === storedEventId);
  }

  getActiveEventLogo(): string {
    const eventShortCode = this.storageService.getData(
      EVENT_SHORT_CODE_LS_KEY
    ) as string;

    return this.getEventLogoByCode(eventShortCode).path;
  }

  getEventLogo(eventCode: string): string {
    return this.getEventLogoByCode(eventCode)?.path;
  }

  getEventLogoByCode(eventCode: string): IEventLogo {
    return (
      EVENT_LOGO.find((event) => event.code === eventCode) ||
      EVENT_LOGO.find((event) => event.code === EventCode.Default)
    );
  }

  onEventChange(eventId: number, eventCode: string, eventExtId: number): void {
    this.storageService.saveData(EVENT_ID_LS_KEY, eventId);
    this.storageService.saveData(EVENT_SHORT_CODE_LS_KEY, eventCode);
    this.storageService.saveData(EVENT_EXT_ID_LS_KEY, eventExtId);
    location.reload();
  }

  getLanguageIcon(languageCode: string): string {
    return `flag-icon flag-icon-${SUPPORTED_LANGUAGES_CODES[languageCode]}`;
  }

  getActiveLanguage(): string {
    return this.languageService.getActiveLanguage();
  }

  getUserRole(): string {
    return localStorage.getItem('role') || 'admin';
  }

  onLanguageChange(languageCode: string): void {
    this.translate.use(languageCode);
    this.languageService.setActiveLanguage(languageCode);
    location.reload();
  }

  logout(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    this.router.navigateByUrl('/login');
  }
}
