import { Pipe, PipeTransform } from '@angular/core';
import { IBasicTable } from '../model/basic-table.model';
import { IBasicFormContentInfo } from '../model/basic-form-content-info.model';

@Pipe({
  name: 'extractBasicTableProps',
})
export class ExtractBasicTablePropsPipe implements PipeTransform {
  transform(
    list: IBasicFormContentInfo[],
    activeLanguageCode: string
  ): IBasicTable[] {
    return list.map((element) => {
      return {
        id: element.id,
        title: element.title[activeLanguageCode],
      };
    });
  }
}
