import { isI18nObject } from './i18n-objects.util';

export function updateI18nObject<T extends object>(
  i18nObject: T,
  reducedI18nObject: object,
  languageCode: string
): T {
  const updatedI18nObject: object = {};

  Object.keys(i18nObject).forEach((i18nObjectKey) => {
    if (!reducedI18nObject.hasOwnProperty(i18nObjectKey)) {
      throw new Error(
        `Reduced i18n object does not match the complete i18n object. Missing property "${i18nObjectKey}"`
      );
    }

    updatedI18nObject[i18nObjectKey] = updateI18nObjectValue(
      i18nObject[i18nObjectKey],
      reducedI18nObject[i18nObjectKey],
      languageCode
    );
  });

  return updatedI18nObject as T;
}

function updateI18nObjectValue(
  i18nObjectValue: unknown,
  reducedI18nObjectValue: unknown,
  languageCode: string
): unknown | object | object[] | string {
  if (
    typeof i18nObjectValue === 'object' &&
    i18nObjectValue !== null &&
    i18nObjectValue.constructor?.name === 'Object'
  ) {
    if (Array.isArray(i18nObjectValue)) {
      return updateI18nArray(
        i18nObjectValue,
        assertReducedI18nObjectValueIsArray(reducedI18nObjectValue),
        languageCode
      );
    }

    const isValueI18nObject = isI18nObject(i18nObjectValue);

    if (isValueI18nObject) {
      return {
        ...i18nObjectValue,
        [languageCode]: assertReducedI18nObjectValueIsStringOrFile(
          reducedI18nObjectValue
        ),
      };
    }

    return updateI18nObject(
      i18nObjectValue,
      assertReducedI18nObjectValueIsObject(reducedI18nObjectValue),
      languageCode
    );
  }

  return reducedI18nObjectValue;
}

export function updateI18nArray<T extends unknown[]>(
  i18nArray: unknown[],
  reducedI18nArray: unknown[],
  languageCode: string
): T {
  return i18nArray.map((i18nArrayValue, index) => {
    if (reducedI18nArray[index] === undefined) {
      throw new Error(
        `Reduced i18n array does not match the complete i18n array. Missing index "${index}"`
      );
    }

    return updateI18nObjectValue(
      i18nArrayValue,
      reducedI18nArray[index],
      languageCode
    );
  }) as T;
}

function assertReducedI18nObjectValueIsStringOrFile(
  reducedI18nObjectValue: unknown
): string | File {
  if (
    typeof reducedI18nObjectValue !== 'string' &&
    !(reducedI18nObjectValue instanceof File)
  ) {
    throw new Error(
      `The value ${reducedI18nObjectValue} of the reduced i18n object should be a string or file`
    );
  }
  return reducedI18nObjectValue as string | File;
}

function assertReducedI18nObjectValueIsObject(
  reducedI18nObjectValue: unknown
): object {
  if (
    typeof reducedI18nObjectValue !== 'object' ||
    reducedI18nObjectValue === null
  ) {
    throw new Error(
      `The value ${reducedI18nObjectValue} of the reduced i18n object should be an object`
    );
  }
  return reducedI18nObjectValue as object;
}

function assertReducedI18nObjectValueIsArray(
  reducedI18nObjectValue: unknown
): unknown[] {
  if (!Array.isArray(reducedI18nObjectValue)) {
    throw new Error(
      `The value ${reducedI18nObjectValue} of the reduced i18n object should be an array`
    );
  }
  return reducedI18nObjectValue;
}
