<div *ngIf="!loading" class="mb4">
  <app-back-panel
    [title]="'NOTIFICATION.DETAIL.BACK' | translate"
  ></app-back-panel>

  <app-list-top-card [title]="notification?.title"> </app-list-top-card>

  <app-detail-card *ngIf="!loading">
    <div class="mb3" [formGroup]="formGroup.get('notification')">
      <mat-form-field class="app-form-field-full-width">
        <mat-label>{{ "NOTIFICATION.TITLE" | translate }}</mat-label>

        <input formControlName="title" matInput />
      </mat-form-field>
    </div>

    <div class="mb3" [formGroup]="formGroup.get('notification')">
      <mat-form-field class="app-form-field-full-width">
        <mat-label>{{ "NOTIFICATION.MESSAGE" | translate }}</mat-label>

        <input formControlName="message" matInput />
      </mat-form-field>
    </div>

    <div class="mb3 flex fit justify-between">
      <div [formGroup]="formGroup.get('notification')">
        <mat-form-field>
          <mat-label>{{ "NOTIFICATION.RACE-CODE" | translate }}</mat-label>

          <mat-select formControlName="topic">
            <mat-option *ngFor="let topic of topics" [value]="topic.topic">
              {{ topic.title | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div [formGroup]="formGroup.get('notification')">
        <mat-form-field>
          <mat-label>{{ "NOTIFICATION.ROUTES" | translate }}</mat-label>
          <mat-select
            formControlName="screenPath"
            [disabled]="disabledRoutes.value"
          >
            <mat-option
              *ngFor="let route of notificationRoutes"
              [value]="route.path"
            >
              {{ route.name | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="notificationEvents.length" class="mb3">
      <mat-form-field>
        <mat-label>{{ "NOTIFICATION.PROGRAM" | translate }}</mat-label>

        <mat-select [formControl]="notificationEventControl">
          <mat-option
            *ngFor="let notificationEvent of notificationEvents"
            [value]="notificationEvent.topic"
          >
            {{ notificationEvent.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="mb3 flex fit justify-between">
      <div [formGroup]="formGroup.get('notification')">
        <mat-form-field>
          <mat-label>{{ "NOTIFICATION.DATE" | translate }}</mat-label>

          <input matInput [matDatepicker]="picker" formControlName="dateTime" />

          <mat-datepicker-toggle matSuffix [for]="picker">
            <mat-icon matDatepickerToggleIcon>date_range</mat-icon>
          </mat-datepicker-toggle>

          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <div [formGroup]="formGroup.get('time')">
        <mat-form-field>
          <mat-label>{{ "NOTIFICATION.TIME" | translate }}</mat-label>
          <input formControlName="time" matInput type="time" />
        </mat-form-field>

        <div class="pl1 mb1">
          <mat-error *ngIf="formGroup.get('time').hasError('time')">
            {{ "CORE.ERROR.TIME-FORMAT" | translate }}
          </mat-error>
        </div>
      </div>
    </div>
  </app-detail-card>

  <app-footer
    [disabled]="formGroup.invalid"
    (save)="onSaveClick()"
    (cancel)="onCancelClick()"
  ></app-footer>
</div>

<app-spinner *ngIf="loading"></app-spinner>
