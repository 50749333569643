import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  private readonly text = `
  Konec září je tady a vypadá to, že spolu s ním i konec léta. Na českých horách už napadlo pár centimetrů prvního sněhu a možná přišel čas, kdy kontrolujete svou zimní výbavu na hory. Je tady ale také poslední den, kdy můžete využít tu nejlepší možnou cenu startovného na nadcházející ročník ČEZ Jizerské 50. Od 1. října Vás bude startovné stát o nějakou tu korunu navíc.
  Na start hlavního padesátikilometrového závodu se již tradičně staví tisíce závodníků nejen z České republiky, ale i z dalších zemí celého světa. Příběh tohoto závodu se píše už od roku 1968, kdy se poprvé běžel jako oddílový závod TJ Lokomotiva Liberec a součást horolezecké přípravy některých členů oddílu.
  Právě v roce 1970 se Jizerské 50 zúčastnili kompletní výprava horolezecké Expedice Peru ´70.`;

  private readonly testData = [
    {
      id: 1,
      title:
        '54. ČEZ Jizerská 50 se pojede i s přísnými omezeními. Organizátoř …',
      type: 1,
      date: '2020-02-05',
      text: this.text,
    },
    {
      id: 2,
      title: 'Přispějte Jizerské o.p.s. na úpravu Jizerské Magistrály',
      type: 1,
      date: '2020-02-08',
      text: this.text,
    },
    {
      id: 3,
      title:
        'Poslední den, kdy si můžete pořídit startovné na 54. ČEZ Jizerskou …',
      type: 2,
      date: '2020-02-07',
      text: this.text,
    },
    {
      id: 4,
      title: 'Přispějte Jizerské o.p.s. na úpravu Jizerské Magistrály',
      type: 1,
      date: '2020-02-09',
      text: this.text,
    },
    {
      id: 5,
      title:
        'If this tool saves you or your company time, please consider making a donation to support my work and the continued maintainence and development',
      type: 2,
      date: '2020-02-09',
      text: this.text,
    },
    {
      id: 6,
      title: 'Přispějte Jizerské o.p.s. na úpravu Jizerské Magistrály',
      type: 2,
      date: '2020-02-09',
      text: this.text,
    },
    {
      id: 7,
      title:
        'If this tool saves you or your company time, please consider making a donation to support my work and the continued maintainence and development',
      type: 1,
      date: '2020-12-07',
      text: this.text,
    },
  ];

  constructor() {}

  newsList(): Observable<any[]> {
    return of(this.testData);
  }

  getNewDetail(newId: number): Observable<any> {
    return of(this.testData.find((data) => data.id === newId));
  }
}
