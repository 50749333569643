import { IEvent, IPatchEvent } from '../model/events.model';

export function getPostEvent(event: IEvent): IPatchEvent {
  return {
    banner1Link: event.banner1Link,
    banner2Link: event.banner2Link,
    baseMap: event.baseMap,
    dateFrom: event.dateFrom,
    dateTo: event.dateTo,
    eventShortCode: event.eventShortCode,
    extId: event.extId,
    id: event.id,
    name: event.name,
    organizerMap: event.organizerMap,
    startTimestamp: event.startTimestamp,
  };
}
