import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProgressDialogComponent } from '../../component/dialogs/progress-dialog/progress-dialog.component';
import {
  IConfirmDialogData,
  IProgressDialogData,
} from '../../model/dialog-data.model';
import { PROGRESS_DIALOG_ID } from '../../constants/dialog.constants';
import { ConfirmDialogComponent } from '../../component/dialogs/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(public dialog: MatDialog) {}

  openConfirmDialog(
    params: IConfirmDialogData
  ): MatDialogRef<ConfirmDialogComponent> {
    return this.dialog.open(ConfirmDialogComponent, {
      data: params,
      minWidth: '500px',
      width: '40%',
    });
  }

  openProgressDialog(
    params: IProgressDialogData
  ): MatDialogRef<ProgressDialogComponent> {
    return this.dialog.open(ProgressDialogComponent, {
      id: PROGRESS_DIALOG_ID,
      disableClose: true,
      data: params,
      backdropClass: ['app-progress-dialog-backdrop'],
      panelClass: ['app-progress-dialog'],
    });
  }

  closeProgressDialog(): void {
    this.dialog.getDialogById(PROGRESS_DIALOG_ID)?.close();
  }
}
