import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, mergeMap, tap } from 'rxjs/operators';
import { IWaxes } from '../../model/other-pages.model';

@Injectable({
  providedIn: 'root',
})
export class WaxesService {
  private readonly basePath = environment.apiUrl;

  constructor(private httpClient: HttpClient) {}

  getWaxes(idsWax: number[] = []): Observable<IWaxes[]> {
    return this.httpClient.get<IWaxes[]>(`${this.basePath}/waxes`, {
      params: {
        ids: idsWax.toString(),
      },
    });
  }
}
