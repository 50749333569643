<div class="table-container">
  <table *ngIf="data.length !== 0" mat-table [dataSource]="data">
    <!-- Enable column -->
    <ng-container matColumnDef="enabled">
      <th mat-header-cell *matHeaderCellDef>
        {{ "TABLE.COLUMNS.ENABLE" | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let element" class="app-dynamic-table">
        <mat-checkbox
          *ngIf="canCheck(element?.canCheck)"
          class="example-margin"
          [checked]="isDisplayed(element.key, element.id)"
          (click)="$event.stopPropagation()"
          (change)="onVisibilityChange($event.checked, element)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Title column -->
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>
        {{ getTitle(type) | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.title | truncate }}
      </td>
    </ng-container>

    <!-- Name column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>
        {{ getTitle(type) | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.name | truncate }}
      </td>
    </ng-container>

    <!-- Translation column -->
    <ng-container matColumnDef="translate">
      <th mat-header-cell *matHeaderCellDef>
        {{ getTitle(type) | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.title | translate | truncate }}
      </td>
    </ng-container>

    <!-- Time column -->
    <ng-container matColumnDef="time">
      <th mat-header-cell *matHeaderCellDef>
        {{ "TABLE.COLUMNS.TIME" | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.time }}</td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>
        {{ "TABLE.COLUMNS.TYPE" | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ typeName(element.type) | translate }}
      </td>
    </ng-container>

    <!-- Visibility column -->
    <ng-container matColumnDef="visibility">
      <th mat-header-cell *matHeaderCellDef>
        {{ "TABLE.COLUMNS.VISIBILITY" | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ typeName(element.visibility) | translate }}
      </td>
    </ng-container>

    <!-- Date Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>
        {{ "TABLE.COLUMNS.DATE" | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.date }}</td>
    </ng-container>

    <!-- DateTime Column -->
    <ng-container matColumnDef="dateTime">
      <th mat-header-cell *matHeaderCellDef>
        {{ "TABLE.COLUMNS.DATE" | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ convertToDateString(element.dateTime) }}
      </td>
    </ng-container>

    <!-- eventTitle column -->
    <ng-container matColumnDef="eventTitle">
      <th mat-header-cell *matHeaderCellDef>
        {{ "TABLE.COLUMNS.EVENT-TITLE" | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.eventTitle | translate | truncate }}
      </td>
    </ng-container>

    <!-- Edit Column -->
    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <div class="flex justify-end">
          <mat-icon>edit</mat-icon>
        </div>
      </td>
    </ng-container>

    <!-- Delete Column -->
    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell class="app-table-icons" *matCellDef="let element">
        <div class="flex justify-end">
          <button mat-icon-button *ngIf="hasPermission('delete', element)">
            <mat-icon
              (click)="onDeleteRow(element)"
              (click)="$event.stopPropagation()"
              >delete</mat-icon
            >
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: columns"
      (click)="navigate(row)"
    ></tr>
  </table>

  <app-empty-result *ngIf="data.length === 0"></app-empty-result>
</div>
