export enum EventCode {
  BedrichovskyNlm = 'BNLM',
  Zadovska30 = 'Z30',
  OrlickyMaraton = 'OM',
  Jizerska50 = 'J50',
  Jelyman = 'JM',
  Krusnohorska30 = 'K30',
  Default = 'default',
}

export interface IEventLogo {
  code: string;
  path: string;
}
