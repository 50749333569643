<div *ngIf="!loading">
  <app-back-panel [title]="'CORE.BACK' | translate"></app-back-panel>

  <app-list-top-card [title]="title"> </app-list-top-card>

  <div class="mb4">
    <app-basic-form-content
      [formGroup]="formGroup"
      [extended]="extended"
      [isExtraInfo]="isExtraInfo"
      (addGalleryImage)="addGalleryFormGroup.emit()"
      (deleteGalleryImage)="deleteImage.emit($event)"
    ></app-basic-form-content>
  </div>

  <app-footer
    class="mt4"
    (save)="saveClick.emit()"
    (cancel)="cancelClick.emit()"
  ></app-footer>
</div>

<app-spinner *ngIf="loading"></app-spinner>
