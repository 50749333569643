import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filterDataBySearchInput } from 'src/app/shared/utils/filter.util';
import { IWaxes } from '../../model/other-pages.model';
import { OtherPagesService } from '../../services/orther-pages/other-pages.service';
import { WaxesService } from '../../services/waxes/waxes.service';
import { IPreference } from 'src/app/api/model/preference.model';

@Component({
  selector: 'app-waxes',
  templateUrl: './waxes.component.html',
  styleUrls: ['./waxes.component.scss'],
})
export class WaxesComponent implements OnInit {
  data: IWaxes[];
  private readonly EVENT_ID = parseInt(localStorage.getItem('eventId'), 10);
  readonly columns = ['enabled', 'name', 'edit'];
  type: string;
  preferences: IPreference[] = [];
  searchValue = '';

  constructor(
    private waxesService: WaxesService,
    private otherPagesService: OtherPagesService,
    private activatedRoute: ActivatedRoute
  ) {}

  onSearch(searchInput: string): void {
    if (searchInput) {
      this.searchValue = searchInput;
      this.waxesService.getWaxes().subscribe((list) => {
        this.data = filterDataBySearchInput(list, 'name', searchInput);
      });
    } else {
      this.ngOnInit();
    }
  }

  changeVisibilityState(page): void {
    const WAX_KEY = this.type + '_' + page?.id;

    page?.state
      ? this.otherPagesService
          .createOrUpdatePreference(this.EVENT_ID, WAX_KEY, page.state)
          .subscribe()
      : this.otherPagesService
          .deletePreference(this.EVENT_ID, WAX_KEY)
          .subscribe();
  }

  ngOnInit(): void {
    this.type = this.activatedRoute.snapshot.queryParamMap.get('type');
    this.waxesService.getWaxes().subscribe((waxes) => {
      this.data = waxes;
    });
    this.otherPagesService
      .getPreference(this.EVENT_ID)
      .subscribe((preferences) => {
        this.preferences = preferences;
      });
  }
}
