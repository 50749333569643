import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { EventService } from 'src/app/api/services/event/event.service';
import { IComponentCanDeactivate } from 'src/app/core/model/can-deactive-guard.model';
import { PROGRESS_DIALOG_ACTION_SAVING } from 'src/app/shared/constants/dialog.constants';
import { EVENT_ID_LS_KEY } from 'src/app/shared/constants/storage.constant';
import { ApiCallExtensionService } from 'src/app/shared/services/api-call-extension/api-call-extension.service';
import { LanguageService } from 'src/app/shared/services/language/language.service';
import { StorageService } from 'src/app/shared/services/storage/storage.service';
import { OrganizersService } from '../../service/organizers/organizers.service';

@Component({
  templateUrl: './base-map.page.html',
  styleUrls: ['./base-map.page.scss'],
})
export class BaseMapPage implements OnInit, IComponentCanDeactivate {
  formGroup: FormGroup;
  loading = false;
  private readonly eventId = this.storageService.getData(
    EVENT_ID_LS_KEY
  ) as number;
  private subs = new Subscription();
  private isSaveButtonClicked = false;
  originalValue: string;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private organizersService: OrganizersService,
    private languageService: LanguageService,
    private eventService: EventService,
    private storageService: StorageService,
    private translate: TranslateService,
    private apiCallExtensionService: ApiCallExtensionService
  ) {
    this.formGroup = this.formBuilder.group({
      baseMap: [''],
    });
  }

  ngOnInit(): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    this.loading = true;

    this.subs.add(
      this.organizersService.getBaseMap(this.eventId).subscribe((response) => {
        const url =
          response.baseMap[activeLanguageCode] !== 'null'
            ? response.baseMap[activeLanguageCode]
            : '';

        this.formGroup.get('baseMap').setValue(url);
        this.loading = false;
        this.originalValue = url;
      })
    );
  }

  canDeactivate(): boolean {
    return (
      this.originalValue === this.formGroup.get('baseMap').value ||
      this.isSaveButtonClicked
    );
  }

  onCancelClick(): void {
    this.navigateToOrganizersList();
  }

  deleteImage(): Observable<void> {
    const activeLanguageCode = this.languageService.getActiveLanguage();

    return this.eventService.deleteOrganizerMap(
      this.eventId,
      activeLanguageCode
    );
  }

  navigateToOrganizersList(): void {
    this.router.navigate(['organizers', 'list']);
  }

  onSaveClick(): void {
    const obsever = this.getObserver();
    this.isSaveButtonClicked = true;

    if (obsever) {
      this.apiCallExtensionService
        .extendApiCall(
          () => obsever,
          this.translate.instant(PROGRESS_DIALOG_ACTION_SAVING)
        )
        .subscribe({
          next: () => {
            this.navigateToOrganizersList();
          },
        });
    } else {
      this.navigateToOrganizersList();
    }
  }

  private getObserver(): Observable<void> | null {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    const baseMapImage = this.formGroup.get('baseMap').value;

    return !baseMapImage
      ? this.deleteImage()
      : baseMapImage instanceof File
      ? this.eventService.uploadOrganizerMap(
          this.eventId,
          baseMapImage,
          activeLanguageCode
        )
      : null;
  }
}
