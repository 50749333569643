import { ILink } from '../model/link.model';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export const LINKS: ILink[] = [
  {
    name: marker('CORE.MAIN_MENU.BANNER'),
    url: '/general',
  },
  {
    name: marker('CORE.MAIN_MENU.PROGRAM'),
    url: `/program`,
  },
  {
    name: marker('CORE.MAIN_MENU.ORTHER_PAGES'),
    url: '/other-pages',
  },
  {
    name: marker('CORE.MAIN_MENU.NOTIFICATIONS'),
    url: '/notifications',
  },
  {
    name: marker('CORE.MAIN_MENU.EXTRA_INFO'),
    url: '/extra',
  },
];
