import { IProgramType } from '../models/program.model';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export const PROGRAM_TYPES: IProgramType[] = [
  {
    id: 0,
    value: marker('PROGRAM.TYPES.ALL'),
  },
  {
    id: 2,
    value: marker('PROGRAM.TYPES.RACE'),
  },
  {
    id: 1,
    value: marker('PROGRAM.TYPES.EVENT'),
  },
];
