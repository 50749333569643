import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { defaultIfEmpty, map, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  IEvent,
  IEventPreference,
  IEventResult,
  IPatchEvent,
} from '../../model/events.model';
import { StorageService } from '../../../shared/services/storage/storage.service';
import {
  EVENT_EXT_ID_LS_KEY,
  EVENT_ID_LS_KEY,
} from '../../../shared/constants/storage.constant';
import { IGeneralImage } from '../../model/image.model';
import { getPostEvent } from '../../utils/event.util';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private readonly basePath = environment.apiUrl;

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService
  ) {}

  getEventList(): Observable<IEvent[]> {
    return this.httpClient.get<IEvent[]>(`${this.basePath}/events/getEvents`);
  }

  getSortedEventList(): Observable<IEvent[]> {
    return this.httpClient.get<IEvent[]>(
      `${this.basePath}/events/getSortedEvents`
    );
  }

  getEventResults(
    year: number,
    extEventId: number
  ): Observable<IEventResult[]> {
    return this.httpClient.get<IEventResult[]>(
      `${this.basePath}/v2/races/${year}`,
      {
        params: {
          extEventId: extEventId as unknown as string,
        },
      }
    );
  }

  reorderEventResults(
    reorderedEventResults: IEventResult[]
  ): Observable<IEventResult[]> {
    return this.httpClient.post<IEventResult[]>(
      `${this.basePath}/v2/races/order`,
      reorderedEventResults
    );
  }

  eventDetail(eventId: number): Observable<IEvent> {
    return this.getEventList().pipe(
      map((events) => {
        return events.find((event) => event.id === eventId);
      })
    );
  }

  getEventId(): number {
    return this.storageService.getData(EVENT_ID_LS_KEY) as number;
  }

  getEventExtId(): number {
    return this.storageService.getData(EVENT_EXT_ID_LS_KEY) as number;
  }

  updateGeneral(event: IEvent, activeLanguageCode: string): Observable<any> {
    return this.uploadInfoImages(
      event.id,
      [
        {
          value: event.banner1[activeLanguageCode],
          upload: 'uploadBanner1',
          delete: 'banner1',
        },
        {
          value: event.banner2[activeLanguageCode],
          upload: 'uploadBanner2',
          delete: 'banner2',
        },
        {
          value: event.landingImage[activeLanguageCode],
          upload: 'uploadLandingImg',
          delete: 'landingImg',
        },
      ],
      activeLanguageCode
    )
      .pipe(
        mergeMap(() => {
          return this.patchEventBanners(getPostEvent(event));
        })
      )
      .pipe(defaultIfEmpty());
  }

  uploadInfoImages(
    eventId: number,
    images: IGeneralImage[],
    activeLanguageCode: string
  ): Observable<void[]> {
    return forkJoin(
      images.map((image) => {
        if (image.value && typeof image.value !== 'string') {
          return this.updateGeneralImages(
            eventId,
            image.value,
            image.upload,
            activeLanguageCode
          );
        } else if (image.value === undefined) {
          return this.deleteGeneralImages(
            eventId,
            image.delete,
            activeLanguageCode
          );
        }
        return of(0 as unknown as void);
      })
    ).pipe(defaultIfEmpty([]));
  }

  patchEventBanners(event: IPatchEvent): Observable<void> {
    return this.httpClient.patch<void>(`${this.basePath}/event`, event);
  }

  deleteGeneralImages(
    eventId: number,
    type: string,
    activeLanguageCode: string
  ): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.basePath}/events/${eventId}/${activeLanguageCode}/${type}`
    );
  }

  getEventPreference(eventId: number): Observable<IEventPreference[]> {
    return this.httpClient.get<IEventPreference[]>(
      `${this.basePath}/events/${eventId}/preferences`
    );
  }

  createOrUpdatePreference(
    eventId: number,
    preferenceKey: string,
    preferenceValue: boolean
  ): Observable<void> {
    return this.httpClient.post<void>(
      `${this.basePath}/events/${eventId}/preference`,
      {},
      {
        params: {
          key: preferenceKey,
          value: preferenceValue.toString(),
        },
      }
    );
  }

  deletePreference(eventId: number, preferenceKey: string): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.basePath}/events/${eventId}/preference`,
      {
        params: {
          key: preferenceKey,
        },
      }
    );
  }

  updateGeneralImages(
    eventId: number,
    image: File,
    type: string,
    activeLanguageCode: string
  ): Observable<void> {
    const formData = new FormData();
    formData.append('file', image, image?.name.toLowerCase());

    return this.httpClient.post<void>(
      `${this.basePath}/events/${eventId}/${activeLanguageCode}/${type}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  }

  uploadOrganizerMap(
    eventId: number,
    baseMap: File,
    activeLanguageCode: string
  ): Observable<void> {
    const formData = new FormData();
    formData.append('file', baseMap, baseMap.name.toLowerCase());

    return this.httpClient.post<void>(
      `${this.basePath}/events/${eventId}/${activeLanguageCode}/uploadOrganizerMap`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  }

  deleteOrganizerMap(
    eventId: number,
    activeLanguageCode: string
  ): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.basePath}/events/${eventId}/${activeLanguageCode}/organizerMap`
    );
  }
}
