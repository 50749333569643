<div *ngIf="data">
  <app-back-panel
    [title]="'OTHER-PAGES.PAGES.BACK' | translate"
  ></app-back-panel>
  <app-list-top-card [title]="'OTHER-PAGES.PAGES.WEATHER' | translate">
  </app-list-top-card>
  <app-detail-card class="mb3">
    <form [formGroup]="formGroup">
      <div class="mb3">
        <mat-form-field class="app-form-field-full-width">
          <mat-label>{{
            "PROGRAM.OTHER-PAGES.PAGES.WEATHER.NAME" | translate
          }}</mat-label>
          <input formControlName="title" matInput />
        </mat-form-field>
      </div>

      <div class="mb2 app-other-pages-title">
        {{ "PROGRAM.OTHER-PAGES.PAGES.WEATHER.WAX" | translate }}
      </div>

      <div class="mb3">
        <mat-form-field class="app-form-field-full-width">
          <mat-label>{{
            "PROGRAM.OTHER-PAGES.PAGES.WEATHER.RACE-TITLE" | translate
          }}</mat-label>
          <input formControlName="raceTitle" matInput />
        </mat-form-field>
      </div>

      <div class="mb3">
        <app-image-input
          [control]="formGroup.get('image')"
          [width]="110"
          [height]="180"
          [title]="'OTHER-PAGES.PAGES.WEATHER' | translate"
          (delete)="deleteImage()"
        ></app-image-input>
      </div>
    </form>
  </app-detail-card>
  <app-footer [loading]="loading" (save)="save()"></app-footer>
</div>
<app-spinner *ngIf="!data"></app-spinner>
