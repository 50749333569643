import { Injectable } from '@angular/core';
import { concat, forkJoin, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { defaultIfEmpty, map, mergeMap } from 'rxjs/operators';
import {
  ITransportParking,
  ITransportParkingDetail,
  ITransportTaxi,
  ITransportBus,
  IDeletedTransportItem,
  ITransportParkingInfo,
} from '../../model/other-pages.model';

@Injectable({
  providedIn: 'root',
})
export class TransportService {
  private readonly basePath = environment.apiUrl;

  constructor(private httpClient: HttpClient) {}

  getTransportParking(eventId: number): Observable<ITransportParking> {
    return this.httpClient.get<ITransportParking>(
      `${this.basePath}/event/${eventId}/parking`
    );
  }

  getTransportTaxi(eventId: number): Observable<ITransportTaxi[]> {
    return this.httpClient.get<ITransportTaxi[]>(
      `${this.basePath}/event/${eventId}/taxi`
    );
  }

  getTransportBus(eventId: number): Observable<ITransportBus[]> {
    return this.httpClient.get<ITransportBus[]>(
      `${this.basePath}/event/${eventId}/bus`
    );
  }

  createOrUpdate(
    eventId: number,
    parking: ITransportParkingDetail[],
    taxi: ITransportTaxi[],
    bus: ITransportBus[],
    parkingInfo: ITransportParkingInfo[],
    image: any,
    activeLanguageCode: string
  ): Observable<any> {
    return forkJoin([
      this.createOrUpdateTransportParking(eventId, parking),
      this.createOrUpdateTransportTaxi(eventId, taxi),
      this.createOrUpdateTransportBus(eventId, bus),
      this.changeParkingMap(eventId, [image], activeLanguageCode),
    ])
      .pipe(
        mergeMap(() =>
          this.createOrUpdateTransportParkingInfo(eventId, parkingInfo)
        )
      )
      .pipe(defaultIfEmpty([]));
  }

  createOrUpdateParkingInfo(
    eventId: number,
    parkingInfo: ITransportParkingInfo
  ): Observable<void> {
    return this.httpClient.post<void>(
      `${this.basePath}/event/${eventId}/parkingInfo`,
      parkingInfo
    );
  }

  getTransportParkingInfo(
    eventId: number
  ): Observable<ITransportParkingInfo[]> {
    return this.httpClient.get<ITransportParkingInfo[]>(
      `${this.basePath}/event/${eventId}/parkingInfo`
    );
  }

  createOrUpdateTransportParkingInfo(
    eventId: number,
    parkingInfo: ITransportParkingInfo[]
  ): Observable<any> {
    return concat(
      ...parkingInfo.map((info) => {
        return this.createOrUpdateParkingInfo(eventId, info);
      })
    );
  }

  deleteTransport(transport: IDeletedTransportItem[]): Observable<void[]> {
    return forkJoin(
      transport.map((item) => {
        return this.deleteTransportByType(item.deletedId, item.name);
      })
    );
  }

  changeParkingMap(
    eventId: number,
    images: any[],
    activeLanguageCode: string
  ): Observable<void[]> {
    return forkJoin(
      images.map((image) => {
        if (image && typeof image !== 'string') {
          return this.uploadParkingImage(eventId, image, activeLanguageCode);
        } else if (image === '') {
          return this.deleteParkingImage(eventId, activeLanguageCode);
        } else {
          return of(0 as unknown as void);
        }
      })
    ).pipe(defaultIfEmpty([]));
  }

  createOrUpdateTransportParking(
    eventId: number,
    parking: ITransportParkingDetail[]
  ): Observable<any> {
    return forkJoin(
      parking.map((place) => {
        return this.createOrUpdateParking(eventId, place);
      })
    ).pipe(defaultIfEmpty([]));
  }

  createOrUpdateTransportBus(
    eventId: number,
    bus: ITransportBus[]
  ): Observable<any> {
    return forkJoin(
      bus.map((value) => {
        return this.createOrUpdateBus(eventId, value);
      })
    ).pipe(defaultIfEmpty([]));
  }

  createOrUpdateBus(eventId: number, parking: ITransportBus): Observable<void> {
    return this.httpClient.post<void>(
      `${this.basePath}/event/${eventId}/bus`,
      parking
    );
  }

  createOrUpdateParking(
    eventId: number,
    parking: ITransportParkingDetail
  ): Observable<void> {
    return this.httpClient.post<void>(
      `${this.basePath}/event/${eventId}/parking`,
      parking
    );
  }

  uploadParkingImage(
    eventId: number,
    image: File,
    activeLanguageCode: string
  ): Observable<void> {
    const formData = new FormData();
    formData.append('file', image, image?.name.toLowerCase());

    return this.httpClient.post<void>(
      `${this.basePath}/event/${eventId}/${activeLanguageCode}/uploadParkingImg`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  }

  deleteParkingImage(
    eventId: number,
    activeLanguageCode: string
  ): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.basePath}/event/${eventId}/${activeLanguageCode}/parkingImg`
    );
  }

  createOrUpdateTransportTaxi(
    eventId: number,
    taxi: ITransportTaxi[]
  ): Observable<any> {
    return forkJoin(
      taxi.map((car) => {
        return this.createOrUpdateTaxi(eventId, car);
      })
    ).pipe(defaultIfEmpty([]));
  }

  createOrUpdateTaxi(eventId: number, taxi: ITransportTaxi): Observable<void> {
    return this.httpClient.post<void>(
      `${this.basePath}/event/${eventId}/taxi`,
      taxi
    );
  }

  deleteTransportByType(ids: number[], type: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.basePath}/${type}`, {
      params: {
        ids: ids.toString(),
      },
    });
  }
}
