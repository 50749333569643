import { SUPPORTED_LANGUAGES } from '../constants/supported-languages.constant';

export function isI18nObject(object: object): boolean {
  return (
    SUPPORTED_LANGUAGES.every(
      (language) =>
        typeof object[language.languageCode] === 'string' ||
        object[language.languageCode] === null
    ) && Object.keys(object).length === SUPPORTED_LANGUAGES.length
  );
}

export function replaceNullStringValue<T extends object>(
  objectValue: object
): T {
  const modifiedObject: object = {};

  Object.entries(objectValue).forEach(([key, value]) => {
    modifiedObject[key] =
      typeof value === 'string'
        ? replaceNullStringWithEmptyValue(value)
        : value;
  });

  return modifiedObject as T;
}

export function replaceNullStringWithEmptyValue(value: string): string {
  return value === 'null' ? '' : value;
}
