<table
  *ngIf="organizerPages.length !== 0"
  class="full-width"
  mat-table
  [dataSource]="organizerPages"
>
  <!-- Enable column -->
  <ng-container matColumnDef="enabled">
    <th mat-header-cell *matHeaderCellDef class="app-checkbox-header-cell">
      {{ "TABLE.COLUMNS.ENABLE" | translate | uppercase }}
    </th>
    <td mat-cell *matCellDef="let organizerPage">
      <mat-checkbox
        *ngIf="canCheck(organizerPage?.canCheck)"
        [checked]="isPageVisibleInApp(organizerPage.id)"
        (click)="$event.stopPropagation()"
        (change)="onVisibilityChange($event.checked, organizerPage)"
      >
      </mat-checkbox>
    </td>
  </ng-container>

  <!-- Title column -->
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef>
      {{ "ORGANIZERS.TITLE" | translate | uppercase }}
    </th>
    <td mat-cell *matCellDef="let organizerPage">
      {{ organizerPage.titleI18n | translate }}
    </td>
  </ng-container>

  <!-- Edit Column -->
  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let organizerPage">
      <div class="flex justify-end">
        <mat-icon>edit</mat-icon>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr
    mat-row
    *matRowDef="let organizerPage; columns: columns"
    (click)="onNavigateToPage(organizerPage)"
  ></tr>
</table>

<app-empty-result *ngIf="organizerPages.length === 0"></app-empty-result>
