import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { defaultIfEmpty } from 'rxjs/operators';
import { OrganizerContactService } from 'src/app/api/services/organizer-contact/organizer-contact.service';
import {
  ITranslatedContactGroup,
  ITranslatedImportantContact,
} from '../../model/important-contacts.model';
import { IContactGroup } from 'src/app/api/model/important-contact.model';

@Injectable({
  providedIn: 'root',
})
export class ImportantContactsService {
  constructor(private organizerContactService: OrganizerContactService) {}

  saveImportantContact(
    eventId: number,
    contactGroups: IContactGroup[],
    ids: number[],
    activeLanguageCode: string
  ): Observable<any> {
    return forkJoin([
      this.organizerContactService.deleteImportantContacts(ids),
      this.organizerContactService.createOrUpdateImportantContacts(
        eventId,
        contactGroups,
        activeLanguageCode
      ),
    ]).pipe(defaultIfEmpty([]));
  }

  transformImportantContactsFromAPI(
    contacts: ITranslatedImportantContact[]
  ): ITranslatedContactGroup[] {
    const uniqueContactGroupNames = this.getUniqueContactGroupNames(contacts);

    return uniqueContactGroupNames.map((group) => {
      return {
        groupName: group,
        contacts: contacts.filter((contact) => contact.groupName === group),
      };
    });
  }

  getUniqueContactGroupNames(
    contacts: ITranslatedImportantContact[]
  ): string[] {
    return contacts.reduce<string[]>((uniqueGroups, contact) => {
      if (!uniqueGroups.includes(contact.groupName)) {
        return uniqueGroups.concat(contact.groupName);
      }
      return uniqueGroups;
    }, []);
  }

  getUniqueContactGroupsWithEmptyContacts(
    contacts: ITranslatedImportantContact[]
  ): ITranslatedContactGroup[] {
    return this.getUniqueContactGroupNames(contacts).map((group) => {
      return {
        groupName: group,
        contacts: [],
      };
    });
  }
}
