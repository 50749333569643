import { formatDate } from '@angular/common';

interface ITime {
  hour: number;
  minute: number;
}

export function dateFormat(date: Date): string {
  const FORMAT = 'dd.MM.yyyy';
  const LOCALE = 'en-US';
  return formatDate(date, FORMAT, LOCALE);
}

export function dateTimeFormat(date: Date): string {
  return new Date(
    new Date(date).getTime() - new Date(date).getTimezoneOffset() * 60000
  ).toISOString();
}

export function timeFormat(date: Date): string {
  return date.toLocaleTimeString('cs-CZ', {
    hour: '2-digit',
    minute: '2-digit',
  });
}

export function setDateWithTime(date: Date, time: string): Date {
  const parsedTime = getTime(time);
  date = new Date(date);
  date.setHours(parsedTime.hour);
  date.setMinutes(parsedTime.minute);
  return new Date(date);
}

export function dateToUTC(date: Date, time: string): Date {
  const DATE = setDateWithTime(date, time);
  return new Date(DATE.getTime() + DATE.getTimezoneOffset() * 60000);
}

export function getTimeFromDate(date: Date): string {
  date = new Date(date);
  return date.getHours() + ':' + date.getMinutes();
}

function getTime(time: string): ITime {
  return {
    hour: parseInt(time.substring(0, 2), 10),
    minute: parseInt(time.substring(2 + 1, time.length), 10),
  };
}
