import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CanDeactivate } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/component/dialogs/confirm-dialog/confirm-dialog.component';
import { IComponentCanDeactivate } from '../model/can-deactive-guard.model';

@Injectable({
  providedIn: 'root',
})
export class CanDeactiveGuard
  implements CanDeactivate<IComponentCanDeactivate>
{
  constructor(private dialog: MatDialog, private translate: TranslateService) {}

  canDeactivate(
    component: IComponentCanDeactivate
  ): Observable<boolean> | boolean {
    if (!component?.canDeactivate()) {
      const dialogRef = this.openLeaveConfirmDialog();
      return dialogRef.afterClosed();
    }
    return true;
  }

  openLeaveConfirmDialog(): MatDialogRef<ConfirmDialogComponent> {
    return this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '650px',
      autoFocus: false,
      data: {
        danger: false,
        showClose: false,
        mainText: this.translate.instant(
          marker('CORE.LEAVE_PAGE_CONFIRMATION_DIALOG.TITLE')
        ),
        subText: this.translate.instant(
          marker('CORE.LEAVE_PAGE_CONFIRMATION_DIALOG.SUBTITLE')
        ),
        cancelText: this.translate.instant(
          marker('CORE.LEAVE_PAGE_CONFIRMATION_DIALOG.CANCEL_TEXT')
        ),
        confirmText: this.translate.instant(
          marker('CORE.LEAVE_PAGE_CONFIRMATION_DIALOG.CONFIRM_TEXT')
        ),
      },
    });
  }
}
