<div *ngIf="program">
  <app-back-panel [title]="'PROGRAM.DETAIL.BACK' | translate"></app-back-panel>

  <app-list-top-card [title]="getProgramTitle()"> </app-list-top-card>

  <app-detail-card>
    <div class="flex">
      <div class="bold mr2">{{ "PROGRAM.DETAIL.TYPE" | translate }}</div>

      <mat-radio-group class="app-news-detail-radio-buttons">
        <mat-radio-button
          *ngFor="let type of types"
          [checked]="type.id === programType"
          [disabled]="true"
          [value]="type.id"
          class="app-news-detail-radio-buttons-button mr2"
          >{{ type.value | translate }}</mat-radio-button
        >
      </mat-radio-group>
    </div>
  </app-detail-card>

  <app-program-event-detail
    *ngIf="programType === 1"
    [event]="program"
    [formGroup]="eventFormGroup"
  ></app-program-event-detail>

  <app-program-race-detail
    *ngIf="programType === 2"
    [race]="program"
    [formGroup]="raceFormGroup"
  ></app-program-race-detail>
</div>

<app-spinner *ngIf="!program"></app-spinner>
