<div>
  <app-list-top-card [title]="'ORGANIZERS.TITLE' | translate">
    <app-search-box
      class="mr2"
      [label]="'ORGANIZERS.FILTERS.SEARCH' | translate"
      [value]="searchValue"
      (search)="onSearch($event)"
    ></app-search-box>
  </app-list-top-card>

  <app-organizer-pages-table
    [organizerPages]="organizerPages | translatePagesTitles: translate.currentLang | filterPagesByName: searchValue"
    [preferences]="preferences"
    [activeLanguageCode]="activeLanguageCode"
    (navigateToPage)="onNavigateToPage($event)"
    (changePageVisibility)="onChangeVisibilityState($event)"
  ></app-organizer-pages-table>
</div>
