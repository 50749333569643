import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-enervit-products',
  templateUrl: './enervit-products.component.html',
  styleUrls: ['./enervit-products.component.scss'],
})
export class EnervitProductsComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() index: number;
  @Output() add = new EventEmitter<void>();
  @Output() getIndex = new EventEmitter<number>();
  @Output() del = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
