import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IProgramRacePost } from '../../model/program.model';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  FormArray,
  Validators,
} from '@angular/forms';
import { ProgramService } from '../../services/program/program.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  setDateWithTime,
  timeFormat,
} from 'src/app/shared/utils/dateFormatter.util';
import { ImagesService } from 'src/app/api/service/images/images.service';
import { IImage, IProgramImage } from 'src/app/api/model/image.model';
import { FileExtension } from 'src/app/shared/utils/validation.util';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Program } from '../../enums/program.enum';
import { ImageType } from 'src/app/api/model/image.model';
import { LanguageService } from 'src/app/shared/services/language/language.service';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { StartInstructionsService } from 'src/app/api/service/start-instructions/start-instructions.service';
import { IStartInstruction } from 'src/app/api/model/start-instruction.model';
import { mergeMap } from 'rxjs/operators';
import { TIME_REGEX } from 'src/app/shared/constants/time.constants';

@Component({
  selector: 'app-program-detail-race',
  templateUrl: './program-detail-race.component.html',
  styleUrls: ['./program-detail-race.component.scss'],
})
export class ProgramDetailRaceComponent implements OnInit {
  private readonly EVENT_ID = parseInt(localStorage.getItem('eventId'), 10);
  private programId: string;
  @Input() race: IProgramRacePost;
  @Output() raceResult = new EventEmitter<IProgramRacePost>();
  formGroup: FormGroup;
  mapUrl: string;
  sponzorUrl: string;
  images: IProgramImage[];
  deletedBasicFormImagesId: number[] = [];

  addedImages = new FormControl('', FileExtension(['jpg', 'png']));
  loadedImages = new FormControl('');
  time = new FormControl('');
  loading = false;
  scheduleType: string;
  private subs = new Subscription();
  startInstruction: IStartInstruction;

  loadedStartInstructionImageGallery: IImage[] = [];

  constructor(
    public formBuilder: FormBuilder,
    private programService: ProgramService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private imagesService: ImagesService,
    private matSnackBar: MatSnackBar,
    private languageService: LanguageService,
    private startInstructionsService: StartInstructionsService
  ) {
    this.formGroup = this.formBuilder.group({
      time: this.formBuilder.group({
        timeFrom: ['', [Validators.pattern(TIME_REGEX)]],
        timeTo: ['', [Validators.pattern(TIME_REGEX)]],
      }),

      gallery: this.formBuilder.array([]),
      instructionGallery: this.formBuilder.array([]),
      basicContent: this.formBuilder.group({
        inserted: [''],
        updated: [''],
        title: [''],
        id: [0],
        description: [''],
        link: [''],
        raceCode: [''],
        raceScheduleId: [''],
      }),

      race: this.formBuilder.group({
        active: [''],
        dateTimeFrom: [''],
        dateTimeTo: [''],
        eventId: [''],
        fbLink: [''],
        heightProfileImg: [''],
        id: [''],
        inserted: [''],
        mapImg: [''],
        place: [''],
        sponsorImg: [''],
        startNumEmission: [''],
        startType: [''],
        startWaves: [''],
        technique: [''],
        title: [''],
        updated: [''],
        raceCode: [''],
        gpsLat: [''],
        gpsLon: [''],
        type: [''],
        urlInfoNumRel: [''],
        urlInfoRace: [''],
      }),
    });
  }

  ngOnInit(): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    this.initRace();
    this.programId = this.activatedRoute.snapshot.paramMap.get('programId');
    this.scheduleType =
      this.activatedRoute.snapshot.queryParamMap.get('scheduleType');
    this.initImages();

    // if (this.scheduleType !== 'ORGANIZER') {
    //   this.subs.add(
    //     this.initStartInstructions().subscribe(
    //       (startInstruction) => {
    //         this.startInstruction = startInstruction;
    //         this.initEventStartInformationGroup();
    //       },
    //       (error: unknown) => {
    //         this.initEmptyRaceStartInstruction();
    //         console.error(error);
    //       }
    //     )
    //   );
    // }

    if (this.scheduleType !== 'ORGANIZER') {
      this.subs.add(
        this.initStartInstructions()
          .pipe(
            mergeMap((startInstruction) => {
              if (startInstruction) {
                this.startInstruction = startInstruction;
                this.initEventStartInformationGroup();
                return this.initStartInstructionsImageGallery(
                  startInstruction.id,
                  activeLanguageCode
                );
              }

              return of(null);
            })
          )
          .subscribe(
            (startInstructionImages) => {
              if (startInstructionImages) {
                this.loadedStartInstructionImageGallery =
                  startInstructionImages;
                this.initStartInstructionsImageGalleryFormGroup(
                  startInstructionImages
                );
              } else {
                this.initEmptyRaceStartInstruction();
              }
            },
            (error: unknown) => {
              this.initEmptyRaceStartInstruction();
              console.error(error);
            }
          )
      );
    }
  }

  initStartInstructionsImageGalleryFormGroup(
    instructionImages: IImage[]
  ): void {
    instructionImages.forEach(() => {
      this.addFormGroup('instructionGallery');
    });
    this.formGroup.get('instructionGallery').setValue(instructionImages);
  }

  addFormGroup(type: string): void {
    (this.formGroup.get(type) as FormArray).push(this.createGroup(type));
  }

  createGroup(type: string): FormGroup {
    switch (type) {
      case 'instructionGallery':
        return this.formBuilder.group({
          inserted: [''],
          updated: [''],
          id: [0],
          lang: [''],
          orderBy: [''],
          type: [''],
          image: [''],
        });
    }
  }

  initStartInstructionsImageGallery(
    startInstructionId: number,
    activeLanguageCode: string
  ): Observable<IImage[]> {
    return this.imagesService.getImages(
      startInstructionId,
      ImageType.StartInstruction,
      activeLanguageCode
    );
  }

  navigate(): void {
    this.router.navigate([
      Program[this.scheduleType],
      'list',
      this.scheduleType,
    ]);
  }

  initStartInstructions(): Observable<IStartInstruction> {
    return this.startInstructionsService.getStartInstructions(this.race.id);
  }

  onDeleteBasicFormImage(basicImageId: number): void {
    this.deletedBasicFormImagesId.push(basicImageId);
  }

  initImages(): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    this.imagesService
      .getImages(
        parseInt(this.programId, 10),
        ImageType.ScheduleRace,
        activeLanguageCode
      )
      .subscribe((images) => {
        this.loadedImages.setValue(images);
        this.images = images;
      });
  }

  openSnackBar(message: string, action: string): void {
    this.matSnackBar.open(message, action, {
      duration: 2000,
    });
  }

  initEmptyRaceStartInstruction(): void {
    const raceStartInstructionFormGroup = this.formGroup.get('basicContent');
    const raceFormGroup = this.formGroup.get('race');

    this.startInstruction = {
      description: {
        cs: raceStartInstructionFormGroup.get('description').value,
        en: raceStartInstructionFormGroup.get('description').value,
      },
      id: raceStartInstructionFormGroup.get('id').value,
      inserted: raceStartInstructionFormGroup.get('inserted').value,
      link: {
        cs: raceStartInstructionFormGroup.get('link').value,
        en: raceStartInstructionFormGroup.get('link').value,
      },
      raceCode: {
        cs: raceFormGroup.get('raceCode').value,
        en: raceFormGroup.get('raceCode').value,
      },
      raceScheduleId: this.race.id,
      title: {
        cs: raceStartInstructionFormGroup.get('title').value,
        en: raceStartInstructionFormGroup.get('title').value,
      },
      updated: raceStartInstructionFormGroup.get('updated').value,
    };
  }

  transformRaceStartInstruction(): void {
    const raceStartInstructionFormGroup = this.formGroup.get('basicContent');
    const raceFormGroup = this.formGroup.get('basicContent');
    const activeLanguageCode = this.languageService.getActiveLanguage();
    this.startInstruction.id = raceStartInstructionFormGroup.get('id').value;
    this.startInstruction.description[activeLanguageCode] =
      raceStartInstructionFormGroup.get('description').value;
    this.startInstruction.inserted =
      raceStartInstructionFormGroup.get('inserted').value;
    this.startInstruction.link[activeLanguageCode] =
      raceStartInstructionFormGroup.get('link').value;
    this.startInstruction.raceCode.cs = this.formGroup
      .get('race')
      .get('raceCode').value;
    this.startInstruction.raceCode.en = this.formGroup
      .get('race')
      .get('raceCode').value;
    this.startInstruction.raceScheduleId = this.race.id;
    this.startInstruction.title[activeLanguageCode] =
      raceStartInstructionFormGroup.get('title').value;
    this.startInstruction.updated =
      raceStartInstructionFormGroup.get('updated').value;
  }

  transformRaceDetailObj(): void {
    const eventFormGroup = this.formGroup.get('race');
    const activeLanguageCode = this.languageService.getActiveLanguage();

    this.race.active = eventFormGroup.get('active').value;
    this.race.dateTimeFrom = eventFormGroup.get('dateTimeFrom').value;
    this.race.dateTimeTo = eventFormGroup.get('dateTimeTo').value;
    this.race.eventId = eventFormGroup.get('eventId').value;
    this.race.fbLink[activeLanguageCode] = eventFormGroup.get('fbLink').value;
    this.race.gpsLat = eventFormGroup.get('gpsLat').value;
    this.race.gpsLon = eventFormGroup.get('gpsLon').value;
    this.race.id = eventFormGroup.get('id').value;
    this.race.inserted = eventFormGroup.get('inserted').value;
    this.race.place[activeLanguageCode] = eventFormGroup.get('place').value;
    this.race.sponsorImg[activeLanguageCode] =
      eventFormGroup.get('sponsorImg').value;
    this.race.title[activeLanguageCode] = eventFormGroup.get('title').value;
    this.race.type = eventFormGroup.get('type').value;
    this.race.updated = eventFormGroup.get('updated').value;
    this.race.raceCode.cs = eventFormGroup.get('raceCode').value;
    this.race.raceCode.en = eventFormGroup.get('raceCode').value;

    this.race.heightProfileImg[activeLanguageCode] =
      eventFormGroup.get('heightProfileImg').value;
    this.race.mapImg[activeLanguageCode] = eventFormGroup.get('mapImg').value;
    this.race.startNumEmission[activeLanguageCode] =
      eventFormGroup.get('startNumEmission').value;
    this.race.startType[activeLanguageCode] =
      eventFormGroup.get('startType').value;
    this.race.startWaves[activeLanguageCode] =
      eventFormGroup.get('startWaves').value;
    this.race.technique[activeLanguageCode] =
      eventFormGroup.get('technique').value;
    this.race.urlInfoNumRel[activeLanguageCode] =
      eventFormGroup.get('urlInfoNumRel').value;
    this.race.urlInfoRace[activeLanguageCode] =
      eventFormGroup.get('urlInfoRace').value;
  }

  save(): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    if (this.validate()) {
      return;
    }
    this.formatDateTime('dateTimeFrom', 'timeFrom');
    this.formatDateTime('dateTimeTo', 'timeTo');
    this.transformRaceDetailObj();

    this.loading = true;

    if (this.scheduleType === 'ORGANIZER') {
      this.saveScheduleRace(activeLanguageCode);
    } else {
      this.saveScheduleRaceWithInstruction(activeLanguageCode);
    }
  }

  saveScheduleRace(activeLanguageCode: string): void {
    this.saveRace()
      .pipe(
        mergeMap((race) =>
          forkJoin([
            this.uploadRaceImages(activeLanguageCode, race.id),
            this.saveImage(race.id, 'sponsorImg', 'uploadSponsor'),
            this.saveImage(race.id, 'mapImg', 'uploadMapImage'),
            this.saveImage(
              race.id,
              'heightProfileImg',
              'uploadHeightProfileImage'
            ),
          ])
        )
      )
      .subscribe(
        () => {
          this.initImages();
          this.navigate();
        },
        (error: unknown) => {
          console.error(error);
        }
      );
  }

  saveScheduleRaceWithInstruction(activeLanguageCode: string): void {
    this.transformRaceStartInstruction();
    this.saveRace()
      .pipe(
        mergeMap((race) =>
          forkJoin([
            this.saveStartInstruction(race.id),
            this.uploadRaceImages(activeLanguageCode, race.id),
            this.saveImage(race.id, 'sponsorImg', 'uploadSponsor'),
            this.saveImage(race.id, 'mapImg', 'uploadMapImage'),
            this.saveImage(
              race.id,
              'heightProfileImg',
              'uploadHeightProfileImage'
            ),
          ]).pipe(
            mergeMap(([startInstruction]) =>
              this.imagesService.uploadRelatedImages(
                this.EVENT_ID,
                startInstruction.id,
                this.getUploadedImages(),
                ImageType.StartInstruction,
                this.deletedBasicFormImagesId,
                activeLanguageCode
              )
            )
          )
        )
      )
      .subscribe(
        () => {
          this.initImages();
          this.navigate();
        },
        (error: unknown) => {
          console.error(error);
        }
      );
  }

  getUploadedImages(): File[] {
    const galleryFiles: File[] = [];
    this.formGroup.get('instructionGallery').value.forEach((value) => {
      if (value.image instanceof File) {
        galleryFiles.push(value.image);
      }
    });
    return galleryFiles;
  }

  saveImage(programId: number, value: string, type: string): Observable<void> {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    const prop = this.formGroup.get('race').get(value).value;
    if (prop instanceof File) {
      return this.programService.uploadImage(
        programId,
        prop,
        type,
        2,
        activeLanguageCode
      );
    }
    return of(null);
  }

  saveRace(): Observable<IProgramRacePost> {
    return this.programService.createOrUpdateProgramRace(
      this.EVENT_ID,
      this.race
    );
  }

  saveStartInstruction(raceId: number): Observable<IStartInstruction> {
    this.startInstruction.raceScheduleId = raceId;
    return this.startInstructionsService.patchStartInstructions(
      this.startInstruction
    );
  }

  uploadRaceImages(
    activeLanguageCode: string,
    raceId: number
  ): Observable<any> {
    return this.imagesService.uploadImages(
      this.EVENT_ID,
      raceId,
      ImageType.ScheduleRace,
      this.addedImages.value || [],
      activeLanguageCode
    );
  }

  formatDateTime(dateType: string, timeType: string): void {
    const DATE = this.formGroup.get('race').get(dateType).value;
    const TIME = this.formGroup.get('time').get(timeType).value;
    const formatedDate = setDateWithTime(DATE, TIME);
    this.formGroup.get('race').get(dateType).setValue(formatedDate);
  }

  deleteImage(type: string): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    if (parseInt(this.programId, 10) !== 0) {
      this.programService
        .deleteRaceImage(
          parseInt(this.programId, 10),
          type,
          2,
          activeLanguageCode
        )
        .subscribe();
      this.ngOnInit();
    }
  }

  deleteGalleryImage(image: IProgramImage): void {
    this.imagesService.deleteImages(this.EVENT_ID, [image.id]).subscribe();
  }

  getImage(type: string): string {
    return this.programService.getImageUrl(this.race[type]);
  }

  initRace(): void {
    if (this.race) {
      const raceTimeFrom = timeFormat(new Date(this.race.dateTimeFrom));
      const raceTimeTo = timeFormat(new Date(this.race.dateTimeTo));
      this.initEventFormGroup();
      this.formGroup.get('time').get('timeFrom').setValue(raceTimeFrom);
      this.formGroup.get('time').get('timeTo').setValue(raceTimeTo);
    }
  }

  initEventStartInformationGroup(): void {
    if (!this.race.id) {
      this.initEmptyRaceStartInstruction();
      return;
    }

    const languageCode = this.languageService.getActiveLanguage();

    if (this.startInstruction) {
      const raceStartInformationFormGroup = this.formGroup.get('basicContent');
      raceStartInformationFormGroup
        .get('id')
        .setValue(this.startInstruction.id);
      raceStartInformationFormGroup
        .get('description')
        .setValue(this.startInstruction.description[languageCode]);
      raceStartInformationFormGroup
        .get('inserted')
        .setValue(this.startInstruction.inserted);
      raceStartInformationFormGroup
        .get('link')
        .setValue(this.startInstruction.link[languageCode]);
      raceStartInformationFormGroup
        .get('raceCode')
        .setValue(this.race.raceCode.cs);
      raceStartInformationFormGroup
        .get('raceScheduleId')
        .setValue(this.startInstruction.raceScheduleId);
      raceStartInformationFormGroup
        .get('title')
        .setValue(this.startInstruction.title[languageCode]);
      raceStartInformationFormGroup
        .get('updated')
        .setValue(this.startInstruction.updated);
    }
  }

  initEventFormGroup(): void {
    const languageCode = this.languageService.getActiveLanguage();
    if (this.race) {
      const raceFormGroup = this.formGroup.get('race');
      const sponsorImg =
        this.race.sponsorImg[languageCode] !== 'null'
          ? this.race.sponsorImg[languageCode]
          : '';

      const heightProfileImg =
        this.race.heightProfileImg[languageCode] !== 'null'
          ? this.race.heightProfileImg[languageCode]
          : '';

      const mapImg =
        this.race.mapImg[languageCode] !== 'null'
          ? this.race.mapImg[languageCode]
          : '';

      raceFormGroup.get('active').setValue(this.race.active);
      raceFormGroup.get('dateTimeFrom').setValue(this.race.dateTimeFrom);
      raceFormGroup.get('dateTimeTo').setValue(this.race.dateTimeTo);
      raceFormGroup.get('eventId').setValue(this.race.eventId);
      raceFormGroup.get('fbLink').setValue(this.race.fbLink[languageCode]);
      raceFormGroup.get('id').setValue(this.race.id);
      raceFormGroup.get('place').setValue(this.race.place[languageCode]);
      raceFormGroup.get('sponsorImg').setValue(sponsorImg);
      raceFormGroup.get('title').setValue(this.race.title[languageCode]);
      raceFormGroup.get('updated').setValue(this.race.updated);
      raceFormGroup.get('gpsLat').setValue(this.race.gpsLat);
      raceFormGroup.get('gpsLon').setValue(this.race.gpsLon);
      raceFormGroup.get('type').setValue(this.race.type);
      raceFormGroup.get('heightProfileImg').setValue(heightProfileImg);
      raceFormGroup.get('mapImg').setValue(mapImg);
      raceFormGroup
        .get('startNumEmission')
        .setValue(this.race.startNumEmission[languageCode]);
      raceFormGroup
        .get('startType')
        .setValue(this.race.startType[languageCode]);
      raceFormGroup
        .get('startWaves')
        .setValue(this.race.startWaves[languageCode]);
      raceFormGroup
        .get('technique')
        .setValue(this.race.technique[languageCode]);
      raceFormGroup.get('raceCode').setValue(this.race.raceCode[languageCode]);
      raceFormGroup
        .get('urlInfoNumRel')
        .setValue(this.race.urlInfoNumRel[languageCode]);
      raceFormGroup
        .get('urlInfoRace')
        .setValue(this.race.urlInfoRace[languageCode]);
    }
  }

  validate(): boolean {
    if (this.formGroup.get('time').get('timeFrom').invalid) {
      this.formGroup.get('time').setErrors({ time: true });
      return true;
    }

    if (this.formGroup.get('time').get('timeTo').invalid) {
      this.formGroup.get('time').setErrors({ time: true });
      return true;
    }
    return false;
  }
}
