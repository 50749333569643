import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { LanguageService } from './shared/services/language/language.service';
import {
  DEFAULT_LANGUAGE_CODE,
  LANGUAGE_STORAGE_KEY,
} from './shared/constants/supported-languages.constant';
import { StorageService } from './shared/services/storage/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    private languageService: LanguageService,
    private storageService: StorageService
  ) {
    this.translate.onLangChange.subscribe((lang: LangChangeEvent) => {
      if (this.languageService.isLanguageSupported(lang.lang)) {
        this.saveAppLanguageToStore(lang.lang);
      }
    });
  }

  ngOnInit(): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    this.translate.setDefaultLang(activeLanguageCode);
    this.translate.use(activeLanguageCode);
  }

  saveAppLanguageToStore(languageCode: string): void {
    if (languageCode === DEFAULT_LANGUAGE_CODE) {
      this.storageService.saveData(LANGUAGE_STORAGE_KEY, languageCode);
    }
  }
}
