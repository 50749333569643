import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ImageType } from 'src/app/api/model/image.model';
import { IBasicFormContentInfo } from '../../model/basic-form-content-info.model';

@Injectable({
  providedIn: 'root',
})
export abstract class BasicFormContentService {
  readonly imageType: ImageType = ImageType.OtherInfo;

  constructor() {}

  abstract navigateToParent(): void;

  abstract getPageTitleI18n(pageType: string): string;

  abstract createOrUpdateInfo(
    eventId: number,
    info: IBasicFormContentInfo,
    infoType: string,
    galleryFiles: File[],
    deletedImagesId: number[],
    activeLanguageCode: string
  ): Observable<any>;

  abstract getInfoByType(
    eventId: number,
    type: string
  ): Observable<IBasicFormContentInfo>;
}
