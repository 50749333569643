import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
})
export class FileInputComponent implements OnInit {
  @Input() icon: any;
  @Input() formGroup: FormGroup;
  @Input() name: string;
  @Output() image = new EventEmitter<any>();
  @Output() delete = new EventEmitter<void>();
  @ViewChild('FileSelectInputDialog') FileSelectInputDialog: ElementRef;
  constructor() {}

  ngOnInit(): void {}

  public OpenAddFilesDialog(): void {
    const e: HTMLElement = this.FileSelectInputDialog.nativeElement;
    e.click();
  }

  public onChange(data: any): void {
    this.image.emit(data);
  }
}
