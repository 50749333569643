import { Injectable } from '@angular/core';
import {
  LANGUAGE_STORAGE_KEY,
  DEFAULT_LANGUAGE_CODE,
  SUPPORTED_LANGUAGES,
} from 'src/app/shared/constants/supported-languages.constant';
import { StorageService } from '../storage/storage.service';
import { isLanguageSupported } from 'src/app/core/utils/language.util';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private storageService: StorageService) {}

  setActiveLanguage(languageCode: string): void {
    this.storageService.saveData(LANGUAGE_STORAGE_KEY, languageCode);
  }

  getActiveLanguage(): string {
    // const storedLanguageCode = this.storageService.getData(
    //   LANGUAGE_STORAGE_KEY
    // ) as string;

    // if (this.isLanguageSupported(storedLanguageCode)) {
    //   return storedLanguageCode;
    // }

    return DEFAULT_LANGUAGE_CODE;
  }

  isLanguageSupported(languageCode: string): boolean {
    return isLanguageSupported(
      languageCode,
      SUPPORTED_LANGUAGES.map((language) => language.languageCode)
    );
  }
}
