import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ILoginProperties } from '../../model/auth.model';
import { AuthorizationService } from '../../service/authorization/authorization.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  // tslint:disable-next-line: quotemark
  readonly backgroungImagePath = "url('assets/image/login.jpg')";
  formGroup: FormGroup;
  error = false;

  constructor(
    private formBuilder: FormBuilder,
    private authorizationService: AuthorizationService,
    private router: Router
  ) {
    this.formGroup = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {}

  login(): void {
    const loginValues = this.formGroup.value;
    if (loginValues?.username && loginValues?.password) {
      const loginProps: ILoginProperties = {
        username: loginValues?.username,
        password: loginValues?.password,
      };
      this.authorizationService.login(loginProps).subscribe(
        () => {
          this.router.navigateByUrl('/');
        },
        () => {
          this.error = true;
        }
      );
    }
  }
}
