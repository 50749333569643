import { Component, Input, OnInit } from '@angular/core';
import { FormControlName, FormGroup } from '@angular/forms';
import * as CustomEditor from '../../../../assets/js/rich-ckeditor/ckeditor.js';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
})
export class TextEditorComponent implements OnInit {
  @Input() editorControl: FormControlName;
  @Input() formGroup: FormGroup;
  public Editor = CustomEditor;
  config: any;

  constructor() {
    this.config = {
      toolbar: {
        items: [
          'bold',
          'italic',
          'underline',
          'link',
          'bulletedList',
          'numberedList',
          'fontColor',
        ],
      },
    };
  }

  ngOnInit(): void {
    // empty
  }
}
