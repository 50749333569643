import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IBasicTable } from '../../../model/basic-table.model';

@Component({
  selector: 'app-basic-table',
  templateUrl: './basic-table.component.html',
  styleUrls: ['./basic-table.component.scss'],
})
export class BasicTableComponent implements OnInit {
  @Input() tableDataList: IBasicTable[];
  @Output() delete = new EventEmitter<IBasicTable>();
  @Output() rowClick = new EventEmitter<IBasicTable>();

  columns = ['title', 'actions'];

  constructor() {}

  ngOnInit(): void {
    // empty
  }
}
