import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IProgramRacePost } from '../../models/program.model';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { ProgramService } from '../../services/program.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  setDateWithTime,
  timeFormat,
} from 'src/app/shared/utils/dateFormatter.util';
import { ImagesService } from 'src/app/api/service/images/images.service';
import { IImage, IProgramImage } from 'src/app/api/model/image.model';
import { FileExtension } from 'src/app/shared/utils/validation.util';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageType } from 'src/app/api/model/image.model';
import { LanguageService } from 'src/app/shared/services/language/language.service';
import { forkJoin, iif, Observable, of, Subscription } from 'rxjs';
import { StartInstructionsService } from 'src/app/api/service/start-instructions/start-instructions.service';
import { IStartInstruction } from 'src/app/api/model/start-instruction.model';
import { defaultIfEmpty, mergeMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { PROGRESS_DIALOG_ACTION_SAVING } from 'src/app/shared/constants/dialog.constants';
import { ApiCallExtensionService } from 'src/app/shared/services/api-call-extension/api-call-extension.service';
import { reduceI18nObject } from 'src/app/shared/utils/reduce-i18n-object.util';
import { updateI18nObject } from 'src/app/shared/utils/update-i18n-object.util';

@Component({
  selector: 'app-program-race-detail',
  templateUrl: './program-race-detail.component.html',
  styleUrls: ['./program-race-detail.component.scss'],
})
export class ProgramRaceDetailComponent implements OnInit {
  @Input() race: IProgramRacePost;
  @Input() formGroup: FormGroup;
  @Output() raceResult = new EventEmitter<IProgramRacePost>();

  private readonly EVENT_ID = parseInt(localStorage.getItem('eventId'), 10);
  private programId: string;
  private subs = new Subscription();

  mapUrl: string;
  sponzorUrl: string;
  images: IProgramImage[];
  deletedBasicFormImagesId: number[] = [];

  addedImages = new FormControl('', FileExtension(['jpg', 'png']));
  loadedImages = new FormControl('');
  time = new FormControl('');
  loading = false;
  scheduleType: string;
  startInstruction: IStartInstruction;
  loadedStartInstructionImageGallery: IImage[] = [];

  constructor(
    public formBuilder: FormBuilder,
    private programService: ProgramService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private imagesService: ImagesService,
    private matSnackBar: MatSnackBar,
    private languageService: LanguageService,
    private startInstructionsService: StartInstructionsService,
    private translate: TranslateService,
    private apiCallExtensionService: ApiCallExtensionService
  ) {}

  ngOnInit(): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    this.initRace();
    this.programId = this.activatedRoute.snapshot.paramMap.get('programId');
    this.scheduleType =
      this.activatedRoute.snapshot.queryParamMap.get('scheduleType');
    this.initImages();

    if (this.scheduleType !== 'ORGANIZER') {
      this.subs.add(
        this.initStartInstructions()
          .pipe(
            mergeMap((startInstruction) => {
              if (startInstruction) {
                this.startInstruction = startInstruction;
                this.initEventStartInformationGroup();
                return this.initStartInstructionsImageGallery(
                  startInstruction.id,
                  activeLanguageCode
                );
              }

              return of(null);
            })
          )
          .subscribe(
            (startInstructionImages) => {
              if (startInstructionImages) {
                this.loadedStartInstructionImageGallery =
                  startInstructionImages;
                this.initStartInstructionsImageGalleryFormGroup(
                  startInstructionImages
                );
              } else {
                this.initEmptyRaceStartInstruction();
              }
            },
            (error: unknown) => {
              this.initEmptyRaceStartInstruction();
              console.error(error);
            }
          )
      );
    }
  }

  initStartInstructionsImageGalleryFormGroup(
    instructionImages: IImage[]
  ): void {
    instructionImages.forEach(() => {
      this.addFormGroup('basicContentGallery');
    });
    this.formGroup.get('basicContentGallery').setValue(instructionImages);
  }

  addFormGroup(type: string): void {
    (this.formGroup.get(type) as FormArray).push(this.createGroup(type));
  }

  createGroup(type: string): FormGroup {
    switch (type) {
      case 'basicContentGallery':
        return this.formBuilder.group({
          inserted: [''],
          updated: [''],
          id: [0],
          lang: [''],
          orderBy: [''],
          type: [''],
          image: [''],
        });
    }
  }

  initStartInstructionsImageGallery(
    startInstructionId: number,
    activeLanguageCode: string
  ): Observable<IImage[]> {
    return this.imagesService.getImages(
      startInstructionId,
      ImageType.StartInstruction,
      activeLanguageCode
    );
  }

  navigate(): void {
    this.router.navigate(['program']);
  }

  initStartInstructions(): Observable<IStartInstruction> {
    return this.startInstructionsService.getStartInstructions(this.race.id);
  }

  onDeleteBasicFormImage(index: number): void {
    const galleryImages = this.formGroup.get('basicContentGallery')
      .value as IImage[];

    this.deletedBasicFormImagesId.push(galleryImages[index]?.id);
    (this.formGroup.get('basicContentGallery') as FormArray).removeAt(index);
  }

  initImages(): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    this.imagesService
      .getImages(
        parseInt(this.programId, 10),
        ImageType.ScheduleRace,
        activeLanguageCode
      )
      .subscribe((images) => {
        this.loadedImages.setValue(images);
        this.images = images;
      });
  }

  openSnackBar(message: string, action: string): void {
    this.matSnackBar.open(message, action, {
      duration: 2000,
    });
  }

  initEmptyRaceStartInstruction(): void {
    const raceStartInstructionFormGroup = this.formGroup.get('basicContent');
    const raceFormGroup = this.formGroup.get('race');

    this.startInstruction = {
      description: {
        cs: raceStartInstructionFormGroup.get('description').value,
        en: raceStartInstructionFormGroup.get('description').value,
      },
      id: raceStartInstructionFormGroup.get('id').value,
      inserted: raceStartInstructionFormGroup.get('inserted').value,
      link: {
        cs: raceStartInstructionFormGroup.get('link').value,
        en: raceStartInstructionFormGroup.get('link').value,
      },
      raceCode: {
        cs: raceFormGroup.get('raceCode').value,
        en: raceFormGroup.get('raceCode').value,
      },
      raceScheduleId: this.race.id,
      title: {
        cs: raceStartInstructionFormGroup.get('title').value,
        en: raceStartInstructionFormGroup.get('title').value,
      },
      updated: raceStartInstructionFormGroup.get('updated').value,
    };
  }

  transformRaceStartInstruction(): void {
    const raceStartInstructionFormGroup = this.formGroup.get('basicContent');
    const activeLanguageCode = this.languageService.getActiveLanguage();

    this.startInstruction = updateI18nObject(
      { ...this.startInstruction },
      raceStartInstructionFormGroup.value,
      activeLanguageCode
    );
  }

  transformRaceDetailObj(): void {
    const eventFormGroup = this.formGroup.get('race');
    const activeLanguageCode = this.languageService.getActiveLanguage();

    this.race = updateI18nObject(
      { ...this.race },
      eventFormGroup.value,
      activeLanguageCode
    );
  }

  onSaveClick(): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();

    this.formatDateTime('dateTimeFrom', 'timeFrom');
    this.formatDateTime('dateTimeTo', 'timeTo');
    this.transformRaceDetailObj();
    this.formGroup.markAsUntouched();

    const observer =
      this.scheduleType === 'ORGANIZER'
        ? this.saveScheduleRace(activeLanguageCode)
        : this.saveScheduleRaceWithInstruction(activeLanguageCode);

    this.apiCallExtensionService
      .extendApiCall(
        () => observer,
        this.translate.instant(PROGRESS_DIALOG_ACTION_SAVING)
      )
      .subscribe({
        next: () => {
          this.navigate();
        },
      });
  }

  onCancelClick(): void {
    this.navigate();
  }

  getImageObservables(): Observable<void>[] | Observable<null> {
    return iif(() => !!this.race.id, forkJoin(this.deleteImages()), of(null));
  }

  saveScheduleRaceWithInstruction(activeLanguageCode: string): Observable<any> {
    this.transformRaceStartInstruction();

    return forkJoin(this.getImageObservables())
      .pipe(defaultIfEmpty([]))
      .pipe(
        mergeMap(() => {
          return this.saveRace().pipe(
            mergeMap((race) =>
              forkJoin([
                this.saveStartInstruction(race.id),
                this.uploadRaceImages(activeLanguageCode, race.id),
                this.saveImage(race.id, 'sponsorImg', 'uploadSponsor'),
                this.saveImage(race.id, 'mapImg', 'uploadMapImage'),
                this.saveImage(
                  race.id,
                  'heightProfileImg',
                  'uploadHeightProfileImage'
                ),
              ]).pipe(
                mergeMap(([startInstruction]) =>
                  this.imagesService.uploadRelatedImages(
                    this.EVENT_ID,
                    startInstruction.id,
                    this.getUploadedImages(),
                    ImageType.StartInstruction,
                    this.deletedBasicFormImagesId,
                    activeLanguageCode
                  )
                )
              )
            )
          );
        })
      );
  }

  saveScheduleRace(activeLanguageCode: string): Observable<any> {
    return forkJoin(this.getImageObservables())
      .pipe(defaultIfEmpty([]))
      .pipe(
        mergeMap(() => {
          return this.saveRace().pipe(
            mergeMap((race) =>
              forkJoin([
                this.uploadRaceImages(activeLanguageCode, race.id),
                this.saveImage(race.id, 'sponsorImg', 'uploadSponsor'),
                this.saveImage(race.id, 'mapImg', 'uploadMapImage'),
                this.saveImage(
                  race.id,
                  'heightProfileImg',
                  'uploadHeightProfileImage'
                ),
              ])
            )
          );
        })
      );
  }

  getUploadedImages(): File[] {
    const galleryFiles: File[] = [];
    this.formGroup.get('basicContentGallery').value.forEach((value) => {
      if (value.image instanceof File) {
        galleryFiles.push(value.image);
      }
    });
    return galleryFiles;
  }

  saveImage(programId: number, value: string, type: string): Observable<void> {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    const prop = this.formGroup.get('race').get(value).value;
    if (prop instanceof File) {
      return this.programService.uploadImage(
        programId,
        prop,
        type,
        2,
        activeLanguageCode
      );
    }
    return of(null);
  }

  saveRace(): Observable<IProgramRacePost> {
    return this.programService.createOrUpdateProgramRace(
      this.EVENT_ID,
      this.race
    );
  }

  saveStartInstruction(raceId: number): Observable<IStartInstruction> {
    this.startInstruction.raceScheduleId = raceId;
    return this.startInstructionsService.patchStartInstructions(
      this.startInstruction
    );
  }

  uploadRaceImages(
    activeLanguageCode: string,
    raceId: number
  ): Observable<any> {
    return this.imagesService.uploadImages(
      this.EVENT_ID,
      raceId,
      ImageType.ScheduleRace,
      this.addedImages.value || [],
      activeLanguageCode
    );
  }

  formatDateTime(dateType: string, timeType: string): void {
    const DATE = this.formGroup.get('race').get(dateType).value;
    const TIME = this.formGroup.get('time').get(timeType).value;
    const formatedDate = setDateWithTime(DATE, TIME);
    this.formGroup.get('race').get(dateType).setValue(formatedDate);
  }

  deleteImages(): Observable<void>[] {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    const observers: Observable<void>[] = [];

    const sponsorImage: Record<string, string> = {
      sponsor: this.formGroup.get('race').get('sponsorImg').value,
    };
    const mapImage: Record<string, string> = {
      mapImage: this.formGroup.get('race').get('mapImg')?.value,
    };
    const heightProfileImage: Record<string, string> = {
      heightProfileImage: this.formGroup.get('race').get('heightProfileImg')
        ?.value,
    };

    [sponsorImage, mapImage, heightProfileImage].forEach((value) => {
      if (Object.values(value)[0] === undefined) {
        observers.push(
          this.programService.deleteRaceImage(
            parseInt(this.programId, 10),
            Object.keys(value)[0],
            2,
            activeLanguageCode
          )
        );
      }
    });

    return observers;
  }

  deleteGalleryImage(image: IProgramImage): void {
    this.imagesService.deleteImages(this.EVENT_ID, [image.id]).subscribe();
  }

  getImage(type: string): string {
    return this.programService.getImageUrl(this.race[type]);
  }

  initRace(): void {
    if (this.race) {
      const raceTimeFrom = timeFormat(new Date(this.race.dateTimeFrom));
      const raceTimeTo = timeFormat(new Date(this.race.dateTimeTo));
      this.initEventFormGroup();
      this.formGroup.get('time').get('timeFrom').setValue(raceTimeFrom);
      this.formGroup.get('time').get('timeTo').setValue(raceTimeTo);
    }
  }

  initEventStartInformationGroup(): void {
    const languageCode = this.languageService.getActiveLanguage();

    if (!this.race.id) {
      this.initEmptyRaceStartInstruction();
    } else if (this.startInstruction) {
      const modifiedStartInstruction = reduceI18nObject(
        this.startInstruction,
        languageCode
      );
      this.formGroup.get('basicContent').setValue(modifiedStartInstruction);
    }
  }

  initEventFormGroup(): void {
    const languageCode = this.languageService.getActiveLanguage();
    if (this.race) {
      const modifiedRace = reduceI18nObject(this.race, languageCode);
      this.formGroup.get('race').setValue(modifiedRace);
    }
  }
}
