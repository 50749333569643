<div class="app-dialog-layout flex flex-column full-height">
  <div *ngIf="showClose" class="right-align">
    <button mat-icon-button (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <h1 mat-dialog-title *ngIf="title">{{ title }}</h1>

  <div mat-dialog-content class="flex-grow-1">
    <ng-content select="[content]"></ng-content>
  </div>

  <div mat-dialog-actions *ngIf="showActions" [align]="alignActions">
    <ng-content select="[actions]"></ng-content>
  </div>
</div>
