import { ISupportedLanguage } from '../model/language.model';

export const LANGUAGE_STORAGE_KEY = 'lang-sokol';
export const DEFAULT_LANGUAGE_CODE = 'cs';

export const SUPPORTED_LANGUAGES_CODES: Record<string, string> = {
  cs: 'cz',
};

export const SUPPORTED_LANGUAGES: ISupportedLanguage[] = [
  {
    languageCode: 'cs',
    languageName: 'Česky',
  },
  {
    languageCode: 'en',
    languageName: 'English',
  },
];
