import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
})
export class ImageGalleryComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() index: number;
  @Input() type: string;
  @Output() add = new EventEmitter<void>();
  @Output() deleteImage = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}
}
