import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-basic-form-detail-content',
  templateUrl: './basic-form-detail-content.component.html',
  styleUrls: ['./basic-form-detail-content.component.scss'],
})
export class BasicFormDetailContentComponent implements OnInit {
  @Input() loading: boolean;
  @Input() extended: boolean;
  @Input() isExtraInfo: boolean;
  @Input() title: string;
  @Input() formGroup: FormGroup;

  @Output() saveClick = new EventEmitter<void>();
  @Output() cancelClick = new EventEmitter<void>();
  @Output() addGalleryFormGroup = new EventEmitter<void>();
  @Output() deleteImage = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {
    // empty
  }
}
