import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';

import { OrganizersRoutingModule } from './organizers-routing.module';
import { OrganizersPage } from './pages/organizers/organizers.page';
import { BaseMapPage } from './pages/base-map/base-map.page';
import { ImportantContactsPage } from './pages/important-contacts/important-contacts.page';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { OrganizerPagesTableComponent } from './components/organizer-pages-table/organizer-pages-table.component';
import { MatButtonModule } from '@angular/material/button';
import { BasicFormContentService } from 'src/app/shared/services/basic-form-content/basic-form-content.service';
import { OrganizersBasicFormContentService } from './service/organizers-basic-form-content/organizers-basic-form-content.service';

@NgModule({
  declarations: [
    OrganizersPage,
    BaseMapPage,
    ImportantContactsPage,
    OrganizerPagesTableComponent,
  ],
  imports: [
    CommonModule,
    OrganizersRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
  ],
  providers: [
    {
      provide: BasicFormContentService,
      useClass: OrganizersBasicFormContentService,
    },
  ],
})
export class OrganizersModule {}
