import { Component, OnDestroy, OnInit } from '@angular/core';
import { filterDataBySearchInput } from 'src/app/shared/utils/filter.util';
import { OtherPagesService } from '../../services/orther-pages/other-pages.service';
import { IPreference } from 'src/app/api/model/preference.model';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { EventsService } from 'src/app/api/service/events/events.service';
import { IOtherPage } from '../../model/other-pages.model';
import { LanguageService } from 'src/app/shared/services/language/language.service';

@Component({
  selector: 'app-other-pages-list',
  templateUrl: './other-pages-list.component.html',
  styleUrls: ['./other-pages-list.component.scss'],
})
export class OtherPagesListComponent implements OnInit, OnDestroy {
  loading = false;
  private readonly EVENT_ID = parseInt(localStorage.getItem('eventId'), 10);
  readonly columns = ['enabled', 'translate', 'edit'];
  private subs = new Subscription();
  preferences: IPreference[] = [];
  otherPages: IOtherPage[] = [];
  searchValue = '';

  constructor(
    private otherPagesService: OtherPagesService,
    private eventsService: EventsService,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.initOrganizers();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onSearch(searchInput: string): void {
    if (searchInput) {
      this.searchValue = searchInput;
      this.subs.add(
        this.otherPagesService.getPagesTranslation().subscribe(
          (list) => {
            this.otherPages = filterDataBySearchInput(
              list,
              'translation',
              searchInput
            );
          },
          (error: unknown) => {
            console.error(error);
          }
        )
      );
    } else {
      this.initOrganizers();
    }
  }

  initOrganizers(): void {
    this.loading = true;
    this.subs.add(
      forkJoin([this.initOtherPages(), this.initPreferences()]).subscribe(
        ([otherPages, preferences]) => {
          this.otherPages = otherPages;
          this.preferences = preferences;
          this.loading = false;
        },
        (error: unknown) => {
          console.error(error);
        }
      )
    );
  }

  initOtherPages(): Observable<IOtherPage[]> {
    return this.otherPagesService.getPagesTranslation();
  }

  initPreferences(): Observable<IPreference[]> {
    return this.eventsService.getPreference(this.EVENT_ID);
  }

  changeVisibilityState(page): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    const key = `${activeLanguageCode};${page.key}`;
    page?.state
      ? this.subs.add(
          this.eventsService
            .createOrUpdatePreference(this.EVENT_ID, key, page.state)
            .subscribe(
              () => {
                // empty
              },
              (error: unknown) => {
                console.error(error);
              }
            )
        )
      : this.subs.add(
          this.eventsService.deletePreference(this.EVENT_ID, key).subscribe(
            () => {
              // empty
            },
            (error: unknown) => {
              console.error(error);
            }
          )
        );
  }
}
