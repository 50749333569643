import { Injectable } from '@angular/core';
import { IOtherPage } from '../model/other-pages.model';
import { OTHER_PAGES } from '../constants/other-pages.constants';

@Injectable({
  providedIn: 'root',
})
export class BasicFormDetailService {
  constructor() {}

  getOtherPages(): IOtherPage[] {
    return OTHER_PAGES;
  }
}
