<div class="mt4">
  <div class="flex justify-between">
    <div class="mb2 app-other-pages-title">
      {{ "PROGRAM.OTHER-TRANSPORT.PARKING.PLACE.TITLE" | translate }}
      {{ index }}
    </div>
    <div>
      <app-icon-title icon="delete"></app-icon-title>
    </div>
  </div>
  <div>
    <div class="mt2">
      <div class="mb3" [formGroup]="formGroup">
        <mat-form-field class="app-form-field-full-width">
          <mat-label>{{
            "OTHER-PAGES.TRANSPORT.PARKING.TITLE" | translate
          }}</mat-label>
          <input formControlName="title" matInput />
        </mat-form-field>
      </div>

      <div class="mb3" [formGroup]="formGroup">
        <mat-form-field class="app-form-field-full-width">
          <mat-label>{{
            "OTHER-PAGES.TRANSPORT.PARKING.DESCRIPTION" | translate
          }}</mat-label>
          <input formControlName="describe" matInput />
        </mat-form-field>
      </div>

      <div class="mb3" [formGroup]="formGroup">
        <mat-form-field class="app-form-field-full-width">
          <mat-label>{{
            "OTHER-PAGES.TRANSPORT.PARKING.LINK" | translate
          }}</mat-label>
          <input formControlName="link" matInput />
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
