import { IPage } from './page-list-item.model';

export interface IOtherPage extends IPage<OtherPage> {
  key: string;
}

export enum OtherPage {
  BaseMap = 'baseMap',
  Tickets = 'tickets',
  Accommodation = 'accommodation',
  Food = 'food',
  PracticalInfo = 'practical_info',
  Transport = 'transport',
  Faq = 'FAQ',
  Partners = 'partners',
  ImportantContacts = 'important_contacts',
  Music = 'music',
  Extra = 'extra',
}
