<div *ngIf="!loading">
  <app-back-panel
    [title]="'ORGANIZERS.PAGES.BACK' | translate"
  ></app-back-panel>

  <app-list-top-card
    [title]="'ORGANIZERS.PAGES.IMPORTANT_CONTACTS' | translate"
  >
  </app-list-top-card>

  <app-detail-card class="mb3">
    <div class="my4 pb2">
      <div
        *ngFor="let contactGroupFormGroup of formArray.controls; let i = index"
      >
        <div class="mt2" [formGroup]="contactGroupFormGroup">
          <div class="flex items-center justify-between mb1">
            <div class="app-other-pages-title">
              <mat-label
                >{{ contactGroupFormGroup.get("groupName").value }}</mat-label
              >
            </div>
            <div>
              <button mat-icon-button (click)="onClickDeleteContactGroup(i)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>

          <div class="mb2">
            <mat-form-field class="app-form-field-full-width">
              <mat-label
                >{{ "IMPORTANT-CONTACTS.GROUP-NAME" | translate }}</mat-label
              >
              <input formControlName="groupName" matInput />
            </mat-form-field>
          </div>

          <div
            *ngFor="
                  let person of getContactsFormArray(contactGroupFormGroup).controls;
                  let j = index
                "
            [formGroup]="person"
          >
            <div class="flex items-center justify-between">
              <div class="app-other-pages-sub-title">
                <mat-label
                  >{{ "IMPORTANT-CONTACTS.CONTACT" | translate: {order: j+1}
                  }}</mat-label
                >
              </div>
              <div>
                <button mat-icon-button (click)="onClickDeleteContact(i, j)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>

            <div class="mb2 flex fit justify-between">
              <div>
                <mat-form-field>
                  <mat-label
                    >{{ "IMPORTANT-CONTACTS.NAME" | translate }}</mat-label
                  >
                  <input formControlName="name" matInput />
                </mat-form-field>
              </div>

              <div>
                <mat-form-field>
                  <mat-label
                    >{{ "IMPORTANT-CONTACTS.PHONE" | translate }}</mat-label
                  >
                  <input formControlName="phone" matInput />
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="flex justify-end">
            <div
              class="mb2"
              (click)="addContactFormGroup(contactGroupFormGroup.get('contacts'))"
            >
              <app-button
                [title]="'IMPORTANT-CONTACTS.ADD.CONTACT' | translate"
                icon="add"
                [secondary]="true"
                [slim]="true"
              ></app-button>
            </div>
          </div>
        </div>
      </div>

      <div class="mb2" (click)="addContactGroupFormGroup()">
        <app-button
          [title]="'IMPORTANT-CONTACTS.ADD.GROUP' | translate"
          icon="add"
          [secondary]="true"
          [slim]="true"
        ></app-button>
      </div>
    </div>
  </app-detail-card>

  <app-footer (save)="onSaveClick()" (cancel)="onCancelClick()"></app-footer>
</div>

<app-spinner *ngIf="loading"></app-spinner>
