import { EventCode, IEventLogo } from '../model/event.model';

export const EVENT_LOGO: IEventLogo[] = [
  {
    code: EventCode.BedrichovskyNlm,
    path: 'assets/logo/default_skitour_logo.svg',
  },
  {
    code: EventCode.Zadovska30,
    path: 'assets/logo/default_skitour_logo.svg',
  },
  {
    code: EventCode.OrlickyMaraton,
    path: 'assets/logo/default_skitour_logo.svg',
  },
  {
    code: EventCode.Jizerska50,
    path: 'assets/logo/jizerska50.png',
  },
  {
    code: EventCode.Jelyman,
    path: 'assets/logo/default_skitour_logo.svg',
  },
  {
    code: EventCode.Krusnohorska30,
    path: 'assets/logo/default_skitour_logo.svg',
  },
  {
    code: EventCode.Default,
    path: 'assets/logo/default_skitour_logo.svg',
  },
];
