import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IEvent, ITopic } from '../../model/events.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, mergeMap } from 'rxjs/operators';
import { IPreference } from '../../model/preference.model';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  private readonly basePath = environment.apiUrl;
  constructor(private httpClient: HttpClient) {}

  eventList(): Observable<IEvent[]> {
    return this.httpClient.get<IEvent[]>(
      `${this.basePath}/events/getSortedEvents`
    );
  }

  eventDetail(eventId: number): Observable<IEvent> {
    return this.eventList().pipe(
      map((events) => {
        return events.find((event) => event.id === eventId);
      })
    );
  }

  createOrUpdatePreference(
    eventId: number,
    preferenceKey: string,
    preferenceValue: boolean
  ): Observable<void> {
    return this.httpClient.post<void>(
      `${this.basePath}/events/${eventId}/preference`,
      {},
      {
        params: {
          key: preferenceKey,
          value: preferenceValue.toString(),
        },
      }
    );
  }

  getTopics(): Observable<ITopic[]> {
    return this.httpClient.get<ITopic[]>(`${this.basePath}/getTopics`);
  }

  deletePreference(eventId: number, preferenceKey: string): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.basePath}/events/${eventId}/preference`,
      {
        params: {
          key: preferenceKey,
        },
      }
    );
  }

  getPreference(eventId: number): Observable<IPreference[]> {
    return this.httpClient.get<IPreference[]>(
      `${this.basePath}/events/${eventId}/preferences`
    );
  }
}
