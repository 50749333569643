<div>
  <div>
    <ng-template #formContainer>{{ text }}</ng-template>
  </div>
  <div class="flex justify-end">
    <div class="mr2">
      <button mat-raised-button (click)="cancel.emit()" class="ml2 mr2">
        {{ "CORE.BUTTON.CANCEL" | translate }}
      </button>
    </div>
    <div>
      <button mat-raised-button (click)="submit.emit()" class="ml2">
        {{ "CORE.BUTTON.SUBMIT" | translate }}
      </button>
    </div>
  </div>
</div>
