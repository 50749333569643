<div *ngIf="images">
  <div class="app-image-panel-info mt2 mb2">
    <div class="app-image-panel-info-element items-center flex">
      <mat-icon class="mr1">info</mat-icon>
      {{ "CORE.IMAGES.TITLE" | translate }}
    </div>
  </div>

  <form class="app-image-panel-wrapper flex">
    <div *ngFor="let image of images" class="mb3">
      <img class="app-image-panel-element" [src]="getImageUrl(image)" />
      <div
        class="app-image-panel-title flex"
        (click)="delete.emit(image)"
        (click)="onDelete(image)"
      >
        {{ "CORE.DELETE" | translate | uppercase }}
        <mat-icon [ngStyle]="{ color: '#b00020' }">delete</mat-icon>
      </div>
    </div>

    <div
      class="app-image-panel-element app-image-panel-element-add flex justify-center items-center"
      (click)="add.emit()"
    >
      <div>
        <div class="flex justify-center items-center">
          <button mat-icon-button (click)="openAddFilesDialog()">
            <mat-icon>add</mat-icon>
            <input
              class="app-file-input"
              #Input
              type="file"
              multiple
              (change)="onChange($event)"
            />
          </button>
        </div>
        <div class="flex justify-center items-center">
          {{ "CORE.IMAGES.ADD" | translate | uppercase }}
        </div>
      </div>
    </div>
  </form>
</div>
