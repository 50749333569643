export interface IProgramImage {
  inserted: Date;
  updated: Date;
  id: number;
  orderBy: number;
  type: string;
  image: any;
}

export interface IImage {
  inserted: Date;
  updated: Date;
  id: number;
  orderBy: number;
  type: string;
  image: any;
}

export interface IProgramImageFile extends IProgramImage {
  file: File;
}

export interface IGeneralImage {
  value: any;
  upload: string;
  delete: string;
}

export enum ImageType {
  ScheduleEvent = 'SCHEDULE_EVENT',
  ScheduleRace = 'SCHEDULE_RACE',
  OtherInfo = 'INFO_PAGE',
  OrganizersInfo = 'ORGANIZER_PAGE',
  StartInstruction = 'START_INSTRUCTIONS',
  ExtraInfo = 'EXTRA_INFO_PAGE',
  Music = 'MUSIC_PAGE',
}
