import { Injectable } from '@angular/core';
import { OTHER_PAGES } from 'src/app/shared/constants/other-pages.constants';
import { IOtherPage, OtherPage } from 'src/app/shared/model/other-pages.model';
import { ImageType } from 'src/app/api/model/image.model';
import { forkJoin, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { IBasicFormContentInfo } from 'src/app/shared/model/basic-form-content-info.model';
import { ImagesService } from 'src/app/api/service/images/images.service';
import { OtherPageService } from 'src/app/api/services/other-page/other-page.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OtherPagesService {
  private readonly basePath = environment.apiUrl;

  constructor(
    private imagesService: ImagesService,
    private otherPageService: OtherPageService,
    private http: HttpClient
  ) {}

  getOtherPages(): IOtherPage[] {
    return OTHER_PAGES;
  }

  getOtherInfoByType(
    eventId: number,
    type: string
  ): Observable<IBasicFormContentInfo> {
    return this.otherPageService.getPage(eventId).pipe(
      map((organizers) => {
        return organizers?.find((organizer) => organizer?.type === type);
      })
    );
  }

  getOtherPagesList(
    eventId: number,
    type?: OtherPage
  ): Observable<IBasicFormContentInfo[]> {
    return this.http
      .get<IBasicFormContentInfo[]>(`${this.basePath}/event/${eventId}/pages`)
      .pipe(
        map((otherPages) => {
          return type
            ? otherPages.filter((page) => page.type === type)
            : otherPages;
        })
      );
  }

  deleteOtherPage(pageId: number): Observable<void> {
    return this.http.delete<void>(`${this.basePath}/page`, {
      params: {
        ids: [pageId.toString()],
      },
    });
  }

  createOrUpdateInfo(
    eventId: number,
    info: IBasicFormContentInfo,
    infoType: OtherPage,
    galleryFiles: File[],
    deletedImagesId: number[],
    activeLanguageCode: string
  ): Observable<any> {
    return this.otherPageService
      .createOrUpdatePage(eventId, info, infoType)
      .pipe(
        mergeMap((response) => {
          return forkJoin([
            this.imagesService.uploadImages(
              eventId,
              response.id,
              ImageType.OtherInfo,
              galleryFiles,
              activeLanguageCode
            ),
            this.imagesService.deleteImages(eventId, deletedImagesId),
          ]);
        })
      );
  }
}
