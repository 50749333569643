import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OtherPage } from '../../model/other-pages.model';
import { Observable, Subscription } from 'rxjs';
import {
  IBasicFormContentInfo,
  IExtendBasicFormContentInfo,
} from '../../model/basic-form-content-info.model';
import { EventService } from 'src/app/api/services/event/event.service';
import { LanguageService } from '../../services/language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiCallExtensionService } from '../../services/api-call-extension/api-call-extension.service';
import { DialogService } from '../../services/dialog/dialog.service';
import { OtherPagesService } from '../../services/other-pages/other-pages.service';
import { IBasicTable } from '../../model/basic-table.model';
import { filter, mergeMap } from 'rxjs/operators';
import { iif } from 'rxjs';
import { PROGRESS_DIALOG_ACTION_DELETING } from '../../constants/dialog.constants';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ConfirmDialogComponent } from '../../component/dialogs/confirm-dialog/confirm-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import { ExtendedFormContentService } from '../../services/extended-form-content/extended-form-content.service';

@Component({
  templateUrl: './custom-other-pages-list.page.html',
  styleUrls: ['./custom-other-pages-list.page.scss'],
})
export class CustomOtherPagesListPage implements OnInit, OnDestroy {
  pageType: OtherPage;
  loading = false;
  searchValue = '';
  private subs = new Subscription();
  customOtherPages: IBasicFormContentInfo[] = [];

  get activeLanguageCode(): string {
    return this.languageService.getActiveLanguage();
  }

  get isExtraInfo(): boolean {
    return ![OtherPage.Extra].includes(this.pageType);
  }

  constructor(
    private otherPagesService: OtherPagesService,
    private eventService: EventService,
    private router: Router,
    private languageService: LanguageService,
    private translate: TranslateService,
    private apiCallExtensionService: ApiCallExtensionService,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private extendedFormContentService: ExtendedFormContentService<IExtendBasicFormContentInfo>
  ) {}

  ngOnInit(): void {
    this.pageType = this.activatedRoute.snapshot.params.type;
    this.init();
  }

  private init(): void {
    this.loading = true;

    this.subs.add(
      this.getPages().subscribe({
        next: (customOtherPages) => {
          this.customOtherPages = customOtherPages;
          this.loading = false;
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private getPages(): Observable<
    IBasicFormContentInfo[] | IExtendBasicFormContentInfo[]
  > {
    return iif(
      () => this.isClassicOtherPage(),
      this.otherPagesService.getOtherPagesList(
        this.eventService.getEventId(),
        this.pageType
      ),
      this.extendedFormContentService.getExtendedList(
        this.eventService.getEventId()
      )
    );
  }

  private isClassicOtherPage(): boolean {
    return ![OtherPage.Music, OtherPage.Extra].includes(this.pageType);
  }

  createCustomOtherPage(pageId: number): void {
    this.onNavigateToPage(pageId);
  }

  onNavigateToPage(id: number): void {
    const navigationExtras = {
      queryParams: {
        type: this.pageType,
        extended: this.pageType === OtherPage.Music,
      },
    };

    if (this.pageType.toString() === OtherPage.Extra) {
      this.router.navigate(['extra', id, this.pageType], navigationExtras);
    } else {
      this.router.navigate(
        ['other-pages', id, this.pageType],
        navigationExtras
      );
    }
  }

  onSearch(searchTerm: string): void {
    this.searchValue = searchTerm;
  }

  private deletePage(id: number): Observable<void> {
    return iif(
      () => this.isClassicOtherPage(),
      this.otherPagesService.deleteOtherPage(id),
      this.extendedFormContentService.deleteExtendedEntity(id)
    );
  }

  onDeleteCustomOtherPage(tableElement: IBasicTable): void {
    const dialogRef = this.openDeleteCustomOtherPageDialog(tableElement.title);

    dialogRef
      .afterClosed()
      .pipe(
        filter((confirmed) => confirmed),
        mergeMap(() =>
          this.apiCallExtensionService.extendApiCall(
            () => this.deletePage(tableElement.id),
            this.translate.instant(PROGRESS_DIALOG_ACTION_DELETING)
          )
        )
      )
      .subscribe({
        next: () => {
          this.init();
        },
      });
  }

  openDeleteCustomOtherPageDialog(
    programTitle: string
  ): MatDialogRef<ConfirmDialogComponent> {
    return this.dialogService.openConfirmDialog({
      danger: true,
      mainText: this.translate.instant(marker('CORE.ALERT.DELETE_ITEM.TITLE')),
      subText: this.translate.instant(
        marker('CORE.ALERT.DELETE_ITEM.SUBTITLE'),
        {
          itemTitle: programTitle,
        }
      ),
      cancelText: this.translate.instant(marker('CORE.BUTTON.BACK')),
      confirmText: this.translate.instant(marker('CORE.DELETE')),
      showClose: true,
      imgPath: 'assets/image/delete.svg',
    });
  }

  onEditCustomOtherPage(tableElement: IBasicTable): void {
    this.onNavigateToPage(tableElement.id);
  }
}
