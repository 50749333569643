import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IPage } from '../../model/page-list-item.model';

@Pipe({
  name: 'translatePagesTitles',
})
export class TranslatePagesTitlesPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  // IMPORTANT!!! languageCode param is present only to force recalculation of pipe on lang change
  transform<T extends IPage>(otherPages: T[], languageCode: string): T[] {
    return otherPages.map((otherPage) => ({
      ...otherPage,
      translation: this.translate.instant(otherPage.titleI18n) as string,
    }));
  }
}
