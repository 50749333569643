<div
  class="app-main-menu-wrapper border-box pt2 px2 full-width flex justify-between"
>
  <div class="app-main-menu-link text-normal">
    <div>
      <button
        class="app-main-menu-button"
        mat-button
        [matMenuTriggerFor]="eventsMenu"
      >
        <div class="flex justify-between items-center">
          <div class="flex justify-center">
            <div class="mr1">{{ activeEvent?.name }}</div>
          </div>
        </div>
      </button>

      <mat-menu #eventsMenu>
        <button
          *ngFor="let event of events"
          (click)="onEventChange(event.id, event.eventShortCode, event.extId)"
          mat-menu-item
        >
          <div class="flex justify-between items-center">
            <div class="mr1">
              {{ event.name }}
            </div>
          </div>
        </button>
      </mat-menu>
    </div>
  </div>

  <nav mat-tab-nav-bar>
    <a
      mat-tab-link
      *ngFor="let link of links"
      [routerLink]="link.url"
      routerLinkActive="app-active"
      #rla="routerLinkActive"
      [active]="rla.isActive"
      [title]="link.name | translate"
    >
      {{ link.name | translate | uppercase }}
    </a>
  </nav>

  <div class="flex">
    <!-- <div class="flex items-center justify-center">
      <button mat-button [mat-menu-trigger-for]="languageMenu">
        <div class="flex items-center">
          <mat-icon class="mr1">language</mat-icon>
          <div>{{ languageName }}</div>
        </div>
      </button>

      <mat-menu #languageMenu>
        <button
          *ngFor="let supportedLanguage of supportedLanguages"
          mat-menu-item
          class="flex items-center justify-center"
          (click)="onLanguageChange(supportedLanguage.languageCode)"
        >
          <div class="flex justify-center">
            {{ supportedLanguage.languageName }}
          </div>
        </button>
      </mat-menu>
    </div> -->

    <div class="flex justify-center">
      <button
        class="app-main-menu-button"
        mat-button
        [matMenuTriggerFor]="adminMenu"
      >
        {{ getUserRole() }}
        <mat-icon matSuffix>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #adminMenu>
        <form>
          <button (click)="logout()" mat-menu-item>
            {{ "CORE.LOGOUT.TITLE" | translate }}
          </button>
        </form>
      </mat-menu>
    </div>
  </div>
</div>

<div class="app-navigation-version flex justify-end mr2 text-extra-small">
  {{ "CORE.VERSION.TITLE" | translate }} {{ version }}
</div>
