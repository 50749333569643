import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ImageType } from 'src/app/api/model/image.model';

@Injectable({
  providedIn: 'root',
})
export abstract class ExtendedFormContentService<T> {
  constructor() {}

  abstract getImageType(): ImageType;

  abstract getExtendedList(eventId: number): Observable<T[]>;

  abstract createOrUpdateExtendedEntity(
    eventId: number,
    extraInfo: T
  ): Observable<T>;

  abstract deleteExtendedEntity(extraInfoId: number): Observable<void>;

  abstract createOrUpdate(
    eventId: number,
    extraInfo: T,
    galleryFiles: File[],
    deletedImagesId: number[],
    activeLanguageCode: string
  ): Observable<[void, void]>;
}
