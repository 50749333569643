<div class="table-container">
  <table
    *ngIf="tableDataList.length !== 0"
    mat-table
    [dataSource]="tableDataList"
  >
    <!-- Title column -->
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>
        {{ "BASIC_TABLE.TITLE" | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let tableData">
        {{ tableData.title }}
      </td>
    </ng-container>

    <!-- actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell class="app-table-icons" *matCellDef="let tableData">
        <div class="flex justify-end">
          <button mat-icon-button>
            <mat-icon>edit</mat-icon>
          </button>

          <button mat-icon-button>
            <mat-icon
              (click)="$event.stopPropagation()"
              (click)="delete.emit(tableData)"
              >delete</mat-icon
            >
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr
      mat-row
      *matRowDef="let tableData; columns: columns"
      (click)="rowClick.emit(tableData)"
    ></tr>
  </table>

  <app-empty-result *ngIf="tableDataList.length === 0"></app-empty-result>
</div>
